<script setup>
    import { computed } from 'vue';

    import { useUserStore } from '@/stores/user'
    const userStore = useUserStore();

    import { useIncentivesStore } from '@/stores/incentives'
    const incentivesStore = useIncentivesStore();
    
    import { usePerformanceStore } from '@/stores/performance'
    const performanceStore = usePerformanceStore();

    import Bar from '@/components/Bar_new.vue';

    const displayedPerformance = computed(() => {
        return performanceStore.performance[userStore.currentSelection.performance.display];
    })

    const wrongScope = computed(() => {
        return userStore.currentSelection.scope !== 'salesAgent';
    })

    const targetStatusIsAchieved = computed(() => {
        return performanceStore.performance['campaign'].status.achieved === true // to prevent status changing while animation
    })

    const targetStatusIsInactive = computed(() => {
        return (userStore.currentSelection.performance.display === 'halftime') ? true : false;
    })

    const targetStatusIsIncomplete = computed(() => {
        return !userStore.status.achieved;
    })

    const campaignAchievedComissionOnTop = computed(() => {
        const achievedStatuses = performanceStore.performance.campaign.status.statusList.filter(status => status.achievedLatest === true)
        if (achievedStatuses.length > 0) {
            // Assuming you want to return the commissionOnTop of the first achieved status
            return achievedStatuses[0].commissionOnTop
        }
        return 0
    })

    const currentStatus = computed(() => {
        return performanceStore.performance[userStore.currentSelection.performance.display].status.currentStatus.toLowerCase().replace(' ', '-');
    }) 

    const plannedStatus = computed(() => {
        return performanceStore.performance[userStore.currentSelection.performance.display].status.plannedStatus.toLowerCase().replace(' ', '-');
    }) 

    async function setDisplayStatus(display) {
        await userStore.selectDisplayStatus('performance', display);
    }

    function formatEuroValues(value) {
        return value.toLocaleString('de-DE');
    }

    function createClassNameForStatus(statusName) {
        return statusName.toLowerCase().replace(' ', '-');
    }

    const incentiveInformations = computed(() => {
        const championsExists = incentivesStore.incentive?.salesAgents?.champions?.find((champion) => champion.highlightedPosition === true);
        if (!championsExists) {
            return {
                bonus: 0,
                entitled: false,
                name: `${userStore.firstname} ${userStore.surname}`,
            }
        } else {
            return {
                bonus: championsExists.salesBonus,
                entitled: championsExists.salesBonusEntitled,
                name: championsExists.salesAgentName,
            }
        }
    });
</script>

<template>
    <div id="performance" :class="['widget-wrapper performance noValue toggle', {'noValue' : !performanceStore.performance}]">
        <div>
            <router-link to="/performance">
                <div class="headline-wrapper">
                    <h2>Performance</h2>
                </div>
            </router-link>
        </div>
        <div class="container-wrapper" >
                <div v-if="!displayedPerformance" class="container">
                    <div class="warning" v-if="wrongScope">Bitte SalesManager auswählen</div>
                    <div class="warning" v-else >Keine Daten verfügbar!</div>
                </div>
                <div v-if="userStore.currentSelection.performance.isLoading === true" class="container main-content cell-grid">
                    <div class="loader" id="loader"></div>
                </div>
                <template v-else-if="displayedPerformance">
                    <div :class="['container main-content', {'halftimeIsSelected' : userStore.currentSelection.performance.display === 'halftime', 'targetStatusIsAchieved' : targetStatusIsAchieved === true}]" v-if="userStore.currentSelection.performance.display === 'campaign'">
                        <h3 class="underlined">Zielstatus</h3>
                        <div :class="['target-status-wrapper', {'targetStatusIsAchieved' : targetStatusIsAchieved}]">
                            <div class="status start">
                                <div class="sm-wrapper">
                                    <div :class="['rank', currentStatus]"><img :src="'avatars/' + userStore.filterOption.salesAgents.find(salesAgent => salesAgent.id === userStore.currentSelection.salesAgent.id).photo + '.webp'" alt="Profilbild"></div>
                                    <div class="centered">
                                        <p>{{displayedPerformance.status.currentStatus}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="status target">
                                <div v-if="targetStatusIsAchieved" class="icon-wrapper"><img src="@/assets/imgs/icons/circle-check-solid.svg"></div>
                                <div v-else class="icon-wrapper"><img src="@/assets/imgs/icons/circle-xmark-solid.svg"></div>
                            </div>
                            <div class="status goal">
                                <div class="sm-wrapper">
                                    <div :class="['rank', plannedStatus]"><img :src="'avatars/' + userStore.filterOption.salesAgents.find(salesAgent => salesAgent.id === userStore.currentSelection.salesAgent.id).photo + '.webp'" alt="Profilbild"></div>
                                    <div class="centered">
                                        <p>{{displayedPerformance.status.plannedStatus}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- {{ displayedPerformance.status.statusList.slice(0, -1) }} -->
                        <div class="bars-wrapper">
                            <span :class="['target-line cell-grid', {'short' : userStore.currentSelection.performance.display === 'halftime'}]" :style="{ width: Math.min(displayedPerformance.progress.expired, 100) + '%',  }"></span>
                            <bar :class="'selling-time'" :mode="'performanceNormal'"  title="Verkaufszeit" :currentValue="displayedPerformance.progress.current" :goalValue="displayedPerformance.progress.planned"></bar >
                            <bar :class="'sales-volume'" :mode="'performanceNormal'"  title="Gesamt" :isEuro=true :currentValue="displayedPerformance.salesEuro.current" :goalValue="displayedPerformance.salesEuro.planned"></bar >
                            <bar :class="'new-customer-sales' " :mode="'PointSystemNew'"  title="Neu-Kd." :isEuro=true :currentValue="displayedPerformance.salesEuroNewCustomers.current" :goalValue="displayedPerformance.salesEuroNewCustomers.planned"></bar >
                            <bar :class="'new-customer-sales-integer'" v-if="userStore.currentSelection.performance.display==='campaign'" :mode="'performanceNormal'"  title="Neu-Kd. #" :currentValue="displayedPerformance.salesNumberNewCustomers.current" :goalValue="displayedPerformance.salesNumberNewCustomers.planned"></bar >
                        </div>
                    </div>
                    <div :class="['container main-content', {'halftimeIsSelected' : userStore.currentSelection.performance.display === 'halftime', 'targetStatusIsAchieved' : targetStatusIsAchieved === true}]" v-if="userStore.currentSelection.performance.display === 'halftime'">
                        <h3 class="underlined">Monats Ziel</h3>
                        <div :class="['target-status-wrapper', {'targetStatusIsAchieved' : targetStatusIsAchieved}]">
                            <div class="status start">
                                <div class="sm-wrapper">
                                    <div :class="['points value-box', {'positive' : displayedPerformance.status.commissionPoints  >= 1, 'negative' : displayedPerformance.status.commissionPoints < .5}]">{{ displayedPerformance.status.commissionPoints }}</div>
                                    <div class="centered">
                                        <p>Prämienpunkte</p>
                                    </div>
                                </div>
                            </div>
                            <div class="calculated">
                                {{ displayedPerformance.status.commissionPoints }} * {{displayedPerformance.status.statusList.find(status => status.name === displayedPerformance.status.plannedStatus).levelValue}}€
                            </div>
                            <div class="status goal month">
                                <div class="sm-wrapper">
                                    <div :class="['rank', plannedStatus]"><img :src="'avatars/' + userStore.filterOption.salesAgents.find(salesAgent => salesAgent.id === userStore.currentSelection.salesAgent.id).photo + '.webp'" alt="Profilbild"></div>
                                    <div>
                                        <div class="centered">
                                            <p>{{displayedPerformance.status.plannedStatus}}</p>
                                        </div>
                                        <div class="centered">
                                            <p>{{displayedPerformance.status.statusList.find(status => status.name === displayedPerformance.status.plannedStatus).levelValue}}€ pro Punkt</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- {{ displayedPerformance.status.statusList.slice(0, -1) }} -->
                        <div class="bars-wrapper">
                            <span :class="['target-line cell-grid', {'short' : userStore.currentSelection.performance.display === 'halftime'}]" :style="{ width: Math.min(displayedPerformance.progress.expired, 100) + '%',  }"></span>
                            <bar :class="'selling-time'" :mode="'performanceNormal'"  title="Verkaufszeit" :currentValue="displayedPerformance.progress.current" :goalValue="displayedPerformance.progress.planned"></bar >
                            <bar :class="'sales-volume'" :mode="'performanceNormal'"  title="Gesamt" :isEuro=true :currentValue="displayedPerformance.salesEuro.current" :goalValue="displayedPerformance.salesEuro.planned"></bar >
                            <bar :class="'new-customer-sales' " :mode="'PointSystemNew'"  title="Neu-Kd." :isEuro=true :currentValue="displayedPerformance.salesEuroNewCustomers.current" :goalValue="displayedPerformance.salesEuroNewCustomers.planned"></bar >
                            <bar :class="'new-customer-sales-integer'" v-if="userStore.currentSelection.performance.display==='campaign'" :mode="'performanceNormal'"  title="Neu-Kd. #" :currentValue="displayedPerformance.salesNumberNewCustomers.current" :goalValue="displayedPerformance.salesNumberNewCustomers.planned"></bar >
                        </div>
                    </div>
                    <div class="container value-wrapper" v-if="userStore.currentSelection.performance.display==='campaign'">
                        <div class="value"><h3>Status-LP<sup>*3</sup></h3> <div :class="['value-box', {'fulfilled' : displayedPerformance.status.commission > 0}]">{{formatEuroValues(displayedPerformance.status.commission)}}€</div></div>
                        <div class="value"><h3>Status-LP Incl. Touren<sup>*3</sup></h3> <div :class="['value-box']">{{formatEuroValues(campaignAchievedComissionOnTop)}}€</div></div>
                    </div>
                    <div class="container value-wrapper" v-if="userStore.currentSelection.performance.display==='halftime'">
                        <div class="value"><h3>Monats Prämie<sup>*3</sup></h3> <div :class="['value-box', {'fulfilled' : displayedPerformance.status.commission > 0}]"> {{formatEuroValues(displayedPerformance.status.commission)}}€ </div></div>
                    </div>
                    <div class="container value-wrapper" v-if="userStore.currentSelection.performance.display==='halftime'">
                        <div class="value">
                            <h3>Champions Bonus ({{ incentivesStore.incentive.salesAgents.dateEndMonth }})<sup>*1</sup></h3> 
                            <div :class="['value-box', {'fulfilled' : incentiveInformations.entitled === true}]">
                                {{ formatEuroValues(incentiveInformations.bonus) }}€
                                <!-- <div class="star-box">
                                    <div class="star-1">
                                        <svg xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 784.11 815.53" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><defs></defs><g id="Layer_x0020_1"><metadata id="CorelCorpID_0Corel-Layer"></metadata><path d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z" class="fil0"></path></g></svg>
                                    </div>
                                    <div class="star-2">
                                        <svg xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 784.11 815.53" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><defs></defs><g id="Layer_x0020_1"><metadata id="CorelCorpID_0Corel-Layer"></metadata><path d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z" class="fil0"></path></g></svg>
                                    </div>
                                    <div class="star-3">
                                        <svg xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 784.11 815.53" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><defs></defs><g id="Layer_x0020_1"><metadata id="CorelCorpID_0Corel-Layer"></metadata><path d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z" class="fil0"></path></g></svg>
                                    </div>
                                    <div class="star-4">
                                        <svg xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 784.11 815.53" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><defs></defs><g id="Layer_x0020_1"><metadata id="CorelCorpID_0Corel-Layer"></metadata><path d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z" class="fil0"></path></g></svg>
                                    </div>
                                    <div class="star-5">
                                        <svg xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 784.11 815.53" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><defs></defs><g id="Layer_x0020_1"><metadata id="CorelCorpID_0Corel-Layer"></metadata><path d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z" class="fil0"></path></g></svg>
                                    </div>
                                    <div class="star-6">
                                        <svg xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 784.11 815.53" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><defs></defs><g id="Layer_x0020_1"><metadata id="CorelCorpID_0Corel-Layer"></metadata><path d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z" class="fil0"></path></g></svg>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        <div class='wave-wrapper'>
            <div class='wave -one'></div>
            <div class='wave -two'></div>
            <div class='wave -three'></div>
        </div>
        <div class="toggle-wrapper">
            <div class="toggle-option" @click="setDisplayStatus('halftime')">
                <span class="toggle-label">Monat</span>
                <label class="switch">
                    <input type="radio" name="performance-toggle" :checked="userStore.currentSelection.performance.display==='halftime'">
                    <span class="custom-radio"></span>
                </label>
            </div>
            <div class="toggle-option" @click="setDisplayStatus('campaign')">
                <span class="toggle-label">Kampagne</span>
                <label class="switch">
                    <input type="radio" name="performance-toggle" :checked="userStore.currentSelection.performance.display==='campaign'">
                    <span class="custom-radio"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<!-- <style src="../scss/components/incentivePreview.scss" lang="scss" scoped></style> -->