<script setup>
    import { watch, ref } from 'vue'
    import router from '../router/routes';
    import { useBusinessPartnersStore } from '@/stores/businessPartners'
    const businessPartnersStore = useBusinessPartnersStore();
    import { useUserStore } from '@/stores/user';
    const userStore = useUserStore();

    const handleResetFilter = () => {
        userStore.resetBusinessPartnerListFilter(true);
    }
    const handleSubmitFilter = () => {
        closeFilter();
        userStore.currentSelection.businessPartnerList.page = 1;
        userStore.currentSelection.businessPartnerList.pages = 1;
        router.push({ name: 'BusinessPartnerList', query: { source: 'filter', filter: userStore.currentFilter.filter.signature}});
    }

    const closeFilter = () => {
        userStore.currentSelection.businessPartnerList.filter.isOpen = false;
    }

    const selectedValue = ref('')
    watch(selectedValue, (newValue) => {
    const element = document.querySelector('.form-child')
    if (element) {
        if (newValue === 'success') {
            element.classList.add('success-selected')
        } else {
            element.classList.remove('success-selected')
        }
    }
})
</script>

<template>
    <div class="filter-wrapper widget-wrapper" v-if="userStore.currentSelection.businessPartnerList.filter.isOpen || userStore.selectedMedia==='desktop'">
        <div class="container-wrapper">
            <div class="headline-wrapper">
                <h2>Filter</h2>
            </div>
            <div class="container">
                <div class="filter-content">
                    <template v-if="userStore.filterOptions.isLoading === true || userStore.currentSelection.businessPartnerList.isLoading === true">
                        <div class="container main-content cell-grid">
                            <div class="loader" id="loader"></div>
                        </div>
                    </template>
                    <div class="filter-group">
                        <span>Sortierung:</span>
                        <select class="form-child" v-model="userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerOrder">
                            <option v-for="option in userStore.filterOption?.businessPartnerOrder" :key="option.id" :value="option.id">{{ option.name }}</option>
                        </select>
                    </div>
                    <div class="filter-group">
                        <span>Filter:</span>
                        <select :class="['form-child', {'selected' : userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerStatus !== -1}]" v-model="userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerStatus">
                            <option v-for="option in userStore.filterOption?.businessPartnerStatus" :key="option.id" :value="option.id">{{ option.name }}</option>
                        </select>
                        <select :class="['form-child', {'selected' : userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerList !== -1}]" v-model="userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerList">
                            <option v-for="option in userStore.filterOption?.businessPartnerLists" :class="{ subMenue: option.isList }" :key="option.id" :value="option.id">{{ option.name }}</option>
                        </select>
                        <select :class="['form-child', {'selected' : userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerPotential !== -1}]" v-model="userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerPotential">
                            <option v-for="option in userStore.filterOption?.businessPartnerPotential.filter((option) => option.useInBusinessPartnerFilter)" :key="option.id" :value="option.id">{{ option.name }}</option>
                        </select>

                        <select :class="['form-child', {'selected' : userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerProduct !== -1}]" v-model="userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerProduct">
                            <option v-for="option in userStore.filterOption?.businessPartnerProduct" :key="option.id" :value="option.id">{{ option.name }}</option>
                        </select>
                        
                        <select :class="['form-child', {'selected' : userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerAboStatus !== -1}]" v-model="userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerAboStatus">
                            <option v-for="option in userStore.filterOption?.businessPartnerAboStatus" :key="option.id" :value="option.id">{{ option.name }}</option>
                        </select>

                        <select :class="['form-child', {'selected' : userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerCityZip !== -1}]" v-model="userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerCityZip">
                            <option v-for="option in userStore.filterOption?.businessPartnerCityZips" :class="{ subMenue: option.isZipCode }" :key="option.id" :value="option.id">{{ option.name }}</option>
                        </select>
                        
                        <input class="form-child" placeholder="Branche eingeben um zu filtern" list="businessPartnerSector" v-model="userStore.currentSelection.businessPartnerList.filter.nameBusinessPartnerSector">
                        <datalist id="businessPartnerSector">
                            <option v-for="option in userStore.filterOption?.businessPartnerSector" :data-value="option.name" :value="option.name" :key="option.name"></option>
                        </datalist>
                    </diV>

                    <div class="row">
                        <button @click="handleResetFilter">Zurücksetzen</button>
                        <button @click="handleSubmitFilter">Anwenden</button>
                    </div>
                </div>
            </div>
        </div>
        <div class='wave-wrapper'>
            <div class='wave -one'></div>
            <div class='wave -two'></div>
            <div class='wave -three'></div>
        </div>
    </div>
</template>