<script setup>
    // Seite zum Verschieben vergangener Wiedervorlagen
    import { ref, reactive, onMounted, computed } from 'vue'
    import { storeToRefs } from "pinia";

    // TODO: allow for routing to the details page of the businessPartner
    import { useRoute } from 'vue-router'
    const route = useRoute()

    import router from '../../router/routes';

    import Reminder from '@/components/Reminder.vue'
    import userAvatar from '@/components/userAvatar.vue'

    import { useUserStore } from '@/stores/user'
    const userStore = useUserStore()

    import { useBusinessPartnersStore } from '@/stores/businessPartners'
    const businessPartnersStore = useBusinessPartnersStore()

    const isSaving = ref(false)
    const additionalProduct = ref(-1);
    const additionalProducts = reactive([]);
    const additionalProductForFollowUpAppointment = ref(-1);
    const additionalProductsForFollowUpAppointment = reactive([]);
    const feedback = reactive({})
    const feedbackList = businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`)
    const businessPartnersWithoutFeedback = computed(() => {
        return feedbackList.filter((businessPartner) => businessPartner.hasFeedback === false)
    })
    const selectedBusinessPartner = computed(() => {
        return feedbackList.find((businessPartner) => businessPartner.selected === true)
    })

    const saveDisabled = computed(() => {
        return !feedback.isValid || userStore.currentSelection.scope!=='salesAgent'
    })

    const selectableProducts = computed(() => {
        const alreadySelectedProducts = []
        const selectedBusinessPartner = feedbackList.find((businessPartner) => businessPartner.selected === true)
        if (selectedBusinessPartner) alreadySelectedProducts.push(...selectedBusinessPartner?.offerList.map((offer) => offer.product.id))
        // console.log(alreadySelectedProducts)
        alreadySelectedProducts.push(...additionalProducts.map((product) => product.id))
        // console.log(alreadySelectedProducts)
        const unselectedProducts = userStore.filterOption.businessPartnerPotential.filter((product) => product.id !== -1 && !alreadySelectedProducts.includes(parseInt(product.id.toString().split(':')[1])))
        // console.log(unselectedProducts)
        return unselectedProducts
        // return (userStore.filterOption) ? userStore.filterOption.businessPartnerPotential.slice(1).filter((product) => !alreadySelectedProducts.includes(product.id)) : []
    })

    const selectableProductsForFollowUpAppointment = computed(() => {
        const alreadySelectedProducts = []
        const selectedBusinessPartner = feedbackList.find((businessPartner) => businessPartner.selected === true)
        // if (selectedBusinessPartner) alreadySelectedProducts.push(...selectedBusinessPartner?.offerList.map((offer) => offer.product.id))
        alreadySelectedProducts.push(...additionalProductsForFollowUpAppointment.map((product) => product.id))
        // console.log(alreadySelectedProducts)
        const unselectedProducts = userStore.filterOption.businessPartnerPotential.filter((product) => product.id !== -1 && !alreadySelectedProducts.includes(parseInt(product.id.toString().split(':')[1])))
        // console.log(unselectedProducts)
        return unselectedProducts
        // return (userStore.filterOption) ? userStore.filterOption.businessPartnerPotential.slice(1).filter((product) => !alreadySelectedProducts.includes(product.id)) : []
    })

    onMounted(() => {
        for (const businessPartner of feedbackList) {
            Object.assign(businessPartner, { followUpAppointmentOfferList: [], followUpAppointmentPotentals: [], showFollowUpAppointment: false, followUpAppointment: false, hasFeedback: false, selected: false, feedback: null })
        }
        // open up the first businessPartner for feedback
        handleSelectBusinessPartner(feedbackList[0].id);
        // console.log('businessPartners without Feedback', businessPartnersWithoutFeedback)
    })

    const handleSelectBusinessPartner = (id) => {
        // console.log('selecting businessPartner', id)
        // console.log('feedbackList', feedbackList)
        // if the already selected businessPartner is clicked again, deselect it
        const selectedBusinessPartner = feedbackList.find((businessPartner) => businessPartner.selected === true)
        if (selectedBusinessPartner?.id===id) {
            selectedBusinessPartner.selected = false;
            return false
        }
        // if another businessPartner selected, open it and deselect all others
        for (const businessPartner of feedbackList) { Object.assign(businessPartner, { selected: (businessPartner.id === id) ? true : false }) }
        Object.assign(feedback, {
            isValid: false,
            displayAction: true,
            businessPartner: Object.assign({}, feedbackList.find((businessPartner) => businessPartner.id === id)),
            rescheduleMode: null,
            rescheduleDate: null,
            rescheduleTime: null,
            followUpAppointmentMode: null,
            followUpAppointmentDate: null,
            followUpAppointmentTime: null,
        })
        for (let offer of feedback.businessPartner.salesPitch.offerList) {
            Object.assign(offer, {
                status: {
                    name: 'Abschluss',
                    nameAdd: (feedback.businessPartner.salesPitch.status.id === 6) ? 'telefonisch' : 'vor Ort',
                    id: (feedback.businessPartner.salesPitch.status.id === 6) ? 20 : 19,
                    refusalReason: null,
                }, product: {
                    id: offer.id
                }
            });
        }
        Object.assign(feedback.businessPartner.salesPitch, { skipBusinessPartnerUpdate: true, dateFollowUp: null })
        handleCheckFeedback(id);
    }

    function handleAddProduct(id) {
        const businessPartner = feedbackList.find((businessPartner) => businessPartner.id === id)
        if(!businessPartner.showFollowUpAppointment) {
            const selectedProduct = userStore.filterOption.businessPartnerPotential.find((product) => product.id === additionalProduct.value);
            // console.log('handleAddProduct', selectedProduct);
            additionalProducts.push({ id: selectedProduct.id, shortcut: selectedProduct.shortcut, name: selectedProduct.name, selfCreated: true });
            additionalProduct.value = -1;
        } else {
            const selectedProduct = userStore.filterOption.businessPartnerPotential.find((product) => product.id === additionalProductForFollowUpAppointment.value);
            // console.log('handleAddProduct', selectedProduct);
            additionalProductsForFollowUpAppointment.push({ id: selectedProduct.id, shortcut: selectedProduct.shortcut, name: selectedProduct.name, selfCreated: true });
            additionalProductForFollowUpAppointment.value = -1;
            handleCheckFeedback(id);
        }
    }

    function handleRemoveAdditionalProductList(idProduct, idBusinessPartner){
        const businessPartner = feedbackList.find((businessPartner) => businessPartner.id === idBusinessPartner)
        if(!businessPartner.showFollowUpAppointment) {
            // const product = feedback.businessPartner.salesPitch.offerList.find((product) => product.id === id);
            additionalProducts.splice(additionalProducts.findIndex((product) => product.id === idProduct), 1);
        } else {
            additionalProductsForFollowUpAppointment.splice(additionalProductsForFollowUpAppointment.findIndex((product) => product.id === idProduct), 1);
            handleCheckFeedback(idBusinessPartner);
        }
    }

    function handleRemoveAdditionalProduct(id) {
        // console.log('handleRemoveAdditionalProduct', id);
        const product = feedback.businessPartner.salesPitch.offerList.find((product) => product.id === id);
        // console.log('product', product.status.selfCreated);
        feedback.businessPartner.salesPitch.offerList.splice(feedback.businessPartner.salesPitch.offerList.findIndex((product) => product.id === id), 1);
    }

    function handleAddAllProducts(id) {
        // console.log('handleAddProduct', additionalProducts);
        // const selectedBusinessPartner = feedbackList.find((businessPartner) => businessPartner.selected === true)
        const businessPartner = feedbackList.find((businessPartner) => businessPartner.id === id)
        if(!businessPartner.showFollowUpAppointment) {
            for (const product of additionalProducts) {
                businessPartner.offerList.push({   
                    id: parseInt( product.id.toString().replaceAll('product:', '') ), 
                    shortcut: product.shortcut, 
                    name: product.name,
                    status: {
                        name: 'Abschluss',
                        nameAdd: (feedback.businessPartner.salesPitch.status.id === 6) ? 'telefonisch' : 'vor Ort',
                        id: (feedback.businessPartner.salesPitch.status.id === 6) ? 20 : 19,
                        refusalReason: null,
                        selfCreated: true
                    }, product: {
                        id: parseInt( product.id.toString().replaceAll('product:', '') )
                    }
                });
            }
            // empty the additionalProducts array
            additionalProducts.length = 0;
        } else {
            console.log('add folgetermin potential')
            for (const product of additionalProductsForFollowUpAppointment) {
                businessPartner.followUpAppointmentOfferList.push({   
                    id: parseInt( product.id.toString().replaceAll('product:', '') ), 
                    shortcut: product.shortcut, 
                    name: product.name,
                    status: {
                        name: 'Abschluss',
                        nameAdd: (feedback.businessPartner.salesPitch.status.id === 6) ? 'telefonisch' : 'vor Ort',
                        id: (feedback.businessPartner.salesPitch.status.id === 6) ? 20 : 19,
                        refusalReason: null,
                        selfCreated: true
                    }, product: {
                        id: parseInt( product.id.toString().replaceAll('product:', '') )
                    }
                });
            }
            // empty the additionalProducts array
            additionalProductsForFollowUpAppointment.length = 0;
        }
    }

    const handleCheckFeedback = (id) => {
        const businessPartner = feedbackList.find((businessPartner) => businessPartner.id === id)
        // console.log(feedback)
        // console.log('Start', feedback.isValid, feedback.followUpAppointmentMode)
        feedback.isValid = false;
        if(!businessPartner.showFollowUpAppointment) {
            // console.log('checking feedback', feedback.displayAction)
            if (feedback.displayAction === false) {
                // console.log('feedback #2', feedback.displayAction)
                if (feedback.rescheduleMode == 2) {
                    // console.log('feedback #3', feedback.displayAction)
                    // rescheduleMode 2: Wiedervorlage only needs a date
                    feedback.isValid = (feedback.rescheduleDate) ? true : false;
                } else if (feedback.rescheduleMode) {
                    // rescheduleMode 6/7: verbindlicher Termin tel/vor Ort needs date and time
                    feedback.isValid = (feedback.rescheduleDate && feedback.rescheduleTime) ? true : false;
                }
                if (feedback.isValid) {
                    feedback.businessPartner.salesPitch.dateFollowUp = `${feedback.rescheduleDate}T${(feedback.rescheduleTime) ? feedback.rescheduleTime : '00:00'}:00.000Z`
                    // on rescheduling all offers need to have the status 23: Terminiert again
                    for (const offer of feedback.businessPartner.salesPitch.offerList) Object.assign(offer.status, { id: 23, name: 'Terminiert', refusalReason: null })
                }
            } else if (feedback.displayAction === true) {
                // console.log('display action is', feedback.displayAction, 'salesPitch', feedback.businessPartner.salesPitch)
                for (const offer of feedback.businessPartner.salesPitch.offerList) {
                    if (['Abschluss', 'nicht Angesprochen', 'Interesse (Folgekampagne)'].includes(offer.status.name)) feedback.isValid = (offer.status.id) ? true : false;
                    if (offer.status.name === 'Abgelehnt') feedback.isValid = (offer.refusalReason) ? true : false;
                    // set the corect status id based on the name
                    switch (offer.status.name) {
                        case 'Abschluss':
                            offer.status.id = (feedback.businessPartner.salesPitch.status.id === 6) ? 20 : 19;
                            break;
                        case 'Abgelehnt':
                            offer.status.id = 21;
                            break;
                        case 'nicht Angesprochen':
                            offer.status.id = 28;
                            break;
                        case 'Interesse (Folgekampagne)':
                            offer.status.id = 18;
                            break;
                        default:
                            break;
                    }
                    // console.log('managed offer', offer.name, 'status', offer.status.name, offer.status.id, offer.refusalReason)
                    
                    if (feedback.isValid === false) return false;
                }
            }
            // console.log('reschedule, mode is', feedback.rescheduleMode, 'isValid', feedback.isValid, 'salesPitch', feedback.businessPartner.salesPitch)
            return true;
        } else {
            // console.log('feedback #2', feedback.displayAction)
            if (feedback.followUpAppointmentMode) {
                // followUpAppointmentMode 6/7: verbindlicher Termin tel/vor Ort needs date and time
                feedback.isValid = (feedback.followUpAppointmentDate && feedback.followUpAppointmentTime && additionalProductsForFollowUpAppointment.length > 0) ? true : false;
            }
            if (feedback.isValid) feedback.businessPartner.salesPitch.dateFollowUp = `${feedback.followUpAppointmentDate}T${(feedback.followUpAppointmentTime) ? feedback.followUpAppointmentTime : '00:00'}:00.000Z`
        }
        // console.log('End', feedback.isValid)
    }

    const handleSaveFeedback = async (id) => {
        const businessPartner = feedbackList.find((businessPartner) => businessPartner.id === id)
        if (!businessPartner || handleCheckFeedback(id) === false) return false
        // make sure that any old salesPitch is removed
        delete businessPartner.salesPitch
        let salesPitch = {}
        
        if(!businessPartner.showFollowUpAppointment) {
            // build a salesPitch from regular feedback (not the followUpAppointment)
            console.log('regular save')
            salesPitch = {
                id: null,
                skipBusinessPartnerUpdate: true,
                status: (feedback.displayAction) ?  {id : 22} : { id: parseInt(feedback.rescheduleMode)},
                dateFollowUp: (feedback.displayAction) ? null : feedback.businessPartner.salesPitch.dateFollowUp,
                contact: businessPartner.contact,
                offerList: feedback.businessPartner.salesPitch.offerList,
            }
        } else {
            // build a salesPitch from the followUpAppointment data
            console.log('followUpAppointment save')
            salesPitch = {
                id: null,
                isFollowUpAppointment: true,
                skipBusinessPartnerUpdate: true,
                status: { id: parseInt(feedback.followUpAppointmentMode)},
                dateFollowUp: feedback.businessPartner.salesPitch.dateFollowUp,
                comment: feedback.businessPartner.salesPitch.comment,
                contact: businessPartner.contact,
                offerList: [],
            }
            // fill the offers from additionalProductsForFollowUpAppointment with status 'terminiert'
            for (const product of additionalProductsForFollowUpAppointment) {
                salesPitch.offerList.push({
                    id: parseInt( product.id.toString().replaceAll('product:', '') ), 
                    shortcut: product.shortcut, 
                    name: product.name,
                    status: { 
                        name: 'Terminiert',
                        id: 23,  
                        refusalReason: null,
                        selfCreated: true
                    }, product: {
                        id: parseInt( product.id.toString().replaceAll('product:', '') )
                    }
                })
            }
        }
        Object.assign(businessPartner, { salesPitch: salesPitch })
        businessPartner.salesPitchList = [businessPartner.salesPitch]
        console.log('saving feedback', businessPartner)
        isSaving.value = true
        const saveFeedbackSuccess = await businessPartnersStore.saveFeedback(businessPartner)
        await userStore.refreshAppointments()
        isSaving.value = false
        if(businessPartner.followUpAppointment === true && !salesPitch.isFollowUpAppointment) {
            console.log('returning from regular save, displayin followUpAppointment')
            businessPartner.showFollowUpAppointment = true
            feedback.isValid = false
        } else {
            console.log(`returning from ${ salesPitch.isFollowUpAppointment ? 'followUpSave': 'regular'} save, closing feedback`)
            // on success, mark the businessPartner as having feedback
            if (saveFeedbackSuccess) {
                // after a successfull save and bevore re-routing back to the dashbord update the feedbackList in the store
                feedbackList.find((businessPartner) => businessPartner.id === id).hasFeedback = true
                // console.log('feedback saved refreshing list')
                await businessPartnersStore.getByFilter(businessPartnersStore.namedFilter('ToDo:VorOrtTermine'))
                
                if (feedbackList.filter((businessPartner) => businessPartner.hasFeedback === false).length === 0) {
                    router.push({ name: 'Dashboard', hash: '#potential-bk' })
                } else {
                    handleSelectBusinessPartner(feedbackList.find((businessPartner) => businessPartner.hasFeedback === false).id)
                }
            } else {
                // TODO: handle error, i.e. if something went wrong like a poPup
                console.log('feedback not saved')
            }
            
            return true
        }
    }

    const handleAbortFeedback = (id) => {
        console.log('hanuta4')
        // on abort, mark the businessPartner as having feedback
        feedbackList.find((businessPartner) => businessPartner.id === id).hasFeedback = true

        
        if (feedbackList.filter((businessPartner) => businessPartner.hasFeedback === false).length === 0) {
            router.push({ name: 'Dashboard', hash: '#potential-bk' })
        } else {
            handleSelectBusinessPartner(feedbackList.find((businessPartner) => businessPartner.hasFeedback === false).id)
        }
        
        return true
    }
</script>

<template>
    <div class="reminder-wrapper singlepage-wrapper max-width">
        <main id="container-main">
            <div>
                <div class="widget-wrapper potential-bk feedback">
                    <router-link to="/dashboard#potential-bk">
                        <div class="headline-wrapper">
                            <h2>Feedback <span>{{ businessPartnersWithoutFeedback.length }}</span></h2>
                        </div>
                    </router-link>
                    <div class="container-wrapper scroll-y">
                        <div :class="['customer', { 'selected': businessPartner.selected }]" v-for="(businessPartner, index) in businessPartnersWithoutFeedback" :key="businessPartner.id">
                            <span v-if="index < 9" class="businessPartnerIndex">0{{ index + 1 }}</span>
                            <span v-else class="businessPartnerIndex">{{ index + 1 }}</span>
                            <div class="informations-wrapper">
                                <div class="accordion-menu notes-details">
                                    <ul class="main-list">
                                        <userAvatar v-if="userStore.currentSelection.scope!=='salesAgent'" :showSalesAgent=true :isSalesAgent="!userStore.isManagement && !userStore.isSalesTeamManager" :salesAgentId="businessPartner.idSalesAgent" />
                                        <li class="main-list-item">
                                            <h3 class="txt-overflow" @click="handleSelectBusinessPartner(businessPartner.id)">
                                                {{ businessPartner.name }}
                                                <i class="arrow"></i>
                                            </h3>
                                            <!-- <div>{{ businessPartner.salesAgent }}</div> -->
                                            <!-- <userAvatar v-if="userStore.currentSelection.scope!=='salesAgent'" :showSalesAgent=true :isSalesAgent="!userStore.isManagement && !userStore.isSalesTeamManager" :salesAgentId="businessPartner.salesAgentId" /> -->
                                            <div class="accordion-content-wrapper">
                                                <div class="accordion-content">
                                                    <div class="nav">
                                                        <div :class="['input-wrapper toggle']">
                                                            <span :class="['switcher switcher-1', {'FollowUpAppointment' : businessPartner.showFollowUpAppointment}]">
                                                                <input type="checkbox" id="switcher-1"
                                                                    @change="handleCheckFeedback(businessPartner.id)"
                                                                    v-model="feedback.displayAction">
                                                                <label for="switcher-1"></label>
                                                            </span>
                                                        </div>
                                                        <router-link :to="{ name: 'BusinessPartner', query: { searchMode: 'GPNo', search: businessPartner.gpNo } }" v-if="!businessPartner.showFollowUpAppointment"><button><div><span>Kundendetails</span></div></button></router-link>
                                                    </div>
                                                    <p v-if="feedback.followUpAppointmentMode && businessPartner.showFollowUpAppointment && additionalProductsForFollowUpAppointment.length === 0 && feedback.followUpAppointmentDate && feedback.followUpAppointmentTime" class="warning-txt">Bitte mindestens 1 Potential auswählen!</p>
                                                    <!-- <p v-else-if="feedback.followUpAppointmentMode && businessPartner.showFollowUpAppointment && (!feedback.followUpAppointmentDate || !feedback.followUpAppointmentTime)" class="warning-txt">Bitte Datum & Uhrzeit auswählen!</p>
                                                    <p v-else-if="businessPartner.showFollowUpAppointment" class="warning-txt">Bitte Terminart treffen!</p> -->
                                                    <div v-if="isSaving" class="container main-content cell-grid">
                                                        <div class="loader" id="loader"></div>
                                                    </div>
                                                    <template v-if="!businessPartner.showFollowUpAppointment">
                                                        <div :class="['follow-up-appointment container', {'cell-grid disabled' : saveDisabled}]">
                                                            <input type="checkbox" id="follow-up-appointment" v-model="businessPartner.followUpAppointment">
                                                            <label for="follow-up-appointment">Kunde möchte Folgetermin</label>
                                                        </div>
                                                        <div class="informations-wrapper">
                                                            <div v-if="[true].includes(feedback.displayAction)" class="input-wrapper feedback list">
                                                                <div class="anchor" :id="'_' + index"></div>
                                                                <div :class="['potential-wrapper container', {'selfCreated' : offer.status.selfCreated}]"
                                                                    v-for="(offer, feedbackIndex) in feedback.businessPartner.salesPitch.offerList"
                                                                    :key="offer.id">
                                                                    <div class="label-wrapper">
                                                                        <span class="label new">Neu</span>
                                                                        <span class="label delete" @click="handleRemoveAdditionalProduct(offer.id)">Entfernen</span>
                                                                    </div>
                                                                    <div class="headline">
                                                                        <h4 class="status-heading product left">{{ offer.name }}</h4>
                                                                        <span class="index">{{ feedbackIndex + 1 + "/" + feedback.businessPartner.salesPitch.offerList.length }}</span>
                                                                    </div>
                                                                    <div class="inputs">
                                                                        <div class="single-input">
                                                                            <input type="radio" selected id="Abschluss" @change="handleCheckFeedback(feedback.businessPartner.id)" value="Abschluss" v-model="offer.status.name">
                                                                            <label for="Abschluss">Abschluss {{ offer.status.nameAdd}}</label>
                                                                        </div>
                                                                        <div class="single-input">
                                                                            <input type="radio" id="Abgelehnt" @change="handleCheckFeedback(feedback.businessPartner.id)" value="Abgelehnt" v-model="offer.status.name">
                                                                            <label for="Abgelehnt">Abgelehnt</label>
                                                                        </div>
                                                                        <div class="submenu">
                                                                            <div class="single-input" v-if="offer.status.name == 'Abgelehnt'">
                                                                                <input type="radio" selected id="kein Interesse" value="kein Interesse" @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="offer.refusalReason">
                                                                                <label for="kein Interesse">kein Interesse</label>
                                                                            </div>
                                                                            <div class="single-input" v-if="offer.status.name == 'Abgelehnt'">
                                                                                <input type="radio" selected id="anderer Anbieter" value="anderer Anbieter" @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="offer.refusalReason">
                                                                                <label for="anderer Anbieter">anderer Anbieter</label>
                                                                            </div>
                                                                            <div class="single-input" v-if="offer.status.name == 'Abgelehnt'">
                                                                                <input type="radio" selected id="Inhouse" value="Inhouse" @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="offer.refusalReason">
                                                                                <label for="Inhouse">Inhouse</label>
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="!offer.status.selfCreated" class="single-input">
                                                                            <input type="radio" id="nicht Angesprochen" @change="handleCheckFeedback(feedback.businessPartner.id)" value="nicht Angesprochen" v-model="offer.status.name">
                                                                            <label for="nicht Angesprochen">Nicht Angesprochen</label>
                                                                        </div>
                                                                        <div class="single-input">
                                                                            <input type="radio" id="Interesse (Folgekampagne)" @change="handleCheckFeedback(feedback.businessPartner.id)" value="Interesse (Folgekampagne)" v-model="offer.status.name">
                                                                            <label for="Interesse (Folgekampagne)">Interesse (Folgekampagne)</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="potential-wrapper container add">
                                                                    <div class="headline">
                                                                        <h4 class="status-heading plus left">Potential hinzufügen</h4>
                                                                    </div>
                                                                    <div class="potentiale-pending">
                                                                        <div class="info-wrapper container" v-for="product in additionalProducts" :key="product.id">
                                                                            <div class="select-wrapper">
                                                                                <p :title="'Potential'" class="_16">{{ product.name }}</p>
                                                                                <button @click="handleRemoveAdditionalProductList(product.id, businessPartner.id)"></button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="add-potential-wrapper container">
                                                                            <select v-model="additionalProduct">
                                                                                <option disabled value="-1" selected>Weiteres Produkt hinzufügen</option>
                                                                                <option v-for="product in selectableProducts" :key="product.id" :value="product.id">
                                                                                    {{ product.name }}
                                                                                </option>
                                                                            </select>
                                                                            <button @click="handleAddProduct(feedback.businessPartner.id)" :disabled="additionalProduct===-1" class="add-potential round"></button>
                                                                        </div>
                                                                        <a :href="'#_' + index"><button @click="handleAddAllProducts(feedback.businessPartner.id)" class="btn-add-potentials">Potentiale hinzufügen</button></a>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="[false].includes(feedback.displayAction)"
                                                                class="input-wrapper feedback list">
                                                                <div class="potential-wrapper container">
                                                                    <div class="headline">
                                                                        <h4 class="status-heading reschedule left">Verschieben auf:</h4>
                                                                    </div>
                                                                    <div class="inputs">
                                                                        <div class="single-input">
                                                                            <input type="radio" id="rescheduleVorOrt" value="7" @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="feedback.rescheduleMode">
                                                                            <label for="rescheduleVorOrt">verbindlicher vor Ort Termin</label>
                                                                        </div>
                                                                        <div class="single-input">
                                                                            <input type="radio" id="rescheduleTel" value="6" @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="feedback.rescheduleMode">
                                                                            <label for="rescheduleTel">verbindlicher tel. Termin</label>
                                                                        </div>
                                                                        <div class="single-input">
                                                                            <input type="radio" id="rescheduleWVL" value="2" @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="feedback.rescheduleMode">
                                                                            <label for="rescheduleWVL">nur Wiedervorlage</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="top-row">
                                                                        <input @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="feedback.rescheduleDate" class="btn" type="date" required>
                                                                        <input @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="feedback.rescheduleTime" class="btn" type="time" id="appt-time" name="appt-time" min="06:30" max="22:00" :required="feedback.rescheduleMode !== 2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="informations-wrapper">
                                                            <div class="input-wrapper feedback list">
                                                                <div class="anchor" :id="'_' + index"></div>
                                                                <div :class="['potential-wrapper container']">
                                                                    <div class="headline">
                                                                        <h4 class="status-heading reschedule left">Folgetermin am:</h4>
                                                                    </div>
                                                                    <div class="inputs">
                                                                        <div class="single-input">
                                                                            <input type="radio" id="rescheduleVorOrt" value="7" @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="feedback.followUpAppointmentMode">
                                                                            <label for="rescheduleVorOrt">verbindlicher vor Ort Termin</label>
                                                                        </div>
                                                                        <div class="single-input">
                                                                            <input type="radio" id="rescheduleTel" value="6" @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="feedback.followUpAppointmentMode">
                                                                            <label for="rescheduleTel">verbindlicher tel. Termin</label>
                                                                        </div>
                                                                        <!-- <div class="single-input">
                                                                            <input type="radio" id="rescheduleWVL" value="2" @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="feedback.rescheduleMode">
                                                                            <label for="rescheduleWVL">doch nicht (lol)</label>
                                                                        </div> -->
                                                                    </div>
                                                                    <div class="top-row">
                                                                        <input @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="feedback.followUpAppointmentDate" class="btn" type="date" required>
                                                                        <input @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="feedback.followUpAppointmentTime" class="btn" type="time" id="appt-time" name="appt-time" min="06:30" max="22:00" :required="feedback.rescheduleMode !== 2" />
                                                                    </div>
                                                                </div> 
                                                                <div :class="['potential-wrapper container', {'selfCreated' : offer.status.selfCreated}]"
                                                                    v-for="(offer, followUpAppointmentOfferListIndex) in feedback.businessPartner?.followUpAppointmentOfferList"
                                                                    :key="offer.id">
                                                                    <div class="label-wrapper">
                                                                        <span class="label new">Neu</span>
                                                                        <span class="label delete" @click="handleRemoveAdditionalProduct(offer.id)">Entfernen</span>
                                                                    </div>
                                                                    <div class="headline">
                                                                        <h4 class="status-heading product left">{{ offer.name }}</h4>
                                                                        <span class="index">{{ followUpAppointmentOfferListIndex + 1 + "/" + feedback.businessPartner.salesPitch.offerList.length }}</span>
                                                                    </div>
                                                                    <div class="inputs">
                                                                        <div class="single-input">
                                                                            <input type="radio" selected id="Abschluss" @change="handleCheckFeedback(feedback.businessPartner.id)" value="Abschluss" v-model="offer.status.name">
                                                                            <label for="Abschluss">Abschluss {{ offer.status.nameAdd}}</label>
                                                                        </div>
                                                                        <div class="single-input">
                                                                            <input type="radio" id="Abgelehnt" @change="handleCheckFeedback(feedback.businessPartner.id)" value="Abgelehnt" v-model="offer.status.name">
                                                                            <label for="Abgelehnt">Abgelehnt</label>
                                                                        </div>
                                                                        <div class="submenu">
                                                                            <div class="single-input" v-if="offer.status.name == 'Abgelehnt'">
                                                                                <input type="radio" selected id="kein Interesse" value="kein Interesse" @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="offer.refusalReason">
                                                                                <label for="kein Interesse">kein Interesse</label>
                                                                            </div>
                                                                            <div class="single-input" v-if="offer.status.name == 'Abgelehnt'">
                                                                                <input type="radio" selected id="anderer Anbieter" value="anderer Anbieter" @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="offer.refusalReason">
                                                                                <label for="anderer Anbieter">anderer Anbieter</label>
                                                                            </div>
                                                                            <div class="single-input" v-if="offer.status.name == 'Abgelehnt'">
                                                                                <input type="radio" selected id="Inhouse" value="Inhouse" @change="handleCheckFeedback(feedback.businessPartner.id)" v-model="offer.refusalReason">
                                                                                <label for="Inhouse">Inhouse</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="single-input">
                                                                            <input type="radio" id="nicht Angesprochen" @change="handleCheckFeedback(feedback.businessPartner.id)" value="nicht Angesprochen" v-model="offer.status.name">
                                                                            <label for="nicht Angesprochen">Nicht Angesprochen</label>
                                                                        </div>
                                                                        <div class="single-input">
                                                                            <input type="radio" id="Interesse (Folgekampagne)" @change="handleCheckFeedback(feedback.businessPartner.id)" value="Interesse (Folgekampagne)" v-model="offer.status.name">
                                                                            <label for="Interesse (Folgekampagne)">Interesse (Folgekampagne)</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="potential-wrapper container add">
                                                                    <div class="headline">
                                                                        <h4 class="status-heading plus left">Folgetermin Potential</h4>
                                                                    </div>
                                                                    <div class="potentiale-pending">
                                                                        <div class="info-wrapper container" v-for="product in additionalProductsForFollowUpAppointment" :key="product.id">
                                                                            <div class="select-wrapper">
                                                                                <p :title="'Potential'" class="_16">{{ product.name }}</p>
                                                                                <button @click="handleRemoveAdditionalProductList(product.id, businessPartner.id)"></button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="add-potential-wrapper container">
                                                                            <select v-model="additionalProductForFollowUpAppointment">
                                                                                <option disabled value="-1" selected>Weiteres Produkt hinzufügen</option>
                                                                                <option v-for="product in selectableProductsForFollowUpAppointment" :key="product.id" :value="product.id">
                                                                                    {{ product.name }}
                                                                                </option>
                                                                            </select>
                                                                            <button @click="handleAddProduct(feedback.businessPartner.id)" :disabled="additionalProductForFollowUpAppointment===-1" class="add-potential round"></button>
                                                                        </div>
                                                                        <!-- <a :href="'#_' + index"><button @click="handleAddAllProducts(feedback.businessPartner.id)" class="btn-add-potentials">Potentiale hinzufügen</button></a> -->

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <textarea class="container" v-model="feedback.businessPartner.salesPitch.comment" placeholder="Kommentar zum Folgetermin ?" />
                                                    </template>
                                                    <button class="cancel customerCancel" v-if="businessPartner.showFollowUpAppointment"><div><span>Abbrechen</span></div></button>
                                                    <button class="customerSave" :disabled="userStore.preventSave || saveDisabled" @click="handleSaveFeedback(businessPartner.id)">Speichern</button>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <reminder></reminder>
    </div>
</template>