<script setup>
    import { computed, onMounted } from 'vue'

    import PreviewIncentives from '@/components/previews/PreviewIncentives.vue'
    import PreviewBusinessPartnerList from '@/components/previews/PreviewBusinessPartnerList.vue'
    import PreviewActivity from '@/components/previews/PreviewActivity.vue'
    import PreviewPerformanceFacility24 from '@/components/previews/PreviewPerformance-facility24.vue'
    import PreviewPerformanceFacility25 from '@/components/previews/PreviewPerformance-facility25.vue'
    import PreviewReviews from '@/components/previews/PreviewReviews.vue'

    import Reminder from '@/components/Reminder.vue'

    import { useIncentivesStore } from '@/stores/incentives'
    const incentivesStore = useIncentivesStore();

    import { useBusinessPartnersStore } from '@/stores/businessPartners'
    const businessPartnersStore = useBusinessPartnersStore();

    import { useUserStore } from '../stores/user'
    const userStore = useUserStore();

    onMounted(async() => {
        if (userStore.currentSelection.campaign.id===null) await userStore.selectCampaign(userStore.idCampaignLogin);
    })

    function handleDisplayPopUp(type) {
        const signature = `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:${type}`;
        return businessPartnersStore.businessPartnerListBySignature(signature).length > 0;
    }

    const exceededToDos = computed(() => {
        if (!userStore.isSalesAgent) return null;
        if (userStore.surname === 'vom Hofe') return null; // debug only
        const salesPitchFollowUps = businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`);
        const salesPitchFeedbacks = businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`);
        return {
            both: (salesPitchFollowUps || []).length > userStore.maxToDos.followUp && (salesPitchFeedbacks || []).length > userStore.maxToDos.feedback,
            followUps: (salesPitchFollowUps || []).length > userStore.maxToDos.followUp,
            feedbacks: (salesPitchFeedbacks || []).length > userStore.maxToDos.feedback
        }
    });
</script>

<template>
    <div class="reminder-wrapper max-width">
        <!-- <div v-if="exceededToDos" class="potentialpopUp">
            <div class="contentWrapper">
                <div class="header">
                    <img src="@/assets/imgs/icons/triangle-exclamation-solid.svg" alt="Obacht!">
                </div>
                <div class="warningText">
                    <h2>Warnung!</h2>
                    <p v-if="exceededToDos.both">Die maximale Anzahl unbearbeitetem Termin-Feedback von {{ userStore.maxToDos.feedback }} und Wiedervorlagen wurde erreicht. Bitte bearbeite ältere Termine und Wiedervorlagen, um fortzufahren.</p>
                    <p v-else-if="exceededToDos.feedbacks">Die maximale Anzahl unbearbeitetem Termin-Feedback von {{ userStore.maxToDos.feedback }} wurde erreicht. Bitte bearbeite ältere Termine, um fortzufahren.</p>
                    <p v-else>Die maximale Anzahl unbearbeiteter Wiedervorlagen von von {{ userStore.maxToDos.followUp }} wurde erreicht. Bitte bearbeite ältere Wiedervorlagen, um fortzufahren.</p>
                </div>
                <div><router-link to="/salesPitchFollowUp" :class="['warning-btn warning-follow-up', {'negative' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length > 0}]" >Wiedervorlagen To-Do<span class="number-label">{{ businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length}}</span></router-link></div>
                <div><router-link to="/salesPitchFeedback" :class="['warning-btn warning-appointments', {'negative' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length > 0}]">Termin-Feedback<span class="number-label">{{ businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length}}</span></router-link></div>    
            </div>
        </div>  -->
        <main :class="['dashboard', userStore.currentSelection.campaign?.id ? `campaign-${userStore.currentSelection.campaign.id}` : 'no-campaign']">
            <preview-incentives></preview-incentives>
            <preview-business-partner-list></preview-business-partner-list>
            <preview-activity></preview-activity>
            <preview-performance-facility24 v-if="userStore.currentSelection.campaign.id <= 22"></preview-performance-facility24>
            <preview-performance-facility25 v-if="userStore.currentSelection.campaign?.id > 22"></preview-performance-facility25>

            <preview-reviews></preview-reviews>
        </main>
        <reminder></reminder>
        <div class="disclaimer-wrapper">
            <div class="widget-wrapper">
                <div class="container-wrapper">
                    <div class="container disclaimer">
                        <p><span class="asterisk">&#42;&sup1;</span>: Aktualisiert am {{ incentivesStore?.incentive?.salesAgents?.dateUpdated}}<br>{{ incentivesStore.incentive?.salesAgents?.disclaimer}}</p>
                        <p><span class="asterisk">&#42;&sup2;</span>: {{ incentivesStore?.incentive?.salesTeams?.disclaimer}}</p>
                        <p><span class="asterisk">&#42;&sup3;</span>: Diese Werte dienen nur der Orientierung und ersetzen nicht die Endabrechnung aus SAP.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>