<script setup>
    import { ref, onMounted } from 'vue'
    
    import Reminder from '@/components/Reminder.vue'

    import { useUserStore } from '@/stores/user';
    const userStore = useUserStore();

    import { useActivityStore } from '@/stores/activity'
    const activityStore = useActivityStore();

    import Bar from '@/components/Bar.vue';
    import BarDetailed from '@/components/BarDetailed.vue';

    const idSelectedWeek = ref(null);
    const idSelectedMonth = ref(null);
    const activitySelection = ref({
        week: {
            opened: false,
            title: null
        },
        month: {
            opened: false,
            title: null
        },
        both: {
            opened: false,
            title: null
        }
    });

    onMounted(() => {
        const currentWeek = activityStore.activity.week.find((week) => week.isCurrent);
        if (currentWeek) idSelectedWeek.value = currentWeek.id;
        const currentMonth = activityStore.activity.month.find((month) => month.isCurrent);
        if (currentMonth)  idSelectedMonth.value = currentMonth.id;
        if(userStore.selectedMedia==='desktop') {
            activitySelection.value['week'].title = currentWeek.name;
            activitySelection.value['month'].title = currentMonth.name;
        } else {
            activitySelection.value['both'].title = currentWeek.name;
         }
        // ensure that the widgets data are always set to the current week and month
        for (const segment of ['week', 'month']) {
            if (activityStore.activity[segment]) userStore.currentSelection.activity[segment] = activityStore.activity[segment].find((entry) => entry.isCurrent).id;
        }
    })

    async function handleSelectActivity(display, id) {
        if(userStore.selectedMedia==='desktop') {
            // console.log('handleSelectActivity', display, id);
            switch (display){
                case 'week':
                    userStore.currentSelection.activity.week = id
                    activitySelection.value['week'].title = activityStore.activity.week.find((week) => week.id === id).name;
                    break;
                case 'month':
                    userStore.currentSelection.activity.month = id
                    activitySelection.value['month'].title = activityStore.activity.month.find((month) => month.id === id).name;
                    break;
                case 'halftime':
                    userStore.currentSelection.activity.halftime = true
                    // activitySelection.value.title = 'Halbz.';
                    break;
                case 'campaign':
                    userStore.currentSelection.activity.campaign = true
                    // activitySelection.value.title = 'Kamp.';
                    break;  
                default:
                    break;
            }
            activitySelection.value[display].opened = !activitySelection.value[display].opened;
        } else {
            userStore.currentSelection.activity.display = display;
            switch (display){
                case 'week':
                    userStore.currentSelection.activity.week = id
                    activitySelection.value['both'].title = activityStore.activity.week.find((week) => week.id === id).name;
                    break;
                case 'month':
                    userStore.currentSelection.activity.month = id
                    activitySelection.value['both'].title = activityStore.activity.month.find((month) => month.id === id).name;
                    break;
                case 'halftime':
                    userStore.currentSelection.activity.halftime = true
                    activitySelection.value['both'].title = 'Halbz.';
                    break;
                case 'campaign':
                    userStore.currentSelection.activity.campaign = true
                    activitySelection.value['both'].title = 'Kamp.';
                    break;  
                default:
                    break;
            }
            activitySelection.value['both'].opened = !activitySelection.value['both'].opened;

        }
        
    }


    async function handleSelectWeek() {
        userStore.currentSelection.activity.week = idSelectedWeek.value;
    }
    async function handleSelectMonth() {
        userStore.currentSelection.activity.month = idSelectedMonth.value;
    }

    function formatEuroValues(value, isEuro) {
        
        return (value) ? value.toLocaleString('de-DE') + '€' : '0';
    }

    const toggleDropdown = (section) => {
        // console.log('clicked', section);
        activitySelection.value[section].opened = !activitySelection.value[section].opened;
    };

    function getCurrentWeek() {
        const currentDate = new Date();
        const dayNumber = (currentDate.getDay() + 6) % 7 + 1; // Montag = 1, Sonntag = 7
        const firstThursday = new Date(currentDate.getFullYear(), 0, 1);
        firstThursday.setDate(firstThursday.getDate() + (4 - (firstThursday.getDay() + 6) % 7));
        const currentThursday = new Date(currentDate);
        currentThursday.setDate(currentThursday.getDate() + (4 - dayNumber));
        const weekNumber = Math.ceil(((currentThursday - firstThursday) / (24 * 60 * 60 * 1000) + 1) / 7);
        const formattedWeekNumber = String(weekNumber).padStart(2, '0'); // KW immer zweistellig
        const formattedYear = currentDate.getFullYear().toString().slice(-2); // Jahr zweistellig
        return `KW ${formattedWeekNumber}.${formattedYear}`;
    }

    function getCurrentMonth() {
        const currentDate = new Date();
        const monthNames = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
        const month = monthNames[currentDate.getMonth()];
        const year = currentDate.getFullYear().toString().slice(-2);
        return `${month}. ${year}`;
    }

    const currentWeek = ref(getCurrentWeek());
    const currentMonth = ref(getCurrentMonth());
</script>

<template>
    <div class="reminder-wrapper singlepage-wrapper activity max-width">
        <main id="container-main">
             <div v-for="display in ['week','month', 'halftime', 'campaign']" :key="display"> 
                <div v-if="userStore.selectedMedia==='desktop' || userStore.currentSelection.activity.display===display" :class="['widget-wrapper activity halftime',  {'desktopView' : userStore.selectedMedia==='desktop'}]"> 
                    <router-link to="/dashboard#activity">
                        <div v-if="userStore.selectedMedia==='desktop'" class="headline-wrapper">
                            <h2 v-if="display==='week'">
                                <!-- Woche -->
                                <div class="arrowWrapper">
                                    <button @click.prevent="toggleDropdown('week')" class="dropdown-toggle form-child no-border" id="dropdown-toggle">
                                        {{ activitySelection['week'].title}}
                                    </button>
                                    <ul @click.prevent :style="{ display: activitySelection['week'].opened ? 'block' : 'none' }" class="dropdown-menu" id="dropdown-menu">
                                        <li class="headline dropdown-item">Woche:</li>
                                        <li v-for="week in activityStore.activity.week" 
                                            :key="week.id" 
                                            :class="['dropdown-item submenu', { 'current': week.name === currentWeek }]"
                                            @click.prevent="handleSelectActivity('week', week.id)">
                                            <div>{{ week.name }} <span v-if="week.name === currentWeek">(aktuelle Woche)</span></div>
                                        </li>
                                    </ul>
                                </div>
                            </h2>
                            <h2 v-if="display==='month'">
                                <!-- Monat -->
                                <div class="arrowWrapper">
                                    <button @click.prevent="toggleDropdown('month')" class="dropdown-toggle form-child no-border" id="dropdown-toggle">
                                        {{ activitySelection['month'].title }}
                                    </button>
                                    <ul :style="{ display: activitySelection['month'].opened ? 'block' : 'none' }" class="dropdown-menu" id="dropdown-menu">
                                        <li class="headline dropdown-item">Monat:</li>
                                        <li v-for="month in activityStore.activity.month" 
                                            :key="month.id" 
                                            :class="['dropdown-item submenu', { 'current': month.name === currentMonth }]"
                                            @click.prevent="handleSelectActivity('month', month.id)">
                                            <div>{{ month.name }} <span v-if="month.name === currentMonth">(aktueller Monat)</span></div>
                                        </li>
                                    </ul>
                                </div>
                            </h2>
                            <h2 v-if="display==='halftime'">Halbzeit</h2>
                            <h2 v-if="display==='campaign'">Kampagne</h2>
                        </div>
                        <div v-else class="headline-wrapper">
                            <h2>
                                <div class="arrowWrapper">
                                    <button @click.prevent="toggleDropdown('both')" class="dropdown-toggle form-child no-border" id="dropdown-toggle">
                                        {{ activitySelection['both'].title }}
                                    </button>
                                    <ul @click.prevent :style="{ display: activitySelection['both'].opened ? 'block' : 'none' }" class="dropdown-menu" id="dropdown-menu">
                                        <li class="headline dropdown-item">Woche:</li>
                                        <li v-for="week in activityStore.activity.week" 
                                            :key="week.id" 
                                            :class="['dropdown-item submenu', { 'current': week.name === currentWeek }]"
                                            @click.prevent="handleSelectActivity('week', week.id)">
                                            <div>{{ week.name }} <span v-if="week.name === currentWeek">(aktuelle Woche)</span></div>
                                        </li>
                                        <li class="headline dropdown-item">Monat:</li>
                                        <li v-for="month in activityStore.activity.month" 
                                            :key="month.id" 
                                            :class="['dropdown-item submenu', { 'current': month.name === currentMonth }]"
                                            @click.prevent="handleSelectActivity('month', month.id)">
                                            <div>{{ month.name }} <span v-if="month.name === currentMonth">(aktueller Monat)</span></div>
                                        </li>
                                        <li  
                                            :class="['dropdown-item']"
                                            @click.prevent="handleSelectActivity('halftime', true)">
                                            <div>Halbzeit</div>
                                        </li>
                                        <li 
                                            :class="['dropdown-item']" 
                                            @click.prevent="handleSelectActivity('campaign', true)">
                                            <div>Kampagne</div>
                                        </li>
                                    </ul>
                                </div>
                            </h2>
                        </div>
                    </router-link>
                    <div class="container-wrapper">
                        <div class="container main-content">
                            <!-- <h3 class="underlined">Ergebnisse</h3> -->
                            <div class="headline-wrapper">
                                <span class="headline">Ergebnisse</span>
                                <span class="headline">Ist</span>
                                <span class="headline plannedValues">Ziel</span>
                            </div>
                            <div class="bars-wrapper activity" v-if="activityStore[display]">
                                <bar v-if="(activitySelection['week'].title !== 'Forecast' && display === 'week') ||  display !== 'week'" title="Entscheider" :mode="'activityNormal'" :currentValue="activityStore[display].actualValues?.contacts" :goalValue="activityStore[display].plannedValues?.contacts"></bar>
                                <bar v-if="(activitySelection['week'].title !== 'Forecast' && display === 'week') ||  display !== 'week'" title="Termine (Akt.)" :mode="'activityNormal'" :currentValue="activityStore[display].actualValues?.appointmentsActivity" :goalValue="activityStore[display].plannedValues?.appointments"></bar>
                                <bar v-else title="Termine (Kal.)" :mode="'activityNormal'" :currentValue="activityStore[display].actualValues?.appointments" :goalValue="activityStore[display].plannedValues?.appointments"></bar>
                                <!-- <bar v-if="(activitySelection['week'].title !== 'Forecast' && display === 'week') ||  display !== 'week'" title="Kunden" :mode="'activityNormal'" :currentValue="activityStore[display].actualValues?.salesNumber" :goalValue="activityStore[display].plannedValues?.salesNumber"></bar> -->
                                <bar v-if="display === 'month' && userStore.currentSelection.campaign.id <= 22" :points="activityStore['month'].level.points" :title="(display === 'week' && activitySelection['week'].title === 'Forecast') ? 'Forecast' : 'Gesamt'" :mode="'activityPoints'" :isEuro="true" :currentValue="activityStore[display].actualValues?.salesEuro" :goalValue="activityStore[display].plannedValues?.salesEuro"></bar>
                                <bar v-else :title="(display === 'week' && activitySelection['week'].title === 'Forecast') ? 'Forecast' : 'Gesamt'" :mode="'activityNormal'" :isEuro=true :currentValue="activityStore[display].actualValues?.salesEuro" :goalValue="activityStore[display].plannedValues?.salesEuro"></bar>
                                <bar v-if="(activitySelection['week'].title !== 'Forecast' && display === 'week') ||  display !== 'week'" title="Neu-Kd." :mode="'activityNormal'" :isEuro=true :currentValue="activityStore[display].actualValues?.salesEuroNewCustomers" :goalValue="activityStore[display].plannedValues?.salesEuroNewCustomers"></bar>
                            </div>
                        </div>
                        <div class="container main-content" v-if="(activitySelection['week'].title !== 'Forecast' && display === 'week') ||  display !== 'week'">
                            <!-- <h3 class="underlined">Funnel</h3> -->
                            <div class="funnel">
                                <div class="funnelContent">
                                    <div class="funnelRow">
                                        <div class="headline actualValues">
                                            <p class="value">Funnel</p>
                                        </div>
                                        <div class="headline actualValues">
                                            <p class="value">Ist</p>
                                        </div>
                                        <div class="headline plannedValues">
                                            <p class="value">Ziel</p>
                                        </div>
                                    </div>
                                    <div class="funnelRow contacts">
                                        <p class="caption">Entscheider</p>
                                        <p class="caption"><span>Termin-Quote</span></p>
                                        <div class="actualValues">
                                            <p :class="['value', {'achieved' : activityStore[display].actualValues?.contacts >= activityStore[display].plannedValues?.contacts, 'notAchieved' : activityStore[display].actualValues?.contacts < activityStore[display].plannedValues?.contacts}]">{{ activityStore[display].actualValues?.contacts }}</p>
                                            <p :class="['ratio number-label', {'achieved' : activityStore[display].actualValues?.appointmentQuote >= activityStore[display].plannedValues?.appointmentQuote, 'notAchieved' : activityStore[display].actualValues?.appointmentQuote < activityStore[display].plannedValues?.appointmentQuote}]">{{ activityStore[display].actualValues?.appointmentQuote }}%</p>
                                        </div>
                                        <div class="plannedValues">
                                            <p class="value">{{ activityStore[display].plannedValues?.contacts }}</p>
                                            <p class="ratio number-label">{{ activityStore[display].plannedValues?.appointmentQuote }}%</p>
                                        </div>
                                    </div>
                                    <div class="funnelRow appointments">
                                        <p class="caption">Termine (Kal.)</p>
                                        <p class="caption"><span>Abschluss-Quote</span></p>
                                        <div class="actualValues">
                                            <p :class="['value', {'achieved' : activityStore[display].actualValues?.appointments >= activityStore[display].plannedValues?.appointments, 'notAchieved': activityStore[display].actualValues?.appointments< activityStore[display].plannedValues?.appointments}]">{{ activityStore[display].actualValues?.appointments }}</p>
                                            <p :class="['ratio number-label', {'achieved' : activityStore[display].actualValues?.salesQuote >= activityStore[display].plannedValues?.salesQuote, 'notAchieved': activityStore[display].actualValues?.salesQuote < activityStore[display].plannedValues?.salesQuote}]">{{ activityStore[display].actualValues?.salesQuote }}%</p>
                                        </div>
                                        <div class="plannedValues">
                                            <p class="value">{{ activityStore[display].plannedValues?.appointments }}</p>
                                            <p class="ratio number-label">{{ activityStore[display].plannedValues?.salesQuote }}%</p>
                                        </div>
                                    </div>
                                    <div class="funnelRow customers">
                                        <p class="caption">Kunden</p>
                                        <p class="caption"><span>DAW</span></p>
                                        <div class="actualValues">
                                            <p :class="['value', {'achieved' : activityStore[display].actualValues?.salesNumber >= activityStore[display].plannedValues?.salesNumber, 'notAchieved' : activityStore[display].actualValues?.salesNumber < activityStore[display].plannedValues?.salesNumber}]">{{ activityStore[display].actualValues?.salesNumber }}</p>
                                            <p :class="['ratio number-label', {'achieved' : activityStore[display].actualValues?.averageOrderValue >= activityStore[display].plannedValues?.averageOrderValue, 'notAchieved' : activityStore[display].actualValues?.averageOrderValue < activityStore[display].plannedValues?.averageOrderValue}]">{{ formatEuroValues(activityStore[display].actualValues?.averageOrderValue) }}</p>
                                        </div>
                                        <div class="plannedValues">
                                            <p class="value">{{ activityStore[display].plannedValues?.salesNumber }}</p>
                                            <p class="ratio number-label">{{ formatEuroValues(activityStore[display].plannedValues?.averageOrderValue) }}</p>
                                        </div>
                                    </div>
                                    <div class="funnelRow overall">
                                        <p>Gesamt</p>
                                        <div class="actualValues">
                                            <p :class="['value', {'achieved' : activityStore[display].actualValues?.salesEuro >= activityStore[display].plannedValues?.salesEuro, 'notAchieved' : activityStore[display].actualValues?.salesEuro < activityStore[display].plannedValues?.salesEuro}]">{{ formatEuroValues(activityStore[display].actualValues?.salesEuro) }}</p>
                                            <!-- <p class="ratio number-label">{{ activityStore[display].actualValues?.salesQuote }}%</p> -->
                                        </div>
                                        <div class="plannedValues">
                                            <p class="value">{{ formatEuroValues(activityStore[display].plannedValues?.salesEuro) }}</p>
                                            <!-- <p class="ratio number-label">{{ activityStore[display].plannedValues?.salesQuote }}%</p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='wave-wrapper'>
                        <div class='wave -one'></div>
                        <div class='wave -two'></div>
                        <div class='wave -three'></div>
                    </div>
                </div>
            </div>
        </main>
        <reminder></reminder>
    </div>
</template>