<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'

import { useRoute } from 'vue-router'
const route = useRoute()

import Reminder from '@/components/Reminder.vue'
import BusinessPartnerFilter from '@/components/BusinessPartnerFilter.vue'
import userAvatar from '@/components/userAvatar.vue'

import { useUserStore } from '@/stores/user'
const userStore = useUserStore();

import { useIncentivesStore } from '@/stores/incentives'
const incentivesStore = useIncentivesStore();

import { useBusinessPartnersStore } from '@/stores/businessPartners'
const businessPartnersStore = useBusinessPartnersStore();

import { useRatingsStore } from '@/stores/ratings';
const ratingsStore = useRatingsStore();

const businessPartnerList = ref(null)

// infinite scroll excample from https://learnvue.co/articles/vue-infinite-scrolling
// this gets the container of the businessPartners that should react to scrolling (similar to a querySelector) but by using ref
onMounted(async () => {
  window.addEventListener("scroll", handleScroll)

  if(userStore.selectedMedia === 'desktop') {
    userStore.currentSelection.businessPartnerList.layout = 'list'
  }
});
onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll)
})
async function handleScroll(e) {
  
  return false;
  // dont react to scrolling if the list is loading
  if (userStore.currentSelection.businessPartnerList.isLoading) return false
  // if the user scrolls to the bottom of the page, see if the list needs to be expanded
  let element = businessPartnerList.value
  if (element.getBoundingClientRect().bottom < window.innerHeight) {
    await requestNewPage()
  }
}

// toggle between list and grid layout
function handleToggleLayout() {
  if (userStore.currentSelection.businessPartnerList.layout === 'list') {
    userStore.currentSelection.businessPartnerList.layout = 'grid'
  } else {
    userStore.currentSelection.businessPartnerList.layout = 'list'
  }
}

// open the filter
function handleOpenFilter() {
  userStore.currentSelection.businessPartnerList.filter.isOpen = !userStore.currentSelection.businessPartnerList.filter.isOpen
}

// get more data on clicking the "Mehr" button
async function requestNewPage() {
  userStore.currentSelection.businessPartnerList.page++
  // console.log('requestNewPage', userStore.currentSelection.businessPartnerList.page)
  await businessPartnersStore.getByFilterExpand(Object.assign({}, userStore.currentFilter.filter, {page: userStore.currentSelection.businessPartnerList.page, pageSize: userStore.currentSelection.businessPartnerList.pageSize}), userStore.currentFilter.searchCriteria)
  // get the dom elemetn of the new page and scroll to it
  let element = document.getElementById(`EndOfPage:${ userStore.currentSelection.businessPartnerList.page-1 }`)
  // console.log('looking for page', userStore.currentSelection.businessPartnerList.page-1, 'element', element)
  if (element) element.scrollIntoView({ behavior: 'smooth' });
}

function formatDateTime(dateTimeString) {
  const date = new Date(dateTimeString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
  const year = String(date.getFullYear()).slice(-2);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}

function formatIndex(index) {
  return String(index).padStart(2, '0');
}

// function formattedSalesAgentName(name) {
//     return name
//         .toLowerCase() // Konvertiere in Kleinbuchstaben
//         .replace(/ä/g, 'ae') // Ersetze ä durch ae
//         .replace(/ö/g, 'oe') // Ersetze ö durch oe
//         .replace(/ü/g, 'ue') // Ersetze ü durch ue
//         .replace(/ß/g, 'ss') // Ersetze ß durch ss
//         .replace(/\s+/g, '-') // Ersetze Leerzeichen durch -
//         + '.webp'; // Füge die Dateiendung hinzu
//     };

// function salesAgentStatus(idSalesAgent) {
//   const salesAgent = userStore.filterOption?.salesAgents?.find(salesAgent => salesAgent.id === idSalesAgent)
//   const status = incentivesStore.incentive.salesAgents.champions.find(champion => salesAgent.idUser === champion.idUser)?.salesAgentStatusCurrent
//   return status
// }
</script>


<template>
  <div class="reminder-wrapper singlepage-wrapper max-width ">
    <div v-if="userStore.currentSelection.businessPartner.isLoading === true" class="container">
        <div class="loader fullPage" id="loader"></div>
    </div>
    <main id="container-main" class="dashboard">
      <div>
        <div class="widget-wrapper potential-bk">
          <router-link to="/dashboard#potential-bk">
            <div class="headline-wrapper">
              <h2>Potential & BK</h2>
              <p>{{ businessPartnersStore.businessPartnerList.summary }}</p>
            </div>
          </router-link>
          <div class="toggle-header-wrapper">
            <div class="filterview-toggle">
              <img @click="handleOpenFilter()" class="" src="@/assets/imgs/icons/filter-solid.svg" alt="gridview" />
            </div>
            <div class="listview-toggle">
                <img @click="handleToggleLayout()" :class="{'active' : userStore.currentSelection.businessPartnerList.layout === 'list'}" src="@/assets/imgs/icons/list-solid.svg" alt="listview" />
                <img @click="handleToggleLayout()" :class="{'active' : userStore.currentSelection.businessPartnerList.layout === 'grid'}" src="@/assets/imgs/icons/grip-solid.svg" alt="gridview" />
                <img @click="handleToggleLayout()" :class="{'active' : userStore.currentSelection.businessPartnerList.layout === 'grid'}" src="@/assets/imgs/icons/grip-small-solid.svg" alt="gridview" />
            </div>
          </div>
          <div class="container-wrapper scroll-y">
            <div v-if="userStore.currentSelection.businessPartnerList.layout === 'grid'" class="businessPartnerList" ref="businessPartnerList">
              <div :class="['container main-content', {'unseen' : businessPartner.unseen}]" v-for="(businessPartner, index) in businessPartnersStore.businessPartnerList.data" :key="businessPartner.id" :id="'bP:' + businessPartner.id">
                <span class="unseenBorder" v-if="businessPartner.unseen"></span> 
                <div :id="'_' + index">
                  <div class="headline-wrapper">
                    <div class="labels">
                      <span v-if="businessPartner.status?.id >= 2 && businessPartner.status?.id <= 4" :class="['status']" :id="'_' + businessPartner.status?.id">{{ businessPartner.status?.listAs }} am {{ formatDateTime(businessPartner.salesPitch?.dateFollowUp) }}</span>
                      <span v-else-if="businessPartner.status?.id >= 6 && businessPartner.status?.id <= 7" :class="['status']" :id="'_' + businessPartner.status?.id">{{ businessPartner.status?.listAs }} am {{ formatDateTime(businessPartner.salesPitch?.dateFollowUp) }}</span>
                      <span v-else-if="businessPartner.status?.id >= 8 && businessPartner.status?.id <= 9" :class="['status']" :id="'_' + businessPartner.status?.id">Beendet: {{ businessPartner.status?.listAs }}</span>
                      <span v-else-if="businessPartner.status?.id === 22" :class="['status']" :id="'_' + businessPartner.status?.id">{{ businessPartner.status?.listAs }}</span>
                      <span v-else-if="businessPartner.status?.id === null" :class="['status']" :id="'_' + businessPartner.status?.id">Unbearbeitet</span>
                      <span v-else :class="['status']" :id="'_' + businessPartner.status?.id">{{ businessPartner.status?.listAs }}</span>
                      <span :class="['status index']" :id="'_' + businessPartner.status?.id">{{ formatIndex(index + 1) + '/' + businessPartnersStore.businessPartnerList.data.length}}</span>
                    </div>
                    <!-- <span v-if="businessPartner.status?.listAs !== null" :class="['status', businessPartner.status?.id]">{{ businessPartner.status?.listAs }}</span> -->
                    <!-- <span v-if="businessPartner.status?.listAs === null" :class="['status', {'newCustomer' : businessPartner.status?.listAs === null }]">Unbearbeitet</span> -->
                    <a :href="`#businessPartner:${ businessPartner.id}`"></a>
                    <h3 class="text-overflow-clamp">
                      <router-link :to="{ name: 'BusinessPartner', query: { searchMode: 'GPNo', search: businessPartner.gpNo } }">
                        {{ businessPartner.name }}
                      </router-link>
                    </h3>
                    <!-- alternative with id in path to allow bookmarking :to="`/businessPartner/${ businessPartner.id}`" -->
                    <p>{{ businessPartner.gpNo }}</p>
                  </div>
                  <template v-if="userStore.isManagement || userStore.isSalesTeamManager">
                      <div class="userAvatar">
                        <!-- <hr> -->
                        <userAvatar :showSalesAgent=true :isSalesAgent="!userStore.isManagement && !userStore.isSalesTeamManager" :salesAgentId="businessPartner.idSalesAgent" />
                        <!-- <hr> -->
                      </div>
                    </template>
                  <!-- <hr> -->
                  <div class="productsIcon-wrapper">
                    <div class="indicator-wrapper">
                      <img :class="{ 'active': (businessPartner.aboStatus?.includes('GS')) }"
                        src="@/assets/imgs/logos/campaigns/Gelbe-Seiten-App-Icon.svg" alt="Abo in GS" />
                    </div>
                    <div class="indicator-wrapper">
                      <img :class="{ 'active': (businessPartner.aboStatus?.includes('TB')) }"
                        src="@/assets/imgs/logos/campaigns/Das-Telefonbuch-App-Icon.svg" alt="Abo im TB" />
                    </div>
                    <div class="indicator-wrapper">
                      <img :class="{ 'active': (businessPartner.aboStatus?.includes('OE')) }"
                        src="@/assets/imgs/logos/campaigns/Das-Oertliche-App-Icon.svg" alt="Abo im ÖTB" />
                    </div>
                    <div class="indicator-wrapper">
                      <div :class="{ 'active': (businessPartner.aboStatus?.includes('OMP')) }" class="omp">omp</div>
                    </div>
                  </div>
                </div>
                <div class="informtions-wrapper customer">
                  <ul>
                    <li v-if="businessPartner.salesPitch?.contact"><p class="txt-overflow">
                      <template v-if="businessPartner.salesPitch?.contact.gender === 'm'">Herr</template>
                      <template v-else-if="businessPartner.salesPitch?.contact.gender === 'f'">Frau</template>
                      {{ businessPartner.salesPitch?.contact.firstname }} {{ businessPartner.salesPitch?.contact.surname }}</p>
                    </li>
                    <li v-if="businessPartner.addressList && businessPartner.addressList.length > 0">
                      <a target="_blank" :href="'https://www.google.com/maps/place/' + 
                        businessPartner.addressList[0].name.split(',')[0].split(' ')[0] + 
                        '+' +
                        businessPartner.addressList[0].name.split(',')[0].split(' ')[1] + 
                        ',+' +
                        businessPartner.addressList[0].name.split(',')[1].split(' ')[1] + 
                        '+' +
                        businessPartner.addressList[0].name.split(',')[1].split(' ')[2]"
                      >
                        <p class="txt-overflow">
                          {{ businessPartner.addressList[0].name.split(',')[0] }}
                        </p>
                      </a>
                    </li>
                    <li v-if="businessPartner.addressList && businessPartner.addressList.length > 0">
                      <a target="_blank" :href="'https://www.google.com/maps/place/' + 
                        businessPartner.addressList[0].name.split(',')[0].split(' ')[0] + 
                        '+' +
                        businessPartner.addressList[0].name.split(',')[0].split(' ')[1] + 
                        ',+' +
                        businessPartner.addressList[0].name.split(',')[1].split(' ')[1] + 
                        '+' +
                        businessPartner.addressList[0].name.split(',')[1].split(' ')[2]"
                      >
                        <p class="txt-overflow">
                          {{ businessPartner.addressList[0].name.split(',')[1] }}
                        </p>
                      </a>
                    </li>
                    <li v-if="businessPartner.businessCategoryList && businessPartner.businessCategoryList.length > 0 && businessPartner.businessCategoryList[0].name"><p class="txt-overflow">{{ businessPartner.businessCategoryList[0].name }}</p></li>
                  </ul>
                </div>
                <!-- <hr class="" v-if="((index+1) % userStore.currentSelection.businessPartnerList.pageSize) === 0" :id="`EndOfPage:${ ((index + 1) / userStore.currentSelection.businessPartnerList.pageSize) - 1 }`" /> -->
              </div>
            </div>
            <div v-if="userStore.currentSelection.businessPartnerList.layout === 'list'" class="container" ref="businessPartnerListDesktop">
                <table>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <!-- <th>Datum</th> -->
                            <th>BK</th>
                            <th>Name / Firma</th>
                            <!-- <th>Entscheider</th> -->
                            <th>Adresse</th>
                            <!-- <th>Branche</th> -->
                            <th v-if="userStore.isManagement || userStore.isSalesTeamManager">Sales Manager</th>
                            <!-- <th>GP Nr.</th> -->
                        </tr>
                    </thead>
                    <tbody>
                      <template v-for="(businessPartner, index) in businessPartnersStore.businessPartnerList.data" :key="businessPartner.id">
                        <tr class="pageSize" v-if="(index % userStore.currentSelection.businessPartnerList.pageSize) === 0">
                          <td></td>
                          <td></td>
                          <td>
                            <p :id="`StartOfPage:${ (index / userStore.currentSelection.businessPartnerList.pageSize) }`">Seite {{ (index / userStore.currentSelection.businessPartnerList.pageSize) + 1 }}</p>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr :class="{'unseen' : businessPartner.unseen}">
                          <td>
                            <span class="unseenBorder" v-if="businessPartner.unseen"></span>               
                            <div class="labels">
                              <div class="rowTop">
                                <span v-if="businessPartner.status?.id >= 2 && businessPartner.status?.id <= 4" :class="['status']" :id="'_' + businessPartner.status?.id">{{ businessPartner.status?.listAs }}</span>
                                <span v-else-if="businessPartner.status?.id >= 6 && businessPartner.status?.id <= 7" :class="['status']" :id="'_' + businessPartner.status?.id">{{ businessPartner.status?.listAs }}</span>
                                <span v-else-if="businessPartner.status?.id >= 8 && businessPartner.status?.id <= 9" :class="['status']" :id="'_' + businessPartner.status?.id">Beendet: {{ businessPartner.status?.listAs }}</span>
                                <span v-else-if="businessPartner.status?.id === 22" :class="['status']" :id="'_' + businessPartner.status?.id">{{ businessPartner.status?.listAs }}</span>
                                <span v-else-if="businessPartner.status?.id === null" :class="['status']" :id="'_' + businessPartner.status?.id">Unbearbeitet</span>
                                <span v-else :class="['status']" :id="'_' + businessPartner.status?.id">{{ businessPartner.status?.listAs }}</span>
                                <span :class="['status index']" :id="'_' + businessPartner.status?.id">{{ formatIndex(index + 1) + '/' + businessPartnersStore.businessPartnerList.data.length}}</span>
                              </div>
                              <div class="rowBottom">
                                <span :class="['status']" :id="'_' + businessPartner.status?.id" v-if="businessPartner.salesPitch.dateFollowUp === null" >Kein Datum</span>
                                <span :class="['status']" :id="'_' + businessPartner.status?.id" v-else>{{ formatDateTime(businessPartner.salesPitch.dateFollowUp) }}</span>
                              </div>
                            </div>
                          </td>
                          <!-- <td>01.08.2024</td> -->
                          <td><div class="productsIcon-wrapper">
                              <div class="indicator-wrapper">
                                <img :class="{ 'active': (businessPartner.aboStatus?.includes('GS')) }"
                                  src="@/assets/imgs/logos/campaigns/Gelbe-Seiten-App-Icon.svg" alt="Abo in GS" />
                              </div>
                              <div class="indicator-wrapper">
                                <img :class="{ 'active': (businessPartner.aboStatus?.includes('TB')) }"
                                  src="@/assets/imgs/logos/campaigns/Das-Telefonbuch-App-Icon.svg" alt="Abo im TB" />
                              </div>
                              <div class="indicator-wrapper">
                                <img :class="{ 'active': (businessPartner.aboStatus?.includes('OE')) }"
                                  src="@/assets/imgs/logos/campaigns/Das-Oertliche-App-Icon.svg" alt="Abo im ÖTB" />
                              </div>
                              <div class="indicator-wrapper">
                                <div :class="{ 'active': (businessPartner.aboStatus?.includes('OMP')) }" class="omp">omp</div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="infoBusinessPartner">               
                              <div class="unseenDotWrapper">
                                <p class="businessPartner">
                                  <router-link :to="{ name: 'BusinessPartner', query: { searchMode: 'GPNo', search: businessPartner.gpNo } }"> {{ businessPartner.name }}</router-link>
                                </p>
                              </div>
                              <p class="businessPartnerContact" v-if="businessPartner.salesPitch?.contact">
                                  ({{businessPartner.gpNo}})
                                  <template v-if="businessPartner.salesPitch?.contact.gender === 'm'">Herr</template>
                                  <template v-else-if="businessPartner.salesPitch?.contact.gender === 'f'">Frau</template>
                                  {{ businessPartner.salesPitch?.contact.firstname }} {{ businessPartner.salesPitch?.contact.surname }}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="infoAdress">
                              <p v-if="businessPartner.addressList && businessPartner.addressList[0].name.split(',')[0].split(' ')[0]">
                                <a target="_blank" :href="'https://www.google.com/maps/place/' + 
                                  businessPartner.addressList[0].name.split(',')[0].split(' ')[0] + 
                                  '+' +
                                  businessPartner.addressList[0].name.split(',')[0].split(' ')[1] + 
                                  ',+' +
                                  businessPartner.addressList[0].name.split(',')[1].split(' ')[1] + 
                                  '+' +
                                  businessPartner.addressList[0].name.split(',')[1].split(' ')[2]"
                                >
                                  {{ businessPartner.addressList[0].name.split(',')[0] }}, {{ businessPartner.addressList[0].name.split(',')[1] }}
                                </a>
                              </p>
                              <p class="disabled" v-else>Keine Adresse bekannt!</p>
                              <p class="businessCategoryList" v-if="businessPartner.businessCategoryList && businessPartner.businessCategoryList.length > 0 && businessPartner.businessCategoryList[0].name">
                                {{ businessPartner.businessCategoryList[0].name }}
                              </p>
                              <p class="businessCategoryList disabled" v-else>Keine Branche eingetragen!</p>
                            </div>
                          </td>
                          <td v-if="userStore.isManagement || userStore.isSalesTeamManager">
                            <userAvatar :showSalesAgent=true :isSalesAgent="!userStore.isManagement && !userStore.isSalesTeamManager" :salesAgentId="businessPartner.idSalesAgent" />
                          </td>
                        </tr>
                      </template>
                        <!-- Weitere Zeilen können hier hinzugefügt werden -->
                    </tbody>
                </table>              
            </div>
            <BusinessPartnerFilter></BusinessPartnerFilter>
          </div>
          <div v-if="userStore.currentSelection.businessPartnerList.isLoading === true" class="container main-content cell-grid">
              <div class="loader" id="loader"></div>
          </div>

          <button 
            v-if=" userStore.currentSelection.businessPartnerList.maxRows > businessPartnersStore.businessPartnerList.data.length"
            class="load-btn" href="#" 
            :disabled="userStore.currentSelection.businessPartnerList.isLoading" 
            @click.prevent="requestNewPage()">
            Mehr laden
          </button>
          <div class='wave-wrapper'>
            <div class='wave -one'></div>
            <div class='wave -two'></div>
            <div class='wave -three'></div>
          </div>
        </div>
        <!-- <div class="btn filter accent" @click="handleOpenFilter()">Filter setzen</div> -->
        <BusinessPartnerFilter v-if="userStore.selectedMedia === 'desktop'"></BusinessPartnerFilter>
      </div>
    </main>
    <reminder></reminder>
  </div>
</template>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>