<script setup>
    // import { ref, onMounted, computed } from 'vue';
    import { ref, onMounted, onUnmounted, watch, computed } from 'vue';

    import { usePerformanceStore } from '@/stores/performance'
    const performanceStore = usePerformanceStore();

    import { useActivityStore } from '@/stores/activity'
    const activityStore = useActivityStore();

    import { useUserStore } from '@/stores/user'
    const userStore = useUserStore();

    const props = defineProps({
        title: String,
        isEuro: {
            type: Boolean,
            default: false,
        },
        currentValue: {
            type: Number,
            default: 0,
        },
        goalValue: {
            type: Number,
            default: 0,
        },
        points: {
            type: Number,
            default: 0,
        },
        mode: {
            type: String,
            default: 'activityNormal',
        },
        class: {
            type: String,
            default: '',
        }
    });

    const percentageValue = computed(() => {
        if (props.currentValue === 0 && props.goalValue === 0) {
            return 0;
        }
        return Math.floor((props.currentValue / props.goalValue) * 100);
    });

    function formatEuroValues(value, isEuro) {
        if (isEuro === true) {
            return value.toLocaleString('de-DE') + '€';
        }
        return value;
    }

    const salesVolumeIsAchieved = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesEuro.expired >= 100;
    })

    const salesVolumeIsPositive = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesEuro.expired >= performance.progress.expired || performance.salesEuro.expired >= 100
        // return performance.salesEuro.expired >= 80
    })

    const salesVolumeIsNegative = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesEuro.expired < performance.progress.expired && performance.salesEuro.expired < 100
        // return performance.salesEuro.expired < 80
    })

    const newCustomersIsAchieved = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesEuroNewCustomers?.expired >= 100;
    })

    const newCustomersIsBothAchieved = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesEuroNewCustomers.expired >= 100 && performance.salesNumberNewCustomers?.expired >= 100
    })

    const newCustomersIsInactive = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesNumberNewCustomers?.current >= performance.salesNumberNewCustomers?.planned
    })

    const newCustomersIsPositive = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesEuroNewCustomers.expired >= performance.progress.expired || performance.salesEuroNewCustomers.expired >= 100
    })

    const newCustomersIsNegative = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesEuroNewCustomers?.expired < performance.progress.expired && performance.salesEuroNewCustomers.expired < 100
    })

    const newCustomersSalesNumberIsAchieved = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesNumberNewCustomers?.expired >= 100
    })

    const newCustomersSalesNumberIsInactive = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesEuroNewCustomers?.current >= performance.salesEuroNewCustomers?.planned
    })

    const newCustomersSalesNumberHalftimeIsPositive = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesEuro.expired >= performance.progress.expired || performance.salesEuro.expired >= 100
    })

    const newCustomersSalesNumberCampaignIsPositive = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesNumberNewCustomers.expired >= performance.progress.expired || performance.salesNumberNewCustomers.expired >= 100
    })

    const newCustomersSalesNumberHalftimeIsNegative = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesEuro.expired < performance.progress.expired && performance.salesEuro.expired < 100
    })

    const newCustomersSalesNumberCampaignIsNegative = computed(() => {
        const performance = performanceStore.performance[userStore.currentSelection.performance.display];
        return performance.salesNumberNewCustomers.expired < performance.progress.expired && performance.salesNumberNewCustomers.expired < 100
    })

    const levels = computed(() => {
        return activityStore.activity.levelList.map(level => {
            return {
                name: level.name,
                points: level.points,
                goalValue: props.goalValue * level.name / 100,
            };
        });
    });

    function calculateProgressForLevel(levels, actualValue, index) {
        const currentLevel = levels[index];
        const previousGoalValue = index > 0 ? levels[index - 1].goalValue : 0;

        if (actualValue <= previousGoalValue) {
            // Noch unterhalb des aktuellen Levels
            return 0;
        }

        if (actualValue > currentLevel.goalValue) {
            // Progress ist oberhalb des Levels
            return 100;
        }

        // Berechnung innerhalb des Levels
        const progressInLevel = actualValue - previousGoalValue;
        const levelRange = currentLevel.goalValue - previousGoalValue;
        return (progressInLevel / levelRange) * 100;
    }
    
</script>

<template>
    <div>
        <!-- Activity -->
        <div v-if="props.mode === 'activityNormal'" :class="['bar-wrapper',  props.class ]">
            <div class="txt-wrapper">
                <div>
                    <p class="currentPercentage">{{percentageValue}}%</p> <p>&nbsp;| {{title}}</p>
                </div>
                <p class="currentValue txt-centered">{{ formatEuroValues(props.currentValue, props.isEuro) }}</p>
                <p class="goalValue">{{ formatEuroValues(props.goalValue, props.isEuro) }}</p>
            </div>
            <!-- <progress :value="props.currentValue" :max="props.goalValue"></progress> -->
            <div class="progressBar">
                <div class="progress" :style="{ width: Math.min(percentageValue, 100) + '%' }"></div>
            </div>
        </div>
        <div v-if="props.mode === 'activityPoints'" :class="['bar-wrapper',  props.class ]">
            <div class="bar-wrapper pointSystem">
                <p :class="['pointValue overall', {'achieved' : percentageValue >= 80}]">{{props.points}}</p>
                <!-- <span class="pointValue" v-for="level in activityStore.activity.levelList" :style="{ width: level.name + '%' }" :key="level.id">{{level.points}}</span> -->
                <div class="txt-wrapper">
                    <div>
                        <p class="currentPercentage">{{percentageValue}}%</p> <p>&nbsp;| {{title}}</p>
                    </div>
                    <p class="currentValue txt-centered">{{ formatEuroValues(props.currentValue, props.isEuro) }}</p>
                    <p class="goalValue">{{ formatEuroValues(props.goalValue, props.isEuro) }}</p>
                </div>
                <div class="progressBars">
                    <div class="progressBar" v-for="(level, index) in levels" :key="level.id">
                        <div class="progress" :style="{ width: calculateProgressForLevel(levels, props.currentValue, index) + '%' }"></div>
                        <span :class="['pointValue', {'achieved' : calculateProgressForLevel(levels, props.currentValue, index) >= 100}]">{{level.points}}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Performance -->
        <template v-if="props.mode === 'performanceNormal'">
            <div v-if="props.class === 'selling-time'" :class="['bar-wrapper',  props.class ]">
                <div class="txt-wrapper">
                    <div>
                        <p class="currentPercentage">{{percentageValue}}%</p> <p>&nbsp;| {{title}}</p>
                    </div>
                    <!-- <p class="currentValue txt-centered">{{ formatEuroValues(props.currentValue, props.isEuro) }}</p> -->
                    <p class="goalValue">{{ formatEuroValues(props.goalValue, props.isEuro) }}</p>
                </div>
                <!-- <progress :value="props.currentValue" :max="props.goalValue"></progress> -->
                <div class="progressBar">
                    <div class="progress" :style="{ width: Math.min(percentageValue, 100) + '%' }"></div>
                </div>
            </div>
            <div v-if="props.class === 'sales-volume'" 
                :class="[
                    'bar-wrapper',  props.class, 
                    {   'dummy' : userStore.currentSelection.performance.display==='halftime'
                    },
                    {   'achieved' : salesVolumeIsAchieved, 
                        'positive': salesVolumeIsPositive, 
                        'negative': salesVolumeIsNegative
                    } ]">
                <div class="txt-wrapper">
                    <template v-if="userStore.currentSelection.performance.display==='halftime'">
                        <div>
                            <p class="currentPercentage">--%</p> <p>&nbsp;| {{title}}</p>
                        </div>
                        <p class="currentValue txt-centered">--</p>
                        <p class="goalValue">--</p>
                    </template>
                    <template v-else>
                        <div>
                            <p class="currentPercentage">{{percentageValue}}%</p> <p>&nbsp;| {{title}}</p>
                        </div>
                        <p class="currentValue txt-centered">{{ formatEuroValues(props.currentValue, props.isEuro) }}</p>
                        <p class="goalValue">{{ formatEuroValues(props.goalValue, props.isEuro) }}</p>
                    </template>
                </div>
                <!-- <progress :value="props.currentValue" :max="props.goalValue"></progress> -->
                <div class="progressBar">
                    <div class="progress" :style="{ width: Math.min(percentageValue, 100) + '%' }"></div>
                </div>
            </div>
            <div v-if="props.class === 'new-customer-sales'" 
                :class="[
                    'bar-wrapper',  props.class, 
                    {   'achieved' : newCustomersIsAchieved, 
                        'both-achieved' : newCustomersIsBothAchieved, 
                        'inactive': newCustomersIsInactive && !newCustomersIsBothAchieved, 
                        'positive': newCustomersIsPositive, 
                        'negative': newCustomersIsNegative 
                    } ]">
                <div class="txt-wrapper">
                    <div>
                        <p class="currentPercentage">{{percentageValue}}%</p> <p>&nbsp;| {{title}}</p>
                    </div>
                    <p class="currentValue txt-centered">{{ formatEuroValues(props.currentValue, props.isEuro) }}</p>
                    <p class="goalValue">{{ formatEuroValues(props.goalValue, props.isEuro) }}</p>
                </div>
                <!-- <progress :value="props.currentValue" :max="props.goalValue"></progress> -->
                <div class="progressBar">
                    <div class="progress" :style="{ width: Math.min(percentageValue, 100) + '%' }"></div>
                </div>
            </div>
            <div v-if="props.class === 'new-customer-sales-integer'" 
                :class="[
                    'bar-wrapper',  props.class, 
                    {   'dummy' : userStore.currentSelection.performance.display==='halftime'
                    },
                    {   'achieved' : newCustomersSalesNumberIsAchieved, 
                        'inactive': newCustomersSalesNumberIsInactive && !newCustomersIsBothAchieved, 
                        'positive': newCustomersSalesNumberCampaignIsPositive, 
                        'negative': newCustomersSalesNumberCampaignIsNegative
                    } ]">
                <div class="txt-wrapper">
                    <template v-if="userStore.currentSelection.performance.display==='halftime'">
                        <div>
                            <p class="currentPercentage">--%</p> <p>&nbsp;| {{title}}</p>
                        </div>
                        <p class="currentValue txt-centered">--</p>
                        <p class="goalValue">--</p>
                    </template>
                    <template v-else>
                        <div>
                            <p class="currentPercentage">{{percentageValue}}%</p> <p>&nbsp;| {{title}}</p>
                        </div>
                        <p class="currentValue txt-centered">{{ formatEuroValues(props.currentValue, props.isEuro) }}</p>
                        <p class="goalValue">{{ formatEuroValues(props.goalValue, props.isEuro) }}</p>
                    </template>
                </div>
                <!-- <progress :value="props.currentValue" :max="props.goalValue"></progress> -->
                <div class="progressBar">
                    <div class="progress" :style="{ width: Math.min(percentageValue, 100) + '%' }"></div>
                </div>
            </div>
        </template>

        <div v-if="props.mode === 'PointSystem'" class="progressBar">
            <div class="progress" :style="{ width: Math.min(percentageValue, 100) + '%' }"></div>
        </div>
    </div>
</template>