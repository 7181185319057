<script setup>
import { onMounted } from 'vue'

import Bar from '@/components/Bar.vue';

import { useUserStore } from '@/stores/user'
const userStore = useUserStore();

import { useActivityStore } from '@/stores/activity'
const activityStore = useActivityStore();

async function handleDisplayStatus(display) {
    await userStore.selectDisplayStatus('activity', display);
}

onMounted( () => {
    // ensure that the widgets data are always set to the current week and month
    for (const segment of ['week', 'month']) {
        if (activityStore.activity[segment]) userStore.currentSelection.activity[segment] = activityStore.activity[segment].find((entry) => entry.isCurrent).id;
    }
});

</script>

<template>
<div id="activity" :class="['widget-wrapper activity', {'noValue' : !activityStore.week}]">
    <div>
        <router-link to="/activity">
            <div class="headline-wrapper">
                <h2>Aktivitäten</h2>
                <!-- <small>{{ activityStore.week?.name }} / {{ activityStore.month?.name }}</small> -->
            </div>
        </router-link>
    </div>
    <div class="container-wrapper">
        <template v-if="userStore.currentSelection.activity.isLoading === true">
            <div class="container main-content cell-grid">
                <div class="loader" id="loader"></div>
            </div>
            <div class="container main-content">
                <div class="headline-wrapper">
                    <span class="headline">Ergebnisse</span>
                    <span class="headline">Ist</span>
                    <span class="headline plannedValues">Ziel</span>
                </div>
                <div class="bars-wrapper activity">
                    <bar title="Entscheider" :mode="'activityNormal'" :currentValue="activityStore[userStore.currentSelection.activity.display].actualValues?.contacts" :goalValue="activityStore[userStore.currentSelection.activity.display].plannedValues?.contacts"></bar>
                    <bar title="Termine (Akt.)" :mode="'activityNormal'" :currentValue="activityStore[userStore.currentSelection.activity.display].actualValues?.appointmentsActivity" :goalValue="activityStore[userStore.currentSelection.activity.display].plannedValues?.appointments"></bar>
                    <bar title="Kunden" :mode="'activityNormal'" :currentValue="activityStore[userStore.currentSelection.activity.display].actualValues?.salesNumber" :goalValue="activityStore[userStore.currentSelection.activity.display].plannedValues?.salesNumber"></bar>
                    <bar v-if="userStore.currentSelection.activity.display === 'month' && userStore.currentSelection.campaign.id <= 22" title="Gesamt" :mode="'activityPoints'" :points="activityStore.month.level.points" :isEuro=true :currentValue="activityStore[userStore.currentSelection.activity.display].actualValues?.salesEuro" :goalValue="activityStore[userStore.currentSelection.activity.display].plannedValues?.salesEuro"></bar>
                    <bar v-else title="Gesamt" :mode="'activityNormal'" :isEuro=true :currentValue="activityStore[userStore.currentSelection.activity.display].actualValues?.salesEuro" :goalValue="activityStore[userStore.currentSelection.activity.display].plannedValues?.salesEuro"></bar>
                    <bar title="Neu-Kd." :mode="'activityNormal'" :isEuro=true :currentValue="activityStore[userStore.currentSelection.activity.display].actualValues?.salesEuroNewCustomers" :goalValue="activityStore[userStore.currentSelection.activity.display].plannedValues?.salesEuroNewCustomers"></bar>
                    <!-- <bar title="Storno" :currentValue="0" :goalValue="5323"></bar> -->
                </div>
            </div>
        </template>
        <div v-else-if="!activityStore.week && userStore.currentSelection.activity.isLoading === false" class="container">
            <div class="warning">Keine Daten verfügbar!</div>
        </div>
        <div v-else class="container main-content">
            <div class="headline-wrapper">
                <span class="headline">Ergebnisse</span>
                <span class="headline">Ist</span>
                <span class="headline plannedValues">Ziel</span>
            </div>
            <div class="bars-wrapper activity">
                <bar title="Entscheider" :mode="'activityNormal'" :currentValue="activityStore[userStore.currentSelection.activity.display].actualValues?.contacts" :goalValue="activityStore[userStore.currentSelection.activity.display].plannedValues?.contacts"></bar>
                <bar title="Termine (Akt.)" :mode="'activityNormal'" :currentValue="activityStore[userStore.currentSelection.activity.display].actualValues?.appointmentsActivity" :goalValue="activityStore[userStore.currentSelection.activity.display].plannedValues?.appointments"></bar>
                <bar title="Kunden" :mode="'activityNormal'" :currentValue="activityStore[userStore.currentSelection.activity.display].actualValues?.salesNumber" :goalValue="activityStore[userStore.currentSelection.activity.display].plannedValues?.salesNumber"></bar>
                <bar v-if="userStore.currentSelection.activity.display === 'month' && userStore.currentSelection.campaign.id <= 22" title="Gesamt" :mode="'activityPoints'" :isEuro=true :currentValue="activityStore[userStore.currentSelection.activity.display].actualValues?.salesEuro" :goalValue="activityStore[userStore.currentSelection.activity.display].plannedValues?.salesEuro"></bar>
                <bar v-else title="Gesamt" :mode="'activityNormal'" :isEuro=true :currentValue="activityStore[userStore.currentSelection.activity.display].actualValues?.salesEuro" :goalValue="activityStore[userStore.currentSelection.activity.display].plannedValues?.salesEuro"></bar>
                <bar title="Neu-Kd." :mode="'activityNormal'" :isEuro=true :currentValue="activityStore[userStore.currentSelection.activity.display].actualValues?.salesEuroNewCustomers" :goalValue="activityStore[userStore.currentSelection.activity.display].plannedValues?.salesEuroNewCustomers"></bar>
                <!-- <bar title="Storno" :currentValue="0" :goalValue="5323"></bar> -->
            </div>
        </div>

    </div>
    <div class='wave-wrapper'>
        <div class='wave -one'></div>
        <div class='wave -two'></div>
        <div class='wave -three'></div>
    </div>
    <div class="toggle-wrapper">
        <div class="toggle-option" @click="handleDisplayStatus('week')">
            <span class="toggle-label">Woche</span>
            <label class="switch">
                <input type="radio" name="activity-toggle" value="week" :checked="userStore.currentSelection.activity.display==='week'">
                <span class="custom-radio"></span>
            </label>
        </div>
        <div class="toggle-option" @click="handleDisplayStatus('month')">
            <span class="toggle-label">Monat</span>
            <label class="switch">
                <input type="radio" name="activity-toggle" value="month" :checked="userStore.currentSelection.activity.display==='month'">
                <span class="custom-radio"></span>
            </label>
        </div>
    </div>
</div>
</template>

<!-- <style src="../scss/components/incentivePreview.scss" lang="scss" scoped></style> -->