import { defineStore } from "pinia";
import { ref } from 'vue'

import { useUserStore } from "./user";

import { fetchFromBackend } from "./helpers/backend.mjs";

export const useBusinessPartnersListsStore = defineStore("businessPartnersLists", {
  state: () => {
    return {
      lists: [], // a list of Lists defined by a specific filter (search)
    }
  },
  getters: {
    businessPartnersLists: (state) => {
      const userStore = useUserStore();
      const businessPartnersListsExists = state.lists.find((list) => list.signature === userStore.currentSelection.businessPartnersLists.signature);
      console.log('businessPartnersListsExists', businessPartnersListsExists)
      return (businessPartnersListsExists) ? businessPartnersListsExists : { summary: '', data: [], size: 0 };
    },
    namedFilter: (state) => (name) => {
      const userStore = useUserStore();
      // console.log('requesting namedFilter', name)
      let filter = {
        idCompany: userStore.currentSelection.campaign.company.id,
        idCampaign: userStore.currentSelection.campaign.id,
        idSalesAgent: (userStore.currentSelection.scope === 'salesAgent') ? userStore.currentSelection.salesAgent.id : -1,
        idSalesTeam: (userStore.currentSelection.scope === 'salesTeam') ? userStore.currentSelection.salesTeam.id : -1,
      };
      switch (name) {
        case 'LeadList':
          const leadLists = userStore.filterOption.businessPartnerLists.filter((list) => ['Leads', 'Sugar Aktionen'].includes(list.name));
          if (leadLists) {
            filter = Object.assign({}, filter, {
              page: 1,
              pages: 1,
              pageSize: 2500,
              id: -9, // new: not yet viewed
              idBusinessPartnerList: leadLists.map((leadList) => leadList.id).join(','),
              idBusinessPartnerStatus: 1,
              idBusinessPartnerOrder: -1,
              signature: `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#LeadList`,
              summary: 'Aktuelle Leads'
            });
          }
          break;
        default:
          break;
      }
      // console.log('namedFilter', name, '=>', filter)
      return filter;
    }
  },
  actions: {
    /** start a search with a filter, i.e. { idBusinessPartnerList: "list:12" } for businessPartners either from Dashboard or from businessPartnerList
     * @async
     * @param {Object} filter - The filter object to search for businessPartners
     * @param {Array} searchCriteria - Additional search criteria to filter the businessPartners
    */
    async getByFilter(filter, searchCriteria = []) {
      const endpoint = "businessPartners/v1/getListsByFilter";
      const userStore = useUserStore();

      // see if there is existing data if it exists and its update property is less then 6 hours old and it matches the desired page
      const storedBusinessPartnersLists = null
      // const storedBusinessPartnersLists = this.lists.find((businessPartnersLists) => businessPartnersLists.signature === filter.signature);
      console.log('getlistsByFilter: checked signature', filter.signature, 'got stored:', storedBusinessPartnersLists, 'filter is', filter)
      // TODO: manage Lists in memory by purging businessPartners from active lists
      // if (storedBusinessPartnerList && storedBusinessPartnerList?.update > (Date.now() - (6 * 60 * 60 * 1000))) return storedBusinessPartnerList;

      const payload = {
        id: userStore.id,
        filter: filter,
        view: {
          page: 1,
          pageSize: filter.pageSize * filter.pages,
          searchCriteria: searchCriteria,
          sort: {
            field: filter.idBusinessPartnerOrder,
          }
        },
      }
      if (filter.order) payload.view.sort.order = filter.order;

      const response = await fetchFromBackend(endpoint, payload);
      if (!response.businessPartnerLists) return false;
      userStore.currentSelection.businessPartnersLists.signature = filter.signature;
      if (!storedBusinessPartnersLists) {
        this.lists = []; // clear the lists to prevent dublicates
        this.lists.push({ 
          signature: filter.signature, 
          filter: filter, searchCriteria: searchCriteria, 
          sort: { id: filter.idBusinessPartnerOrder }, 
          summary: response.filter.summary, 
          data: response.businessPartnerLists,
          size: response.view.countBusinessPartner, 
          update: Date.now() 
        });
      } else {
        storedBusinessPartnersLists.update = Date.now();
        storedBusinessPartnersLists.data = response.businessPartnerList;
      }
    },
    /** at campaign change or in certain intervals, the businessPartnerList data needs to be refreshed to reflect up to date 'Wiedervorlagen', 'vor Ort Termine' and 'Sugar Aktionen' 
     * @async
    */
    async refresh() {
      const userStore = useUserStore();
      // if (userStore.currentSelection.scope !== 'salesAgent') return false;
      const taskList = [
        this.getByFilter(this.namedFilter('LeadList')),
      ];
      console.log(this.namedFilter('LeadList'))
      userStore.currentSelection.businessPartnersLists.isLoading = true;
      await Promise.all(taskList);
      userStore.currentSelection.businessPartnersLists.isLoading = false;
    },
  },
});