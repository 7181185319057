<script setup>
    import router from '../../router/routes';
    import { ref, reactive, computed, onMounted, onUnmounted } from 'vue'
  
    import { useRoute } from 'vue-router'
    const route = useRoute();

    import Reminder from '@/components/Reminder.vue'
    import userAvatar from '@/components/userAvatar.vue'

    import {emailIsValid} from '@/stores/helpers/emailIsValid'

    import { useBusinessPartnersStore } from '@/stores/businessPartners'
    const businessPartnersStore = useBusinessPartnersStore();

    import { useUserStore } from '@/stores/user'
    // import { RadioElement } from '@vueform/vueform/dist/vueform';
    const userStore = useUserStore();

    import { useFunctionsStore } from '@/stores/functions'
    const functionsStore = useFunctionsStore()

    onMounted(() => {
        userStore.currentSelection.businessPartner.hasValid.salesPitch = false;
        userStore.currentSelection.businessPartner.editMode = false;
        saveSalesPitchIsLoading.value = false;
    });

    const saveSalesPitchIsLoading = ref(false);

    const productsForAppointment = ref([]);
    const currentDate = new Date();
    let currentStatusSection = '';
    let currentSelectedOption = '';
    const currentComment = ref('');
    const contactNeedsUpdate = ref(false);
    const incompleteSalesPitchMessage = ref('');
    const contact = businessPartnersStore.businessPartner.contact;
    const newProductForAppointment = ref(-1);

    const status = reactive({
        currentStep: 1, // Zustand für den aktuellen Schritt
        allowPrevious: false, // Erlaubt den vorherigen Schritt
        allowNext: false, // Erlaubt den nächsten Schritt
        history: [], // Historie der Schritte, z.B. um abzuspeichern welche selektion in schritt 1 gemacht wurde !
        'Entscheider Kontakt hergestellt': {
            display: true,
            options: {
                'Ja, telefonisch': { id: 3, display: true, checked: false, isParent: false },
                'Ja, vor Ort': { id: 4, display: true, checked: false, isParent: false },
                'Nein': { id: 2, display: true, checked: false, isParent: false },
                'Ungültig: Dublette': { id: 9, display: true, checked: false },
                'Ungültig: kein Anschluss': { id: 8, display: true, checked: false },
            }
        },
        'Beendet':  {
            display: false,
            options: {
                'Geschäftsaufgabe': { id: 26, display: true, checked: false },
                'Entscheider nie erreicht': { id: 27, display: true, checked: false },
                'Entscheider desinteressiert': { id: 22, display: true, checked: false },
            }
        },
        'Verbindlicher Termin mit Entscheider': {
            display: false,
            options: {
                'Rückruf': { display: true, checked: false },
                'Termin vor Ort': { display: true, checked: false },
            }
        },
        'Unverbindliche Wiedervorlage': {
            display: false,
            options: {
                'Datum eintragen': { display: true, checked: false },
            }

        },
        datePicker: {
            "unverbindlich" : {
                title: '',
                addendum: '',
                dateMin: currentDate.toISOString().split('T')[0],
                dateMax: new Date(currentDate.setFullYear(currentDate.getFullYear() + 1)).toISOString().split('T')[0],
                inputTime: 'optional',
                selected: {
                    date: null,
                    time: null
                }
            },
            "verbindlich" : {
                title: '',
                addendum: '',
                dateMin: currentDate.toISOString().split('T')[0],
                dateMax: new Date(currentDate.setFullYear(currentDate.getFullYear() + 1)).toISOString().split('T')[0],
                inputTime: 'optional',
                selected: {
                    date: null,
                    time: null
                }
            }
        }
    });

    const warning = computed(() => {
        return Object.values(status['Verbindlicher Termin mit Entscheider'].options).some(option => option.checked) && offersForSalesPitch.value.length === 0
    })

    const offersWithFeedback = computed(() => {
        return businessPartnersStore.businessPartner.offerList.filter((offer) => [18,19,20,21].includes(offer.status.id));
    });

    const offersForSalesPitch = computed(() => {
        return businessPartnersStore.businessPartner.offerList.filter((offer) => ![18,19,20,21].includes(offer.status.id));
    });

    // handling the contact status
    function handleChangeStatus(statusSection, selectedOption) {
        console.log('@handleChangeStatus', statusSection, selectedOption);
        userStore.currentSelection.businessPartner.hasValid.salesPitch = false;
        Object.values(status['Entscheider Kontakt hergestellt'].options).forEach((option) => option.isParent= false )

        if (!['Auswahl Datum', 'Auswahl Zeit'].includes(statusSection)) {
            currentStatusSection = statusSection; 
            currentSelectedOption = selectedOption;
            // reset all checked values
            Object.values(status[statusSection].options).forEach((o) => o.checked= false );
            status[statusSection].options[selectedOption].checked = true;
        }
        
        if (statusSection == 'Beendet') {
            status['Beendet'].options[selectedOption].checked = true;

            status['Verbindlicher Termin mit Entscheider'].options['Rückruf'].checked = false
            status['Verbindlicher Termin mit Entscheider'].options['Termin vor Ort'].checked = false
        }

        if (statusSection == 'Entscheider Kontakt hergestellt') {
            status['Beendet'].display = true
            Object.values(status['Beendet'].options).forEach((option) => option.display= false );
            Object.values(status['Beendet'].options).forEach((option) => option.checked= false );
            status['Entscheider Kontakt hergestellt'].options[selectedOption].isParent = true;

            if (selectedOption === 'Nein') {
                status.allowNext = true;
                status['Verbindlicher Termin mit Entscheider'].display = false
                status['Unverbindliche Wiedervorlage'].display = true
                for (const key of ['Geschäftsaufgabe', 'Entscheider nie erreicht']) {
                    status['Beendet'].options[key].display = true;
                }
            }
            if (selectedOption === 'Ja, telefonisch' || selectedOption === 'Ja, vor Ort') {
                status.allowNext = true;
                for (const key of ['Geschäftsaufgabe', 'Entscheider desinteressiert']) {
                    status['Beendet'].options[key].display = true;
                }
                if (!Object.values(status['Beendet'].options).find((option) => option.checked)) status['Verbindlicher Termin mit Entscheider'].display = true
                if (!Object.values(status['Beendet'].options).find((option) => option.checked)) status['Unverbindliche Wiedervorlage'].display = true
            }
            if (selectedOption === 'Ungültig: Dublette' || selectedOption === 'Ungültig: kein Anschluss') {
                status.allowNext = false;
                status['Verbindlicher Termin mit Entscheider'].display = false
                status['Unverbindliche Wiedervorlage'].display = false
            }
        }

        if (statusSection == 'Verbindlicher Termin mit Entscheider') {
            if (selectedOption === 'Rückruf') {
                status['Verbindlicher Termin mit Entscheider'].display = true
                status['Unverbindliche Wiedervorlage'].display = true
                Object.assign(status.datePicker['verbindlich'], {  title: 'Rückruf', inputTime: 'required', addendum: '=> Google-Kalender (bitte prüfen)' })
                Object.assign(status.datePicker['unverbindlich'], {  selected: {date: null, time: null} })
            }
            if (selectedOption === 'Termin vor Ort') {
                status['Verbindlicher Termin mit Entscheider'].display = true
                status['Unverbindliche Wiedervorlage'].display = true
                Object.assign(status.datePicker['verbindlich'], {  title: 'Termin vor Ort', inputTime: 'required', addendum: `=> Google-Kalender (bitte prüfen), Terminbestätigung an ${businessPartnersStore.businessPartner.contact.email}` })
                Object.assign(status.datePicker['unverbindlich'], {  selected: {date: null, time: null} })
            }
        }

        if (statusSection == 'Unverbindliche Wiedervorlage') {
            if (selectedOption === 'Datum eintragen') {
                status['Unverbindliche Wiedervorlage'].display = true

                status['Verbindlicher Termin mit Entscheider'].options['Rückruf'].checked = false
                status['Verbindlicher Termin mit Entscheider'].options['Termin vor Ort'].checked = false

                Object.assign(status.datePicker['unverbindlich'], {  title: 'Wiedervorlage für', inputTime: 'optional', addendum: '(Optional)' })
                Object.assign(status.datePicker['verbindlich'], {  selected: {date: null, time: null} })
            }
        }
        checkSalesPitchIsValid();
    }


    // Funktionen zum Navigieren zwischen den Schritten
    function handleGoToNextStep() {
        status.currentStep++
        status.allowNext = false;
        status.allowPrevious = (status.currentStep!==1); 
        const selectedOption = status[currentStatusSection].options[currentSelectedOption];
        console.log('selectedOption', selectedOption)
        status.history.push(selectedOption);
        console.log('moving to next step',status.currentStep, 'history is', status.history)

    }

    function handleGoToPreviousStep() {
        status.currentStep--
        status.allowNext = false;
        status.allowPrevious = (status.currentStep!==1); 
        // remove the last entry from the status history
        status.history.pop();
        status.datePicker['unverbindlich'].selected= { date: null, time: null }
        status.datePicker['verbindlich'].selected= { date: null, time: null }      
        currentStatusSection = '';
        currentSelectedOption = '';
        console.log('moving to previous step',status.currentStep, 'history is', status.history)
        this.checkSalesPitchIsValid();     
    }

    function checkSalesPitchIsValid() {
        let salesPitchIsValid = false
        console.log('checking',currentStatusSection, currentSelectedOption)
        contactNeedsUpdate.value = false
        if (currentStatusSection == 'Beendet') {
            salesPitchIsValid = true
        } else if (currentStatusSection == 'Entscheider Kontakt hergestellt' && currentSelectedOption.startsWith('Ungültig:') ) {
            salesPitchIsValid = true
        } else if (currentStatusSection == 'Entscheider Kontakt hergestellt' || currentStatusSection == 'Unverbindliche Wiedervorlage') {
            if (status.datePicker['unverbindlich'].selected.date) salesPitchIsValid = true;
            incompleteSalesPitchMessage.value = (salesPitchIsValid) ? '' : 'für eine Wiedervorlage bitte Datum eintragen';
        } else if (currentStatusSection == 'Verbindlicher Termin mit Entscheider') {
            if ( ['Rückruf','Termin vor Ort'].includes(currentSelectedOption) && businessPartnersStore.businessPartner.offerList.length>0 && status.datePicker['verbindlich'].selected.date && status.datePicker['verbindlich'].selected.time ) salesPitchIsValid = true; 
            // check if there is an emal for the contact
            console.log('explicid:', emailIsValid(businessPartnersStore.businessPartner.contact.email), currentSelectedOption)

            if (!emailIsValid(businessPartnersStore.businessPartner.contact.email) && currentSelectedOption==='Termin vor Ort') {
                contactNeedsUpdate.value = true;
                salesPitchIsValid = false;
            }
            incompleteSalesPitchMessage.value = (salesPitchIsValid) ? '' : (currentSelectedOption==='Termin vor Ort') ? 'für einen verbindlichen vor Ort Termin bitte Entscheider Email, Datum und Zeit eintragen' : 'für einen verbindlichen Rückruf bitte Datum und Zeit eintragen'; 
        }
        userStore.currentSelection.businessPartner.hasValid.salesPitch = salesPitchIsValid
    }

    async function handleSaveSalesPitch() {
        // console.log(status.datePicker)
        saveSalesPitchIsLoading.value = true;
        
        const additionalSalesPitch = {
            id: null,
            comment: currentComment.value,
            offerList: businessPartnersStore.businessPartner.offerList,
        }
        // set contact information as they may have changed
        const contact = businessPartnersStore.businessPartner.contact
        const step1 = status.history[0];   

        additionalSalesPitch.contact = contact;
        // Beendet Entscheider desinteressiert null null <empty string>
        if (currentStatusSection==='Beendet') {
            Object.assign(additionalSalesPitch,{
                status: { id: status['Beendet'].options[currentSelectedOption].id, idParent: null },
                dateFollowUp: null,
            });
            if (currentSelectedOption === 'Entscheider desinteressiert') {
                // look for the parent in the status "Entscheider Kontakt hergestellt" by using the first entry in the history
                if (step1) additionalSalesPitch.idParent = step1.id;
                // on this specific status, all offered products get the status "abgelehnt"
                additionalSalesPitch.offerList.forEach((offer) => { 
                    offer.status.id = 21 
                    offer.refusalReason = 'Entscheider desinteressiert'
                });
            }
        } else if (currentStatusSection==='Entscheider Kontakt hergestellt') {
            if (currentSelectedOption === 'Ungültig: Dublette') {
                Object.assign(additionalSalesPitch,{
                    status: { id: 9, idParent: null },
                    dateFollowUp: null,
                });
            } else if (currentSelectedOption === 'Ungültig: kein Anschluss') {
                Object.assign(additionalSalesPitch,{
                    status: { id: 8, idParent: null },
                    dateFollowUp: null,
                });
             } 
        } else if (currentStatusSection==='Unverbindliche Wiedervorlage') {
            // look inside the history for the parnet status
            Object.assign(additionalSalesPitch,{
                status: { id: step1.id, idParent: null },
                dateFollowUp: `${status.datePicker['unverbindlich'].selected.date}T${(status.datePicker['unverbindlich'].selected.time) ? status.datePicker['unverbindlich'].selected.time : '00:00'}:00.000Z`,
            });
        } else if (currentStatusSection==='Verbindlicher Termin mit Entscheider') {
            // look for the parent in the status "Entscheider Kontakt hergestellt", use the first entry in the history
            Object.assign(additionalSalesPitch,{
                status: { id: (currentSelectedOption === 'Rückruf') ? 6: 7, idParent: step1.id },
                dateFollowUp: `${status.datePicker['verbindlich'].selected.date}T${(status.datePicker['verbindlich'].selected.time) ? status.datePicker['verbindlich'].selected.time : '00:00'}:00.000Z`,
            });
            // if there is a sales appointment, all offered products get the status "terminiert"
            additionalSalesPitch.offerList.forEach((offer) => { offer.status.id = 23 });
        }
        console.log('saving salesPitch', additionalSalesPitch)
        userStore.currentSelection.businessPartner.additionalSalesPitches.push(additionalSalesPitch);

        userStore.currentSelection.businessPartner.isLoading = true;
        const saveSalesPitchResult = await businessPartnersStore.saveSalesPitch(additionalSalesPitch);

        console.log('salesPitch to save', additionalSalesPitch);
        await userStore.refreshAppointments()
        if (saveSalesPitchResult) {
            userStore.currentSelection.businessPartner.isLoading = false;
            navigateBack();
        }
    }

    function handleAddProduct() {
        if (newProductForAppointment.value === -1) return false;
        const selectedProduct = userStore.filterOption.businessPartnerPotential.find((product) => product.id === newProductForAppointment.value);
        // console.log('handleAddProduct', selectedProduct);
        const productExists = businessPartnersStore.businessPartner.offerList.find((offer) => offer.product.id === selectedProduct.id);
        if (productExists) return false;
        businessPartnersStore.businessPartner.offerList.push({ id: null, idPublication:-1, publication: null, product: selectedProduct, status: { id: 16 }, selfCreated: true });
        newProductForAppointment.value = -1;
    }

    function handleRemoveProduct(id) {
        // console.log('handleRemoveProduct', id);
        const productExists = businessPartnersStore.businessPartner.offerList.find((offer) => offer.product.id === id);
        if (!productExists) return false;
        businessPartnersStore.businessPartner.offerList = businessPartnersStore.businessPartner.offerList.filter((offer) => offer.product.id !== id);
        checkSalesPitchIsValid();
    }

    function handleAbortSalesPitch() {
        // console.log('handleAbortSalesPitch');
        navigateBack();
    }

    function navigateBack() {
        router.go(-1);
    }

    function handleEditContactInfo() {
        // opens the edit mode for the contact information
        userStore.currentSelection.businessPartner.editMode = !userStore.currentSelection.businessPartner.editMode;
    }
    function handleCloseEditContactInfo() {
        // currently the edit is only saved by using the new contact information for the addSalesPitch
        // for phone: create entries for the phoneList by checking  phoneStationary and phoneMobile
        const contactData = Object.assign({}, contact, { phoneList: [], emailList: [] });
        
        if (contact.phoneStationary) contactData.phoneList.push({ name: contact.phoneStationary, type: 'stationary' });
        if (contact.phoneMobile) contactData.phoneList.push({ name: contact.phoneMobile, type: 'mobile' });
        if (contact.email) contactData.emailList.push({ name: contact.email });
       
        Object.assign(businessPartnersStore.businessPartner, { contact: contactData }, { email: contact.email });
        userStore.currentSelection.businessPartner.editMode = !userStore.currentSelection.businessPartner.editMode;
        console.log('updated contact, current email is', businessPartnersStore.businessPartner.contact.email)
        checkSalesPitchIsValid();
    }

    function handleUndoEditContactInfo() {
        // aborts the edit mode for the contact information
        Object.assign(contact,businessPartnersStore.businessPartner.contact);
        userStore.currentSelection.businessPartner.editMode = !userStore.currentSelection.businessPartner.editMode;
    }

    function formatDateTime(dateTimeString) {
        const date = new Date(dateTimeString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    function copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Text copied to clipboard')
        }).catch(err => {
            console.error('Could not copy text: ', err)
        })
    }

    function handleLinkClick(event, text) {
        copyToClipboard(text)
        // Lassen Sie die Standardaktion des Links zu, damit der Benutzer auf die Seite gelangt
    }
</script>

<template>
        <div class="reminder-wrapper singlepage-wrapper max-width">
            <main id="container-main" class="dashboard">
                <div>   
                    <div class="widget-wrapper potential-bk customer-details" :style="{ maxHeight: `calc(100dvh - ${functionsStore.navigationHeight + functionsStore.footerHeight + functionsStore.gapHeight}px)` }">
                        <a href="#" @click="navigateBack()">
                            <div class="headline-wrapper">
                                <h2>GP Details</h2>
                            </div>
                            <template v-if="userStore.isManagement || userStore.isSalesTeamManager">
                                <div class="userAvatar">
                                    <!-- <hr> -->
                                    <userAvatar :showSalesAgent=true :isSalesAgent="!userStore.isManagement && !userStore.isSalesTeamManager" :salesAgentId="businessPartnersStore.businessPartner.idSalesAgent" />
                                    <!-- <hr> -->
                                </div>
                            </template>
                        </a>
                        <div class="container-wrapper">
                            <div class="flexWrapper grid">
                                <!-- <div :class="['container creditWorthiness', {'worthiness' : businessPartnersStore.businessPartner.creditWorthiness.symbol === 'OK'}]">
                                    <h3 :style="{color: businessPartnersStore.businessPartner.creditWorthiness.color}">{{ businessPartnersStore.businessPartner.creditWorthiness.description }}</h3>
                                </div> -->
                                <div class="column">
                                    <div :class="['accordion-menu customer-note', {'deactivated' : businessPartnersStore.businessPartner.info}]" v-if="businessPartnersStore.businessPartner.info">
                                            <ul class="main-list">
                                                <li class="main-list-item">
                                                    <input type="checkbox">
                                                    <i class="arrow"></i>
                                                    <h3>Hinweise</h3>
                                                    <div class="accordion-content-wrapper">
                                                        <div class="notice-details-content">
                                                    
                                                                <p v-if="businessPartnersStore.businessPartner.info">{{ businessPartnersStore.businessPartner.info }}</p>
                                                                <p v-else>Keine Hinweise vorhanden</p>
                                                
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                    </div>
                                    <div :class="['accordion-menu customer-details', {'preventOnDesktop' : userStore.selectedMedia === 'desktop'}]">
                                            <ul class="main-list">
                                                <li class="main-list-item">
                                                    <input type="checkbox" checked>
                                                    <i class="arrow"></i>
                                                    <h3>Kundendaten <span v-if="contactNeedsUpdate">(!)</span></h3>
                                                    <div class="accordion-content-wrapper">
                                                        <div class="customer-details-content">
                                                            <div class="headline-wrapper">
                                                                <div class="txt-wrapper">
                                                                    <span :style="{color: businessPartnersStore.businessPartner.creditWorthiness.color}">{{ businessPartnersStore.businessPartner.creditWorthiness.description }}</span>
                                                                    <h4>{{businessPartnersStore.businessPartner.name}}</h4>
                                                                    <div class="lists">
                                                                        <span class="singleList" v-for="list in businessPartnersStore.businessPartner.lists" :key="list.id">{{list}}</span>
                                                                    </div>
                                                                    <span>{{businessPartnersStore.businessPartner.gpNo}}</span>
                                                                </div>
                                                                <!-- <div :class="['productsIcon-wrapper', {'deactivated' : !businessPartnersStore.businessPartner.reportingLink}]">
                                                                    <div class="indicator-wrapper"><a class="active" :href="businessPartnersStore.businessPartner.reportingLink" target="_blank"><img src="@/assets/imgs/logos/sellwerk/sellwerk-favicon.svg" alt="Gesamt" /></a></div>
                                                                    <div class="indicator-wrapper"><a :class="[{active : businessPartnersStore.businessPartner.contractList.find((contract) => contract.publication.startsWith('GS'))}]" :href="`${businessPartnersStore.businessPartner.reportingLink}&context=GS`" target="_blank"><img src="@/assets/imgs/logos/campaigns/Gelbe-Seiten-App-Icon.svg" alt="Reporting:GS" /></a></div>
                                                                    <div class="indicator-wrapper"><a :class="[{active : businessPartnersStore.businessPartner.contractList.find((contract) => contract.publication.startsWith('TB'))}]" :href="`${businessPartnersStore.businessPartner.reportingLink}&context=TB`" target="_blank"><img src="@/assets/imgs/logos/campaigns/Das-Telefonbuch-App-Icon.svg" alt="Reporting:TB" /></a></div>
                                                                    <div class="indicator-wrapper"><a :class="[{active : businessPartnersStore.businessPartner.contractList.find((contract) => contract.publication.startsWith('OE'))}]" :href="`${businessPartnersStore.businessPartner.reportingLink}&context=OETB`" target="_blank"><img src="@/assets/imgs/logos/campaigns/Das-Oertliche-App-Icon.svg" alt="Reporting:ÖTB" /></a></div>
                                                                </div> -->
                                                            </div>
                                                            <!-- <hr> -->
                                                            <div class="customer-details-wrapper">
                                                                <div class="container customerWrapper">
                                                                    <h4 class="achieved left contact"> Anschrift</h4>
                                                                    <div class="contactInfoWrapper noEdit">
                                                                        <div class="input-wrapper">
                                                                            <a v-if="businessPartnersStore.businessPartner.name" target="_blank" href="https://manager.sellwerk.de/website-check" @click="handleLinkClick($event, businessPartnersStore.businessPartner.name)">
                                                                                <div class="login-field">
                                                                                    <img src="@/assets/imgs/icons/copy-solid.svg" alt="Lock Icon">
                                                                                    <input readonly class="login-input" :value="businessPartnersStore.businessPartner.name" placeholder="Kundennamen">
                                                                                </div>
                                                                            </a>
                                                                            <div v-else class="login-field">
                                                                                <div class="login-field">
                                                                                    <img src="@/assets/imgs/icons/copy-solid.svg" alt="Lock Icon">
                                                                                    <input readonly class="login-input" placeholder="Kundennamen">
                                                                                </div>
                                                                            </div>

                                                                            <a v-if="businessPartnersStore.businessPartner.addressList[0].name !== ' ,  '" target="_blank" :href="'https://www.google.com/maps/place/' + 
                                                                                businessPartnersStore.businessPartner.addressList[0].name.split(',')[0].split(' ')[0] + 
                                                                                '+' +
                                                                                businessPartnersStore.businessPartner.addressList[0].name.split(',')[0].split(' ')[1] + 
                                                                                ',+' +
                                                                                businessPartnersStore.businessPartner.addressList[0].name.split(',')[1].split(' ')[1] + 
                                                                                '+' +
                                                                                businessPartnersStore.businessPartner.addressList[0].name.split(',')[1].split(' ')[2]"
                                                                            >
                                                                                <div class="login-field">
                                                                                    <img src="@/assets/imgs/icons/location-dot-solid.svg" alt="Lock Icon">
                                                                                    <input readonly class="login-input" :value="businessPartnersStore.businessPartner.addressList[0].name" placeholder="Adresse">
                                                                                </div>
                                                                            </a>
                                                                            <div v-else class="login-field">
                                                                                <img src="@/assets/imgs/icons/location-dot-solid.svg" alt="Lock Icon">
                                                                                <input readonly class="login-input" placeholder="Adresse">
                                                                            </div>

                                                                            <div class="login-field">
                                                                                <img src="@/assets/imgs/icons/map-solid.svg" alt="Lock Icon">
                                                                                <input readonly class="login-input" :value="businessPartnersStore.businessPartner.tour" placeholder="Tour">
                                                                            </div>

                                                                            
                                                                            <div class="login-field">
                                                                                <img src="@/assets/imgs/icons/industry-solid.svg" alt="Lock Icon">
                                                                                <input readonly class="login-input" :value="businessPartnersStore.businessPartner.businessCategoryList.map((businessCategory)=>businessCategory.name).join(', ')" placeholder="Branche">
                                                                            </div>
                                                                    
                                                                        </div>
                                                                    </div>
                                                                    <!-- <ul>
                                                                        <li>
                                                                            <a target="_blank" href="https://manager.sellwerk.de/website-check" @click="handleLinkClick($event, businessPartnersStore.businessPartner.name)">
                                                                                {{ businessPartnersStore.businessPartner.name }}
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a target="_blank" :href="'https://www.google.com/maps/place/' + 
                                                                                businessPartnersStore.businessPartner.addressList[0].name.split(',')[0].split(' ')[0] + 
                                                                                '+' +
                                                                                businessPartnersStore.businessPartner.addressList[0].name.split(',')[0].split(' ')[1] + 
                                                                                ',+' +
                                                                                businessPartnersStore.businessPartner.addressList[0].name.split(',')[1].split(' ')[1] + 
                                                                                '+' +
                                                                                businessPartnersStore.businessPartner.addressList[0].name.split(',')[1].split(' ')[2]"
                                                                            >
                                                                                {{businessPartnersStore.businessPartner.addressList[0].name}}
                                                                            </a>
                                                                        </li>
                                                                        <li>{{businessPartnersStore.businessPartner.tour}}</li>
                                                                        <li v-if="businessPartnersStore.businessPartner.businessCategoryList?.length>0">{{businessPartnersStore.businessPartner.businessCategoryList.map((businessCategory)=>businessCategory.name).join(', ')}}</li>
                                                                    </ul> -->
                                                                </div>
                                                                <!-- <hr> -->
                                                                <div class="container">
                                                                    <h4 class="achieved left contact">Kontakt</h4>
                                                                    <div class="contactInfoWrapper noEdit">
                                                                        <div class="input-wrapper">
                                                                            <a v-if="businessPartnersStore.businessPartner.websiteList[0]?.name" target="_blank" :href="businessPartnersStore.businessPartner.websiteList[0]?.name">
                                                                                <div class="login-field">
                                                                                    <img src="@/assets/imgs/icons/earth-europe-solid.svg" alt="Lock Icon">
                                                                                    <input readonly class="login-input" :value="businessPartnersStore.businessPartner.websiteList[0]?.name" placeholder="Website">
                                                                                </div>
                                                                            </a>
                                                                            <div v-else class="login-field">
                                                                                <div class="login-field">
                                                                                    <img src="@/assets/imgs/icons/earth-europe-solid.svg" alt="Lock Icon">
                                                                                    <input readonly class="login-input" placeholder="Website">
                                                                                </div>
                                                                            </div>

                                                                            <a v-if="businessPartnersStore.businessPartner.phoneList[0]?.name" :href="'tel:' + businessPartnersStore.businessPartner.phoneList[0]?.name">
                                                                                <div class="login-field">
                                                                                    <img src="@/assets/imgs/icons/phone-solid.svg" alt="Lock Icon">
                                                                                    <input readonly class="login-input" :value="businessPartnersStore.businessPartner.phoneList[0]?.name" placeholder="Telefon">
                                                                                </div>
                                                                            </a>
                                                                            <div v-else class="login-field">
                                                                                <img src="@/assets/imgs/icons/phone-solid.svg" alt="Lock Icon">
                                                                                <input readonly class="login-input" placeholder="Telefon">
                                                                            </div>

                                                                            <a v-if="businessPartnersStore.businessPartner.phoneList[1]?.name" :href="'tel:' + businessPartnersStore.businessPartner.phoneList[1]?.name">
                                                                                <div class="login-field">
                                                                                    <img src="@/assets/imgs/icons/mobile-screen-button-solid.svg" alt="Lock Icon">
                                                                                    <input readonly class="login-input" :value="businessPartnersStore.businessPartner.phoneList[1]?.name" placeholder="Handynummer">
                                                                                </div>
                                                                            </a>
                                                                            <div v-else class="login-field">
                                                                                <img src="@/assets/imgs/icons/mobile-screen-button-solid.svg" alt="Lock Icon">
                                                                                <input readonly class="login-input" placeholder="Handynummer">
                                                                            </div>

                                                                            <a v-if="businessPartnersStore.businessPartner.emailList[0]?.name" :href="'mailto:' + businessPartnersStore.businessPartner.emailList[0]?.name">
                                                                                <div class="login-field">
                                                                                    <img src="@/assets/imgs/icons/envelope-solid.svg" alt="Lock Icon">
                                                                                    <input readonly class="login-input" :value="businessPartnersStore.businessPartner.emailList[0]?.name" placeholder="E-Mail">
                                                                                </div>
                                                                            </a>
                                                                            <div v-else class="login-field">
                                                                                <img src="@/assets/imgs/icons/envelope-solid.svg" alt="Lock Icon">
                                                                                <input readonly class="login-input" placeholder="E-Mail">
                                                                            </div>
                                                                    
                                                                        </div>
                                                                    </div>
                                                                    <!-- <ul>
                                                                        <li><a :href="businessPartnersStore.businessPartner.websiteList[0]?.name"> {{businessPartnersStore.businessPartner.websiteList[0]?.name}}</a></li>
                                                                        <li><a :href="'tel:' + businessPartnersStore.businessPartner.phoneList[0]?.name"> {{businessPartnersStore.businessPartner.phoneList[0]?.name}}</a></li>
                                                                        <li><a :href="'tel:' + businessPartnersStore.businessPartner.phoneList[1]?.name"> {{businessPartnersStore.businessPartner.phoneList[1]?.name}}</a></li>
                                                                        <li><a :href="'mailto:' + businessPartnersStore.businessPartner.emailList[0]?.name"> {{businessPartnersStore.businessPartner.emailList[0]?.name}}</a></li>
                                                                    </ul> -->
                                                                </div>
                                                                <!-- <hr> -->
                                                                <div class="container">
                                                                    <div class="headlineWrapper">
                                                                        <h4 class="achieved left contact">Entscheider</h4>
                                                                        <div class="btnWrapper" v-show="userStore.currentSelection.businessPartner.editMode">
                                                                            <button class="icon" id="close" @click="handleCloseEditContactInfo()" name="editContactClose"><img src="@/assets/imgs/icons/check-solid.svg"></button>
                                                                            <button class="icon" id="undo" @click="handleUndoEditContactInfo()" name="editContactUndo"><img src="@/assets/imgs/icons/xmark-solid.svg"></button>
                                                                        </div>
                                                                        <div class="btnWrapper" v-show="!userStore.currentSelection.businessPartner.editMode">
                                                                            <button class="icon" id="edit" @click="handleEditContactInfo()" name="editContact"><img src="@/assets/imgs/icons/pen-to-square-solid.svg"></button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="contactInfoWrapper" v-show="userStore.currentSelection.businessPartner.editMode">
                                                                        <div class="input-wrapper threeColumns">
                                                                            <div class="inputRow login-field">
                                                                                <div class="login-field">
                                                                                    <img src="@/assets/imgs/icons/venus-mars-solid.svg" alt="Lock Icon">
                                                                                    <select v-model="contact.gender">
                                                                                        <option value="null">---</option>
                                                                                        <option value="f">Frau</option>
                                                                                        <option value="m">Herr</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div class="login-field">
                                                                                    <img src="@/assets/imgs/icons/user-solid.svg" alt="Person Icon">
                                                                                    <input v-model="contact.firstname" type="text" class="login-input" placeholder="Vorname">
                                                                                </div>
                                                                                <div class="login-field">
                                                                                    <img src="@/assets/imgs/icons/user-solid.svg" alt="Person Icon">
                                                                                    <input v-model="contact.surname" type="text" class="login-input" placeholder="Name">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="input-wrapper">
                                                                            <div class="login-field">
                                                                                <img src="@/assets/imgs/icons/phone-solid.svg" alt="Lock Icon">
                                                                                <input class="login-input" v-model="contact.phoneStationary" placeholder="Telefon">
                                                                            </div>
                                                                            <div class="login-field">
                                                                                <img src="@/assets/imgs/icons/mobile-screen-button-solid.svg" alt="Lock Icon">
                                                                                <input class="login-input" v-model="contact.phoneMobile" placeholder="Handynummer">
                                                                            </div>
                                                                            <div class="login-field">
                                                                                <img src="@/assets/imgs/icons/envelope-solid.svg" alt="Lock Icon">
                                                                                <input class="login-input" v-model="contact.email" placeholder="E-Mail">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div v-show="!userStore.currentSelection.businessPartner.editMode">
                                                                        <div class="contactInfoWrapper noEdit">
                                                                            <div class="input-wrapper threeColumns">
                                                                                <div class="inputRow login-field">
                                                                                    <div class="login-field">
                                                                                        <img src="@/assets/imgs/icons/venus-mars-solid.svg" alt="Lock Icon">
                                                                                        <input v-if="contact.gender==='f'" readonly type="text" class="login-input" placeholder="Frau">
                                                                                        <input v-if="contact.gender==='m'" readonly type="text" class="login-input" placeholder="Herr">
                                                                                        <input v-if="contact.gender===null" readonly type="text" class="login-input" placeholder="--">
                                                                                    </div>
                                                                                    <div class="login-field">
                                                                                        <img src="@/assets/imgs/icons/user-solid.svg" alt="Person Icon">
                                                                                        <input readonly v-model="contact.firstname" type="text" class="login-input" placeholder="Vorname">
                                                                                    </div>
                                                                                    <div class="login-field">
                                                                                        <img src="@/assets/imgs/icons/user-solid.svg" alt="Person Icon">
                                                                                        <input readonly v-model="contact.surname" type="text" class="login-input" placeholder="Name">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="input-wrapper">
                                                                                <a v-if="contact.phoneStationary"  :href="`tel:${contact.phoneStationary}`">
                                                                                    <div class="login-field">
                                                                                        <img src="@/assets/imgs/icons/phone-solid.svg" alt="Lock Icon">
                                                                                        <input readonly class="login-input" v-model="contact.phoneStationary" placeholder="Telefon">
                                                                                    </div>
                                                                                </a>
                                                                                <div v-else class="login-field">
                                                                                    <img src="@/assets/imgs/icons/phone-solid.svg" alt="Lock Icon">
                                                                                    <input readonly class="login-input" v-model="contact.phoneStationary" placeholder="Telefon">
                                                                                </div>

                                                                                <a v-if="contact.phoneMobile" :href="`tel:${contact.phoneMobile}`">
                                                                                    <div class="login-field">
                                                                                        <img src="@/assets/imgs/icons/mobile-screen-button-solid.svg" alt="Lock Icon">
                                                                                        <input readonly class="login-input" v-model="contact.phoneMobile" placeholder="Handynummer">
                                                                                    </div>
                                                                                </a>
                                                                                <div v-else class="login-field">
                                                                                    <img src="@/assets/imgs/icons/mobile-screen-button-solid.svg" alt="Lock Icon">
                                                                                    <input readonly class="login-input" v-model="contact.phoneMobile" placeholder="Handynummer">
                                                                                </div>

                                                                                <a v-if="contact.email" :href="`mailto:${contact.email}`"> 
                                                                                    <div class="login-field">
                                                                                        <img src="@/assets/imgs/icons/envelope-solid.svg" alt="Lock Icon">
                                                                                        <input readonly class="login-input" v-model="contact.email" placeholder="E-Mail">
                                                                                    </div>
                                                                                </a>
                                                                                <div v-else class="login-field">
                                                                                    <img src="@/assets/imgs/icons/envelope-solid.svg" alt="Lock Icon">
                                                                                    <input readonly class="login-input" v-model="contact.email" placeholder="E-Mail">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                    </div>
                                </div>
                                <div class="column">
                                    <div :class="['container accordion-menu editing', {'preventOnDesktop' : userStore.selectedMedia === 'desktop'}]">
                                        <h4>Neuer Kontaktierungsstatus</h4>
                                        <div class="multiform">
                                            <span class="stepIndex">Schritt {{ status.currentStep }}:</span>
                                            <div class="multiform-step" v-if="status.currentStep === 1">
                                                <div class="container form-step" id="_2" v-if="status['Entscheider Kontakt hergestellt'].display===true">
                                                    <h5>Entscheider Kontakt</h5>
                                                    <div class="input-wrapper" v-for="(status, name, index) in status['Entscheider Kontakt hergestellt'].options" :key="index">
                                                        <input type="radio" name="contact" :id="name" @click="handleChangeStatus('Entscheider Kontakt hergestellt', name)">
                                                        <label :for="name">{{ name }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="multiform-step" v-if="status.currentStep === 2">
                                                <div class="container form-step" id="_22" v-if="status['Beendet'].display===true">
                                                    <h5>Beendet</h5>
                                                    <template v-for="(status, name, index) in status['Beendet'].options" :key="index">
                                                        <div v-if="status.display===true" class="input-wrapper" >
                                                            <input type="radio" name="next_contact" :id="name"  @click="handleChangeStatus('Beendet', name)">
                                                            <label :for="name">{{ name }}</label>
                                                        </div>
                                                    </template>
                                                </div>
                                                <div class="container form-step" id="_2" v-if="status['Unverbindliche Wiedervorlage'].display === true">
                                                    <h5>Unverbindliche Wiedervorlage für</h5>
                                                    <template v-for="(status, name, index) in status['Unverbindliche Wiedervorlage'].options" :key="index">
                                                        <div v-if="status.display === true" class="input-wrapper">
                                                            <input type="radio" name="next_contact" :id="name" @click="handleChangeStatus('Unverbindliche Wiedervorlage', name)">
                                                            <label :for="name">{{ name }}</label>
                                                        </div>
                                                    </template>
                                                    <div class="datePicker">
                                                        <div class="input-wrapper">
                                                            <input type="date" v-model="status.datePicker['unverbindlich'].selected.date" @change="handleChangeStatus('Auswahl Datum')" :min="status.datePicker['unverbindlich'].selected.dateMin" :max="status.datePicker['unverbindlich'].selected.dateMax" />
                                                            <input type="time" v-model="status.datePicker['unverbindlich'].selected.time" @change="handleChangeStatus('Auswahl Zeit')" />
                                                            {{ status.datePicker.addendum }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="container form-step" id="_6" v-if="status['Verbindlicher Termin mit Entscheider'].display===true">
                                                    <h5>Verbindlicher Termin mit Entscheider</h5>
                                                    <template v-for="(status, name, index) in status['Verbindlicher Termin mit Entscheider'].options" :key="index">
                                                        <div v-if="status.display===true" class="input-wrapper" >
                                                            <input type="radio" name="next_contact" :id="name" @click="handleChangeStatus('Verbindlicher Termin mit Entscheider', name)">
                                                            <label :for="name">{{ name }}</label>
                                                        </div>
                                                    </template>
                                                    <div class="datePicker">
                                                        <div class="input-wrapper">
                                                            <input type="date" v-model="status.datePicker['verbindlich'].selected.date" @change="handleChangeStatus('Auswahl Datum')" :min="status.datePicker['verbindlich'].selected.dateMin" :max="status.datePicker['verbindlich'].selected.dateMax" />
                                                            <input type="time" v-model="status.datePicker['verbindlich'].selected.time" @change="handleChangeStatus('Auswahl Zeit')" />
                                                            {{ status.datePicker.addendum }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <p v-if="warning" class="warning-txt">Bitte mindestens ein Potential auswählen.</p>
                                            </div>
                                            <input class="note" type="text" v-model="currentComment" placeholder="Notiz (Optional)">
                                            <div class="multiform-navigation">
                                                <button @click="handleGoToPreviousStep()" :disabled="!status.allowPrevious">Zurück</button>
                                                <button @click="handleGoToNextStep()" :disabled="!status.allowNext">Weiter</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="['accordion-menu notes-details', {'preventOnDesktop' : userStore.selectedMedia === 'desktop'}]">
                                        <ul class="main-list">
                                            <li class="main-list-item">
                                                <input type="checkbox" checked>
                                                <i class="arrow"></i>
                                                <h3><b>{{ businessPartnersStore.businessPartner.salesPitchList.filter(note => note?.date !== null).length - 1 }}</b>Verlauf Kontaktierungsstatus</h3>
                                                <div class="accordion-content-wrapper">
                                                    <div class="notes-details-content">
                                                        <div class="notes-wrapper" v-for="(note, index) in businessPartnersStore.businessPartner.salesPitchList" :key="note.id">
                                                            <div v-if="note?.id !== -1" class="note">
                                                                <!-- {{ index + 1 }} -->
                                                                <p v-if="note?.date !== null" class="date">{{note?.date}}</p>
                                                                <div class="status information" :id="'_' + note?.status.id">
                                                                    <div class="name" v-if="note?.dateFollowUp === null">
                                                                        <span>{{note?.status?.name}}</span>
                                                                    </div>
                                                                    <div class="dateFollowUp" v-else>
                                                                        <span>{{note?.status?.listAs}}: </span>
                                                                        <span>{{ formatDateTime(note?.dateFollowUp) }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="status information" v-if ="note?.comment !== null">
                                                                    <div class="comment">
                                                                        <span>Notiz: </span> 
                                                                        <span>{{note?.comment}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="column">
                          
                                    <div :class="['accordion-menu potential', {'preventOnDesktop' : userStore.selectedMedia === 'desktop', 'warning': warning}]">
                                        <ul class="main-list">
                                            <li class="main-list-item">
                                                <input type="checkbox" checked>
                                                <i class="arrow"></i>
                                                <h3>Potentiale</h3>
                                                <div class="accordion-content-wrapper">
                                                    <div class="potential-details-content">
                                                        <div class="potentiale-edited">
                                                            <h4>Potential Feedback</h4>
                                                            <div class="info-wrapper container edited" v-if="offersWithFeedback.length === 0">Kein Potential mit Feedback vorhanden.</div>
                                                            <div class="info-wrapper container edited" v-for="offer in offersWithFeedback" :key="offer.product.id">
                                                                <div class="select-wrapper">
                                                                    <p :title="
                                                                        offer.status.id === 16 ? 'Potential' : 
                                                                        offer.status.id === 23 ? 'Wird im nächsten Termin besprochen' : 
                                                                        offer.status.id === 21 ? 'Wurde vom Kunden abgelehnt' : 
                                                                        offer.status.id === 19 ? 'Wurde vom Kunden persönlich angenommen' : 
                                                                        offer.status.id === 20 ? 'Wurde vom Kunden telefonisch angenommen' : 
                                                                        offer.status.id === 28 ? 'Wurde im Termin nicht besprochen' : 
                                                                        offer.status.id === 18 ? 'Wurde in die nächste Kampagne verschoben' : 
                                                                        ''" 
                                                                        :class="{['_' + offer.status.id]: true }">{{offer.product.name}}</p>
                                                                </div>
                                                                <!-- <hr v-if="offer.status.id !== 16"> -->
                                                            </div>
                                                        </div>
                                                        <div class="potentiale-pending">
                                                            <h4>Beim Termin besprechen</h4>
                                                            <div class="filler">
                                                                <div class="wrapper">
                                                                    <div class="info-wrapper container" v-for="offer in offersForSalesPitch" :key="offer.product.id">
                                                                        <div class="select-wrapper">
                                                                            <p :title="
                                                                                offer.status.id === 16 ? 'Potential' : 
                                                                                offer.status.id === 23 ? 'Wird im nächsten Termin besprochen' : 
                                                                                offer.status.id === 21 ? 'Wurde vom Kunden abgelehnt' : 
                                                                                offer.status.id === 19 ? 'Wurde vom Kunden persönlich angenommen' : 
                                                                                offer.status.id === 20 ? 'Wurde vom Kunden telefonisch angenommen' : 
                                                                                offer.status.id === 28 ? 'Wurde im Termin nicht besprochen' : 
                                                                                offer.status.id === 18 ? 'Wurde in die nächste Kampagne verschoben' : 
                                                                                ''" 
                                                                                :class="{['_' + offer.status.id]: true }">{{offer.product.name}}</p>

                                                                            <button v-if="offer.status.id === 16 && businessPartnersStore.businessPartner.offerList.filter((offer) => offer.status.id===16).length > 1" @click="handleRemoveProduct(offer.product.id)"></button>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <hr> -->
                                                                </div>                                                      
                                                                <div class="add-potential-wrapper container">
                                                                    <select v-model="newProductForAppointment">
                                                                        <option disabled value="-1" selected>Weiteres Produkt hinzufügen</option>
                                                                        <option v-for="product in userStore.filterOption.businessPartnerPotential.slice(1).filter((product)=> !businessPartnersStore.businessPartner.offerList.find((offer) => offer.product.id === product.id))" 
                                                                            :key="product.id" 
                                                                            :value="product.id"
                                                                        >
                                                                            {{ product.name }}
                                                                        </option>
                                                                    </select>
                                                                    <button @click="handleAddProduct()" class="add-potential"></button>
                                                                </div>
                                                            </div>
                                                            <!-- <p v-if="warning" class="warning-txt">Bitte mindestens ein Potential auswählen.</p> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div :class="['container accordion-menu reports', {'deactivated' : !businessPartnersStore.businessPartner.reportingLink}]" v-if="businessPartnersStore.businessPartner.reportingLink">
                                        <h3>KST Reporting</h3>
                                        <div class="productsIcon-wrapper">
                                            <div class="indicator-wrapper"><a class="active" :href="businessPartnersStore.businessPartner.reportingLink" target="_blank"><img src="@/assets/imgs/logos/sellwerk/sellwerk-favicon.svg" alt="Gesamt" /></a></div>
                                            <div class="indicator-wrapper"><a :class="[{active : businessPartnersStore.businessPartner.contractList.find((contract) => contract.publication.startsWith('GS'))}]" :href="`${businessPartnersStore.businessPartner.reportingLink}&context=GS`" target="_blank"><img src="@/assets/imgs/logos/campaigns/Gelbe-Seiten-App-Icon.svg" alt="Reporting:GS" /></a></div>
                                            <div class="indicator-wrapper"><a :class="[{active : businessPartnersStore.businessPartner.contractList.find((contract) => contract.publication.startsWith('TB'))}]" :href="`${businessPartnersStore.businessPartner.reportingLink}&context=TB`" target="_blank"><img src="@/assets/imgs/logos/campaigns/Das-Telefonbuch-App-Icon.svg" alt="Reporting:TB" /></a></div>
                                            <div class="indicator-wrapper"><a :class="[{active : businessPartnersStore.businessPartner.contractList.find((contract) => contract.publication.startsWith('OE'))}]" :href="`${businessPartnersStore.businessPartner.reportingLink}&context=OETB`" target="_blank"><img src="@/assets/imgs/logos/campaigns/Das-Oertliche-App-Icon.svg" alt="Reporting:ÖTB" /></a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="['accordion-menu products', {'preventOnDesktop' : userStore.selectedMedia === 'desktop'}]">
                                        <ul class="main-list">
                                            <li class="main-list-item">
                                                <input type="checkbox" checked>
                                                <i class="arrow"></i>
                                                <h3>Produkte 360°</h3>
                                                <div class="accordion-content-wrapper">
                                                    <div class="products-details-content">
                                                        <div :class="['status-container']" v-for="status in ['terminated', 'active', 'history']" :key="status">
                                                            <h4 :class="['status-heading', status === 'terminated' ? 'not-achieved left' : status === 'active' ? 'achieved left' : 'history left']">{{ status === 'terminated' ? 'Gekündigt' : status === 'active' ? 'Aktiv' : 'Historie' }}</h4>
                                                            <div class="productGroup-wrapper" v-if="businessPartnersStore.businessPartner.products.filter((product) => product.status===status).length>0">
                                                                <div v-for="productGroupName in ['OMP', 'GS', 'TB', 'OE']" :key="productGroupName">
                                                                    <div :class="['container',  productGroupName]" 
                                                                        v-if="businessPartnersStore.businessPartner.products.filter((product) => product.status===status && product.productGroup.name===productGroupName).length>0">
                                                                        <div class="header">
                                                                            <img v-if="productGroupName === 'GS'" src="@/assets/imgs/logos/campaigns/Gelbe-Seiten-App-Icon.svg" alt="GS" />
                                                                            <img v-if="productGroupName === 'TB'" src="@/assets/imgs/logos/campaigns/Das-Telefonbuch-App-Icon.svg" alt="TB" />
                                                                            <img v-if="productGroupName === 'OE'" src="@/assets/imgs/logos/campaigns/Das-Oertliche-App-Icon.svg" alt="OE" />
                                                                            <div v-if="productGroupName === 'OMP'" class="omp">omp</div>
                                                                            <h4 v-if="productGroupName === 'GS'">Gelbe Seiten</h4>
                                                                            <h4 v-if="productGroupName === 'OE'">Das Örtliche</h4>
                                                                            <h4 v-if="productGroupName === 'TB'">Das Telefonbuch</h4>
                                                                            <h4 v-if="productGroupName === 'OMP'">OMP?</h4>
                                                                        </div>
                                                                        <div class="product" v-for="product in businessPartnersStore.businessPartner.products.filter((product) => product.status===status && product.productGroup.name===productGroupName )" :key="product.name">
                                                                            <div class="single-product">
                                                                                <div onclick="this.classList.toggle('active')" class="headline"><h4>{{product.name }} ({{product.contracts.length}})</h4><div class="arrow"></div></div>
                                                                                <div class="product-info-list">
                                                                                    <div :class="['product-info']" v-for="(contract) in product.contracts" :key="contract.id">
                                                                                    <!-- <div :class="['product-info', { 'latest' : (index ===0 && status==='active')  }]" v-for="(contract, index) in product.contracts" :key="contract.id"> -->
                                                                                        <p>{{contract.publication}}</p>
                                                                                        <p>{{contract.campaign.name}}</p>
                                                                                        <p>alt: {{contract.alt}}</p>
                                                                                        <p>ist: {{ contract.ist }}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div  v-else :class="['container']">
                                                                <p>Keine Produkte vorhanden!</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                            </div>
                        </div>
                        <div class='wave-wrapper'>
                            <div class='wave -one'></div>
                            <div class='wave -two'></div>
                            <div class='wave -three'></div>
                        </div>
                    </div>
                    <div class="operation-wrapper">
                        <p>{{ incompleteSalesPitchMessage }}</p>
                        <div class="buttons">
                            <button :class="['save', {'disabled cell-grid': saveSalesPitchIsLoading || userStore.preventSave}]" :disabled="saveSalesPitchIsLoading || userStore.preventSave || userStore.currentSelection.businessPartner.isLoading === true || !userStore.currentSelection.businessPartner?.hasValid?.salesPitch || ((Object.values(status['Verbindlicher Termin mit Entscheider'].options).some(option => option.checked === true) && offersForSalesPitch.length === 0))" @click="handleSaveSalesPitch()">
                                Speichern
                                <div v-if="saveSalesPitchIsLoading" class="loader"></div>
                            </button> 
                            <button id="abortEdit" @click="handleAbortSalesPitch()" name="abortEditContact">Abbrechen</button>
                        </div>
                    </div>
                    <reminder></reminder>
                </div>
            </main>
        </div>
</template>