import { defineStore } from "pinia";

import { useUserStore } from "./user";

import { setKey as setBrowserStorageKey } from "./helpers/localStorage.mjs";
import { fetchFromBackend } from "./helpers/backend.mjs";


export const useIncentivesStore = defineStore("incentives", {
  state: () => {
    return {
      incentives: [],
    }
  },
  getters: {
    // return the full incentive data, the signature allows for finding the incentive by display (i.e. salesAgens or salesTeams) and campaign
    incentive: (state) => {
      if (!state.incentives) return false;
      const userStore = useUserStore();
      const incentive = state.incentives.find((incentive) => incentive.signature === `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`);
      if (!incentive) return { 'salesAgents': [], 'salesTeams': [] };
      return { 'salesAgents': incentive.data.salesAgents, 'salesTeams': incentive.data.salesTeams }
    },
    incentiveSales: (state) => {
      const userStore = useUserStore();
      const incentive = state.incentives.find((incentive) => incentive.signature === `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`);
      if (!incentive || !incentive.data[userStore.currentSelection.incentives.display].champions) return [];
      const salesAgents = incentive.data['salesAgents'].champions.filter((salesAgent) => salesAgent.salesEuro > 0 || salesAgent.salesEuroNew > 0);
      const salesTeams = incentive.data['salesTeams'].champions.filter((salesTeam) => salesTeam.salesEuro > 0 || salesTeam.salesEuroNew > 0);
      // for salesManagers, highlight all salesAgents of the own team
      if (userStore.isSalesTeamManager) salesAgents.forEach((salesAgent) => salesAgent.highlightedPosition = (salesAgent.idTeam === userStore.currentSelection.salesTeam.id) ? true : false);
      return { 'salesAgents': salesAgents, 'salesTeams': salesTeams }
    },
    incentiveNoSales: (state) => {
      const userStore = useUserStore();
      const incentive = state.incentives.find((incentive) => incentive.signature === `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`);
      if (!incentive || !incentive.data[userStore.currentSelection.incentives.display].champions) return [];
      // console.log("incentiveNoSales", incentive.champions);
      const salesAgents = incentive.data['salesAgents'].champions.filter((salesAgent) => salesAgent.salesEuro === 0 && salesAgent.salesEuroNew === 0);
      const salesTeams = incentive.data['salesTeams'].champions.filter((salesTeam) => salesTeam.salesEuro === 0 && salesTeam.salesEuroNew === 0);
      // for salesManagers, highlight all salesAgents of the own team
      if (userStore.isSalesTeamManager) salesAgents.forEach((salesAgent) => salesAgent.highlightedPosition = (salesAgent.idTeam === userStore.currentSelection.salesTeam.id) ? true : false);
      return { 'salesAgents': salesAgents, 'salesTeams': salesTeams }
    },
    // return the top three sales agents or sales teams
    topThree: (state) => {
      const userStore = useUserStore();
      const topThree = { "salesAgents": [], "salesTeams": [] };
      const incentive = state.incentives.find((incentive) => incentive.signature === `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`);
      if (!incentive) return [];
      for (const display of ["salesAgents", "salesTeams"]) {
        if (incentive.data[display].champions.length > 1) topThree[display].push(incentive.data[display].champions[1]);
        if (incentive.data[display].champions.length > 0) topThree[display].push(incentive.data[display].champions[0]);
        if (incentive.data[display].champions.length > 2) topThree[display].push(incentive.data[display].champions[2]);
      }
      // highlight the own salesTeam inside the top three salesTeams
      const ownTeamInTopThree = topThree['salesTeams'].find((salesTeam) => salesTeam.idTeam === userStore.currentSelection.salesTeam.id);
      if (ownTeamInTopThree) ownTeamInTopThree.highlightedPosition = true;
      // highlight the own salesAgent inside the top three salesAgents
      const ownPositionInTopThree = topThree['salesAgents'].find((salesAgent) => salesAgent.idUser === userStore.id);
      if (ownPositionInTopThree) ownPositionInTopThree.highlightedPosition = true;

      return topThree;
    },
    // return the shortened list of those salesManagers/ salesTeams that did not make the top three for the widget
    preview: (state) => {
      const userStore = useUserStore();
      const preview = { "salesAgents": [], "salesTeams": [] };
      let previewStart = 0;
      let previewEnd = 2;
      const incentive = state.incentives.find((incentive) => incentive.signature === `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`);
      if (!incentive) return [];

      // console.log('show incentive with user', userStore.id, 'and user team', userStore.currentSelection.salesTeam)
      if (userStore.isManagement && userStore.currentSelection.scope !== "salesAgent") {
        // console.log('preparing incentives as isManagement')
        // console.log('show topSalesAgents with display status', userStore.currentSelection.incentives.display)
        // the user is management, observer or winBack => simply show position 4 to 6 below the top 3 salesAgents and salesTeams
        for (const display of ["salesAgents", "salesTeams"]) {
          for (let i = 0; i <= 4; i++) {
            if (incentive.data[display].champions[i]) preview[display].push(incentive.data[display].champions[i]);
          }
        }
      } else if (userStore.isSalesTeamManager && userStore.currentSelection.scope !== "salesAgent") {
        // console.log('preparing incentives as isSalesTeamManager')
        // console.log('show salesTeam with display status', userStore.currentSelection.incentives.display)
        // the user is salesTeamManager => fill position 4 to 6 with members of my team that dont belong to the top 3 salesAgents
        let i = 0;
        for (const salesAgent of incentive.data["salesAgents"].champions) {
          i++;
          if (i < 4) continue;
          if (preview["salesAgents"].length > 2) break;
          // console.log('on index', i, 'comparing team', salesAgent, 'with', userStore.currentSelection.salesTeam.id, (salesAgent.idTeam === userStore.currentSelection.salesTeam.id))
          if (salesAgent.idTeam === userStore.currentSelection.salesTeam.id) preview["salesAgents"].push(salesAgent);
        }
        // also show positions 4 to 6 of the salesTeams (or less if there are less than 6 salesTeams)
        for (let i = 3; i <= Math.min(5, (incentive.data.salesTeams?.champions?.length || 1) - 1); i++) {
          const salesTeam = incentive.data["salesTeams"].champions[i];
          // console.log('on index', i, 'comparing team', salesTeam, 'with', userStore.currentSelection.salesTeam.id)
          if (salesTeam.idTeam === userStore.currentSelection.salesTeam.id) salesTeam.highlightedPosition = true;
          if (incentive.data["salesTeams"].champions[i]) preview["salesTeams"].push(incentive.data["salesTeams"].champions[i]);
        }
      } else if (userStore.isSalesAgent || userStore.currentSelection.scope === "salesAgent") {
        console.log('preparing incentives as isSalesAgent')
        // console.log('show salesAgent with display status', userStore.currentSelection.incentives.display)
        // the user is a salesAgent => get the own position within the salesAgents champions
        for (const display of ["salesAgents", "salesTeams"]) {
          let champion = -1;
          let index = -1;
          if (display === "salesAgents") {
            if (userStore.currentSelection.scope === "salesAgent") {
              if (userStore.isManagement || userStore.isSalesTeamManager) {
                champion = incentive.data["salesAgents"].champions.find((champion) => parseInt(champion.idUser) === parseInt(userStore.currentSelection.salesAgent.idUser))
              } else {
                champion = incentive.data["salesAgents"].champions.find((champion) => parseInt(champion.idUser) === parseInt(userStore.id))
              }
            } else {
              champion = incentive.data["salesAgents"].champions.find((champion) => parseInt(champion.idUser) === parseInt(userStore.id))
            }
            if (!champion) continue;
            champion.highlightedPosition = true;
            index = incentive.data["salesAgents"].champions.findIndex((champion) => champion.highlightedPosition);
            // console.log('champion salesAgent', champion, "index", index)
            if (index < 3) {
              // the salesAgent is positioned in the top 3 => return ranks  4 to 6
              // previewStart = 3;
              // previewEnd = 5;
            } else if (index > 2 && index < incentive.data[display].champions.length - 2) {
              // the salesAgent ranks below the top 3 but not at the bottom => return the ranks above and below
              previewStart = index - 1;
              previewEnd = index + 1;
            } else {
              // the salesAgent ranks at the bottom => return the last 3 ranks
              previewStart = incentive.data[display].champions.length - 2;
              previewEnd = index;
            }
          };
          if (display === "salesTeams") {
            champion = incentive.data["salesTeams"].champions.find((champion) => parseInt(champion.idTeam) === parseInt(userStore.currentSelection.salesTeam.id))
            if (!champion) continue;
            champion.highlightedPosition = true;
            index = incentive.data["salesTeams"].champions.findIndex((champion) => champion.highlightedPosition);
            // console.log('champion salesTeam', champion, "index", index)
            // previewStart = 3;
            // previewEnd = 5;
          };
          for (let i = previewStart; i <= previewEnd; i++) {
            // console.log('pushing position', i, 'on', display, "with", incentive.data[display].champions[i])
            if (incentive.data[display].champions[i]) preview[display].push(incentive.data[display].champions[i]);
          }
        }
      }
      return preview[userStore.currentSelection.incentives.display];
    },
  },
  actions: {
    /** see if the incentives need to be fetched again, either because the data is not up to date or the display status has changed
    */
    async refresh() {
      const userStore = useUserStore();
      // create a signature to see if the data is is up to date or needs to be fetched
      const signature = `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`;
      const incentiveExists = this.incentives.find((incentive) => incentive.signature === signature);
      // return the existing data if it exists and its update property is less then 6 hours old
      if (incentiveExists && incentiveExists?.update > (Date.now() - (6 * 60 * 60 * 1000))) return incentiveExists;
      // fetch the data from the backend, get incentives for salesTeams and salesAgents in parallel
      const taskList = [
        fetchFromBackend("incentives/v1/salesAgents", { id: userStore.id, filter: { idCompany: userStore.currentSelection.campaign.company.id, idCampaign: userStore.currentSelection.campaign.id } }),
        fetchFromBackend("incentives/v1/salesTeams", { id: userStore.id, filter: { idCompany: userStore.currentSelection.campaign.company.id, idCampaign: userStore.currentSelection.campaign.id } }),
      ];
      userStore.currentSelection.incentives.isLoading = true;
      const responses = await Promise.all(taskList);
      userStore.currentSelection.incentives.isLoading = false;
      if (!responses[0].length === 2 || !responses[0].incentive || !responses[1].incentive) return false;
      // if the data exists update it, if not create it
      if (incentiveExists) {
        incentiveExists.data = { "salesAgents": responses[0].incentive, "salesTeams": responses[1].incentive };
        incentiveExists.update = Date.now();
      } else {
        this.incentives.push({
          signature,
          data: {
            "salesAgents": responses[0].incentive,
            "salesTeams": responses[1].incentive
          },
          update: Date.now()
        });
      }
    },
  },
});
