<script setup>
    import { ref, computed, onMounted, onUnmounted, onUpdated } from 'vue';
    import Bubble from '@/components/Bubble.vue';

    import { useRoute } from 'vue-router'
    const route = useRoute()

    import { useBusinessPartnersStore } from '@/stores/businessPartners'
    const businessPartnersStore = useBusinessPartnersStore();

    import { useUserStore } from '@/stores/user';
    const userStore = useUserStore();

    import { useActivityStore } from '@/stores/activity'
    const activityStore = useActivityStore();

    import { useFunctionsStore } from '@/stores/functions'
    const functionsStore = useFunctionsStore()

    const idOwnTeam = computed(() => {
        if (!userStore.isSalesTeamManager) return null;
        return userStore.currentSelection.campaign.roles.find((role) => ["MBA:salesTeamManager"].includes(role.name)).team.id;
    })

    const percentageValueWeek = computed(() => {
        if (!activityStore.week) return 0;
        if (activityStore.week[0]?.actualValues?.salesEuro === 0 && activityStore.week[0]?.plannedValues?.salesEuro === 0) {
            return 0;
        }
        const value = Math.floor((activityStore.week[0]?.actualValues?.salesEuro / activityStore.week[0]?.plannedValues?.salesEuro) * 100)
        return isNaN(value) ? 0 : value;
    });

    const percentageValueMonth = computed(() => {
        // if (!activityStore.month) return 0;
        // if (activityStore.month[0]?.actualValues?.salesEuro === 0 && activityStore.month[0]?.plannedValues?.salesEuro === 0) {
        //     return 0;
        // }
        // const value = Math.floor((activityStore.month[0]?.actualValues?.salesEuro / activityStore.month[0]?.plannedValues?.salesEuro) * 100)
        // return isNaN(value) ? 0 : value;

        if (!activityStore.month) return 0;
        if (activityStore.month[0]?.actualValues?.salesEuro === 0 && activityStore.month[0]?.plannedValues?.salesEuro === 0) {
            return 0;
        }
        const value = activityStore.month.level.value
        return isNaN(value) ? 0 : value;
    });

    const isActive = ref(false);

    function toggleActive() {
        // console.log("cklick")
        isActive.value = !isActive.value;
    }

    // manages the selection of a salesAgent or salesTeam in the management view
    async function handleSelectSalesAgent(idSelection) {
        console.log("handleSelectSalesAgent", idSelection)
        if (idSelection == -1) {await userStore.selectCompany()}
        if (idSelection.startsWith('salesAgent:')) await userStore.selectSalesAgent(idSelection.replace('salesAgent:', ''))
        if (idSelection.startsWith('salesTeam:')) await userStore.selectSalesTeam(idSelection.replace('salesTeam:', ''))
    }

    let timerAppointments = null;
    let timerFollowUps = null;
    onMounted (() => {
        timerAppointments = setInterval(async () => {
            await userStore.refreshAppointments()
        }, 1000 * 60) // query the Minute

        timerFollowUps = setInterval(async () => {
            await userStore.refreshFollowUps()
        }, 1000 * 60) // query the Minute

        updateFooterHeight()
        window.addEventListener('resize', updateFooterHeight)
    })

    onUpdated(() => {
        updateFooterHeight()
    })

    onUnmounted (() => {
        clearInterval(timerAppointments)
        clearInterval(timerFollowUps)
        window.removeEventListener('resize', updateFooterHeight)
    })


    const footerRef = ref(null)
    function updateFooterHeight() {
        if (footerRef.value) {
            functionsStore.setFooterHeight(footerRef.value.getBoundingClientRect().height)
        }
    }

    function getCurrentMonth() {
        const currentDate = new Date()
        const monthNames = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
        const month = monthNames[currentDate.getMonth()]
        const year = currentDate.getFullYear().toString().slice(-2)
        return `${month}. ${year}`
    }

    const currentMonth = ref(getCurrentMonth())
</script>

<template>
    <footer class="max-width" id="reminder" ref="footerRef">
        <div class="reminder-inner-wrapper">
            <div class="wrapper">
                <div class="follow-up-wrapper">
                    <div>
                        <div class="headline-wrapper">
                            <div>
                                <img src="@/assets/imgs/icons/icon-wvl.png" alt="Wiedervorlagen Icon"> 
                                <p>Wiedervorlagen:</p>
                            </div>
                        </div>
                        <ul v-if="businessPartnersStore.reminders['Wiedervorlagen'].length>0">
                            <li v-for="appointment of businessPartnersStore.reminders['Wiedervorlagen']" :key="appointment.businessPartner.id">
                                <router-link :to="{ name: 'BusinessPartner', query: { searchMode: 'GPNo', search: appointment.businessPartner.gpNo } }">
                                    {{ appointment.businessPartner.name }}
                                </router-link>
                                <span class="time">{{ appointment.businessPartner.salesPitch.dateFollowUp.split(' ')[1].slice(0,5)}}</span>
                            </li>
                        </ul>
                        <ul class="no-appointments" v-else>
                            <li>
                                <span> Keine Wiedervorlagen mit Uhrzeit festgelegt! </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <hr> -->
                <div class="appointments-wrapper" v-if="businessPartnersStore.reminders['VorOrtTermine'].length>0">
                    <div v-for="appointment of businessPartnersStore.reminders['VorOrtTermine']" :key="appointment.businessPartner.id">
                        <div class="headline-wrapper">
                            <div>            
                                <img src="@/assets/imgs/icons/icon-vot.png" alt="Wiedervorlagen Icon"> 
                                <p>Verbindlicher Termin:</p>
                            </div>
                            <span>{{ appointment.businessPartner.salesPitch.dateFollowUp.split(' ')[1].slice(0,5)}}</span>
                        </div>
                        <ul>
                            <li>
                                <span>
                                    <router-link :to="{ name: 'BusinessPartner', query: { searchMode: 'GPNo', search: appointment.businessPartner.gpNo } }">
                                        {{ appointment.businessPartner.name }}
                                    </router-link>
                                </span>
                            </li>
                            <li>
                                <span>
                                    {{ appointment.businessPartner.addressList[0].street }} {{ appointment.businessPartner.addressList[0].houseNumber }}, 
                                    {{ appointment.businessPartner.addressList[0].postalCode }} {{ appointment.businessPartner.addressList[0].city.name }} 
                                </span>
                            </li>
                            <li>
                                <span>{{ appointment.businessPartner.salesPitch.contact.firstname }} {{ appointment.businessPartner.salesPitch.contact.surname }}</span>
                            </li>
                            <li>
                                <span>{{ appointment.businessPartner.salesPitch.comment }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="appointments-wrapper" v-else>
                    <div>
                        <div class="headline-wrapper">
                            <div>            
                                <img src="@/assets/imgs/icons/icon-vot.png" alt="Wiedervorlagen Icon"> 
                                <p>Verbindlicher Termin:</p>
                            </div>
                        </div>
                        <ul class="no-appointments">
                            <li>
                                <span>Keinen Vor Ort Termin!</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <hr> -->
            </div>
            <div class="circle-container-wrapper">
                <!-- <div v-if="activityStore.month?.name === currentMonth">{{ currentMonth }}</div> -->
                <bubble :mode="'points'" :points="-1 * (activityStore.activity.week?.find(item => item.name === 'Forecast').plannedValues.appointments - activityStore.activity.week?.find(item => item.name === 'Forecast').actualValues.appointments)" :percentValue="100 * (activityStore.activity.week?.find(item => item.name === 'Forecast').actualValues.appointments / activityStore.activity.week?.find(item => item.name === 'Forecast').plannedValues.appointments)" :forecastValue="100"></bubble>
                <bubble :mode="'points'" :points="activityStore.activity.month?.find(item => item.name === currentMonth).level?.points" :percentValue="((activityStore.activity.month?.find(item => item.name === currentMonth).actualValues?.salesEuro / activityStore.activity.month?.find(item => item.name === currentMonth).plannedValues?.salesEuro) * 100)" :forecastValue="100"></bubble>
            </div>
            <div class="reminder-overlay">
                <!-- salesAgent view: show "Wiedervorlagen" -->
                <div class="container main-content cell-grid" v-if="userStore.filterOptions.isLoading === true">
                    <div class="loader" id="loader"></div>
                </div>
                <div class="txt-wrapper" v-if="!userStore.isManagement && !userStore.isSalesTeamManager" @click="toggleActive">
                    <img src="@/assets/imgs/reminder/icon-wvl.png" alt="Wiedervorlage"> 
                    <span v-if="businessPartnersStore.reminders['Wiedervorlagen'].length>0">
                        in {{ businessPartnersStore.reminders['Wiedervorlagen'][0].remainingMinutes }} Min. ({{ businessPartnersStore.reminders['Wiedervorlagen'][0].businessPartner.salesPitch.dateFollowUp.split(' ')[1].slice(0,5)   }} Uhr)
                    </span>
                    <span v-else>keine künftigen WVL</span>
                </div>
                <!-- management view: allow for selecting a salesAgent or area -->
                <select v-else @change="(event) => handleSelectSalesAgent(event.target.value)">
                    <template v-if="userStore.isManagement">
                        <option :selected="userStore.currentSelection.scope === 'campaign' && userStore.currentSelection.salesTeam.id === -1" value="-1">Gesamter Verlag</option>    
                        <hr>
                        <template v-for="(salesTeam) in userStore.filterOption?.salesTeams.filter((option) => option.id!==-1)" :key="salesTeam.id">
                            <option :selected="userStore.currentSelection.salesTeam.id === salesTeam.id" :value="`salesTeam:${salesTeam.id}`">
                                {{ salesTeam.name }}
                            </option>
                        </template>
                        <hr>
                    </template>
                    <template v-if="userStore.isSalesTeamManager">
                        <option :value="`salesTeam:${idOwnTeam}`" :selected="userStore.currentSelection.scope==='salesTeam'">Gesamtes Team</option>    
                        <hr>
                    </template>
                    <template v-for="(salesAgent) in userStore.filterOption?.salesAgents.sort((a,b) => a.firstname > b.firstname).filter((option) => option.id!==-1)" :key="salesAgent.id" :value="`salesAgent:${salesAgent.id}`">
                        <option :value="`salesAgent:${salesAgent.id}`" :selected="userStore.currentSelection.scope==='salesAgent' && userStore.currentSelection.salesAgent.id === salesAgent.id">
                            {{ salesAgent.firstname }} {{ salesAgent.surname }}
                        </option>
                    </template>
                </select>
            </div>
            <div :class="['reminder-main', { 'active': isActive }]" @click="toggleActive">
                <template v-if="businessPartnersStore.reminders['VorOrtTermine'].length>0">
                    <span   data-aos="fade-right"
                            data-aos-easing="ease-out-sine"
                            data-aos-delay="150"
                            data-aos-offset="0"
                            data-aos-anchor="#reminder"
                    class="timer">{{businessPartnersStore.reminders['VorOrtTermine'][0].remainingMinutes}} Min. </span>
                    <div    data-aos="fade-in"
                            data-aos-easing="ease-out-sine"
                            data-aos-delay="50"
                            data-aos-offset="0"
                            data-aos-anchor="#reminder"
                    class="client-info">
                        <div class="client-info">
                            <span class="client-name">{{businessPartnersStore.reminders['VorOrtTermine'][0].businessPartner.name}}</span>
                            <span class="client-adress"><i><b>{{ businessPartnersStore.reminders['VorOrtTermine'][0].businessPartner.addressList[0].street }} {{ businessPartnersStore.reminders['VorOrtTermine'][0].businessPartner.addressList[0].houseNumber }}, {{ businessPartnersStore.reminders['VorOrtTermine'][0].businessPartner.addressList[0].postalCode }} {{ businessPartnersStore.reminders['VorOrtTermine'][0].businessPartner.addressList[0].city.name }} </b></i></span>
                        </div>
                    </div>
                    <img    data-aos="fade-left"
                            data-aos-easing="ease-out-sine"
                            data-aos-delay="150"
                            data-aos-offset="0"
                            data-aos-anchor="#reminder" 
                    src="@/assets/imgs/icons/feather-more-vertical.svg" alt="Menu Icon"> 
                </template>
                <template v-else-if="((userStore.isManagement || userStore.isSalesTeamManager) && userStore.currentSelection.salesAgent.id !==-1) || (!userStore.isManagement && !userStore.isSalesTeamManager)">
                    <span   data-aos="fade-right"
                            data-aos-easing="ease-out-sine"
                            data-aos-delay="1000"
                            data-aos-offset="0"
                            data-aos-anchor="#reminder"
                    class="timer">-- Min.</span>
                    <div    data-aos="fade-in"
                            data-aos-easing="ease-out-sine"
                            data-aos-delay="500"
                            data-aos-offset="0"
                            data-aos-anchor="#reminder"
                    class="client-info">
                        <span class="client-name">keine künftigen Termine</span>
                    </div>
                    <img    data-aos="fade-left"
                            data-aos-easing="ease-out-sine"
                            data-aos-delay="1000"
                            data-aos-offset="0"
                            data-aos-anchor="#reminder"
                    src="@/assets/imgs/icons/feather-more-vertical.svg" alt="Menu Icon"> 
                </template>
                <template v-else>
                    <span class="timer"></span>
                    <div class="client-info">
                        <span class="client-name"></span>
                    </div>
                </template>
            </div>
            <!-- <div class='wave-wrapper'>
                <div class='wave -one'></div>
                <div class='wave -two'></div>
                <div class='wave -three'></div>
            </div> -->
        </div>
        <!-- <div class="footerNavigation">
            <div class="max-width">
                <div class="icon-wrapper">
                    <span class="number-label">1</span>
                    <img src="@/assets/imgs/icons/house-solid.svg" alt="Home Icon">
                    <p>Home</p>
                </div>
                <div class="icon-wrapper">
                    <img src="@/assets/imgs/icons/magnifying-glass-solid.svg" alt="Home Icon">
                    <p>Suche</p>
                </div>
                <div class="icon-wrapper">
                    <img src="@/assets/imgs/icons/square-plus-regular.svg" alt="Home Icon">
                    <p>Neu</p>
                </div>
                <div class="icon-wrapper">
                    <span class="number-label">3</span>
                    <img src="@/assets/imgs/icons/bell-regular.svg" alt="Home Icon">
                    <p>Feedback</p>
                </div>
                <div class="icon-wrapper">
                    <img src="@/assets/imgs/icons/list-solid.svg" alt="Home Icon">
                    <p>Listen</p>
                </div>
            </div>
        </div> -->
    </footer>
</template>