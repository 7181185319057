<script setup>
    // import { ref, onMounted, computed } from 'vue';
    import { ref, onMounted, onUnmounted, watch, computed } from 'vue';

    import { useUserStore } from '@/stores/user'
    const userStore = useUserStore();

    import { useIncentivesStore } from '@/stores/incentives'
    const incentivesStore = useIncentivesStore();

    import { useRatingsStore } from '@/stores/ratings';
    const ratingsStore = useRatingsStore();

    const handleImageError = (event) => {
        event.target.src = 'avatars/dummy.webp';
    };

    const props = defineProps({
        salesAgentId: {
            type: Number,
            default: 0,
        },
        isSalesAgent: {
            type: Boolean,
            default: false,
        },
        showSalesAgent: {
            type: Boolean,
            default: false,
        },
        isUserAvatar: {
            type: Boolean,
            default: false,
        }
    });

    const salesAgentInfo = computed(() => {
        let salesAgentFirstName = null
        let salesAgentLastName = null
        let salesAgentName = null
        let salesAgentPhoto = "dummy"
        let salesAgentStatusClass = ''
        let salesAgentStatusName = null
        let isSalesTeamManager = false
        let isManagement = false

        const salesAgent = userStore.filterOption?.salesAgents?.find(salesAgent => salesAgent.id === props.salesAgentId)
        if (!salesAgent) return { status: null, formattedName: null }
            const idUser = salesAgent.id
        if(props.isUserAvatar===true) {
            console.log('isUserAvatar')
            salesAgentFirstName = userStore.firstname
            salesAgentLastName = userStore.surname
            salesAgentPhoto = userStore.photo
            salesAgentStatusName = incentivesStore.incentive?.salesAgents?.champions?.find(champion => userStore.id === champion.idUser)?.salesAgentStatusCurrent
        } else if (userStore.isManagement && userStore.currentSelection.scope !== 'salesAgent' && props.showSalesAgent !== true) {
            salesAgentFirstName = userStore.firstname
            salesAgentLastName = userStore.surname
            salesAgentPhoto = userStore.photo
            salesAgentStatusName = incentivesStore.incentive?.salesAgents?.champions?.find(champion => salesAgent.idUser === champion.idUser)?.salesAgentStatusCurrent
        } else {
            console.log('else')
            salesAgentFirstName = salesAgent.firstname
            salesAgentLastName = salesAgent.surname
            salesAgentPhoto = salesAgent.photo
            console.log(salesAgentPhoto)
            if(props.showSalesAgent) {
                salesAgentStatusName = incentivesStore.incentive?.salesAgents?.champions?.find(champion => salesAgent.idUser === champion.idUser)?.salesAgentStatusCurrent
            } else {
                salesAgentStatusName = incentivesStore.incentive?.salesAgents?.champions?.find(champion => userStore.id === champion.idUser)?.salesAgentStatusCurrent
            }
        }
        salesAgentName = `${salesAgentFirstName} ${salesAgentLastName}`
        salesAgentStatusClass = salesAgentStatusName?.toLowerCase().replaceAll(' ', '-')

        const salesAgentAverageRating = ratingsStore.rating?.week?.bySalesAgent?.find(salesAgent => salesAgent.id === props.salesAgentId)?.average.ratings

        return { salesAgentStatusName, salesAgentStatusClass, salesAgentName, salesAgentAverageRating, salesAgentPhoto, idUser }
    })
    
</script>

<template>
    <div :class="['sm-wrapper nav', {'isSalesAgent': !userStore.isManagement && !userStore.isSalesTeamManager}]">
        <!-- {{ props.salesAgentId }}
        {{ salesAgentInfo.idUser }} -->
        <!-- {{ props.isSalesAgent }} -->
        <div :class="['rank rating-4', salesAgentInfo.salesAgentStatusClass, {'management': (userStore.isManagement && isUserAvatar), 'area-manager': (userStore.isSalesTeamManager && isUserAvatar)}]">
            <p class="rating-value">{{ salesAgentInfo.salesAgentAverageRating }}</p>
            <img @error="handleImageError" :src="'avatars/' + (salesAgentInfo.salesAgentPhoto + '.webp')" alt="SM Profilbild">
        </div>
        <div class="txt-wrapper"> 
            <p class="sm-name">{{salesAgentInfo.salesAgentName}}</p>
            <p v-if="userStore.isManagement && isUserAvatar" class="sm-status">Geschäftsführer</p>
            <p v-else-if="userStore.isSalesTeamManager && isUserAvatar" class="sm-status">Area Manager</p>
            <p v-else class="sm-status">{{salesAgentInfo.salesAgentStatusName}}</p>
        </div>
    </div>
</template>