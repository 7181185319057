<script setup>
    import { ref, computed, watch } from 'vue'
    import router from '../../router/routes';

    import { useUserStore } from '@/stores/user';
    const userStore = useUserStore();
    
    import { useBusinessPartnersStore } from '@/stores/businessPartners'
    const businessPartnersStore = useBusinessPartnersStore();

    import { useBusinessPartnersListsStore } from '@/stores/businessPartnersLists'
    const businessPartnersListsStore = useBusinessPartnersListsStore();

    const businessPartnerSearch = ref('');

    const exceededToDos = computed(() => {
        if (userStore.isManagement || userStore.isSalesTeamManager) return {
            both: false,
            followUps: false,
            feedbacks: false
        };
        
        // if (userStore.surname === 'vom Hofe') return null; // debug only
        const salesPitchFollowUps = businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`);
        const salesPitchFeedbacks = businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`);
        return {
            both: (salesPitchFollowUps || []).length > userStore.maxToDos.followUp && (salesPitchFeedbacks || []).length > userStore.maxToDos.feedback,
            followUps: (salesPitchFollowUps || []).length > userStore.maxToDos.followUp,
            feedbacks: (salesPitchFeedbacks || []).length > userStore.maxToDos.feedback,
            feedbacks: (salesPitchFeedbacks || []).length > userStore.maxToDos.feedback,
        }
    });

    const isLoadingLeads = computed(() => {
        return (userStore.currentSelection.businessPartnerList.isLoading || userStore.currentSelection.businessPartnersLists.isLoading || userStore.currentSelection.businessPartner.isLoading);
    });

    const isLoadingList = computed(() => {
        return (userStore.currentSelection.businessPartnerList.isLoading || userStore.currentSelection.businessPartner.isLoading);
    });

    const isLoadingTodayFollowUps = computed(() => {
        return (userStore.currentSelection.businessPartnerList.isLoadingTodayFollowUps);
    });

    const isLoadingToDoFollowUps = computed(() => {
        return (userStore.currentSelection.businessPartnerList.isLoadingToDoFollowUps);
    });

    const isLoadingTodayAppointments = computed(() => {
        return (userStore.currentSelection.businessPartnerList.isLoadingTodayAppointments);
    });

    const isLoadingToDoAppointments = computed(() => {
        return (userStore.currentSelection.businessPartnerList.isLoadingToDoAppointments);
    });

    // Define a function to handle form submissions
    const handleBusinessPartnerSearch = () => {
        // console.log('searching for:', businessPartnerSearch.value, userStore.currentSelection.businessPartnerList.isLoading);
        router.push({ name: 'BusinessPartner', query: { source: 'search', searchMode: 'all', search: businessPartnerSearch.value}});
    };
    const handleSelectList = (idList) => {
        toggleDropdown();
        // console.log('routing to list:', idList);
        // set the page to 1 to avoid pagination issues
        userStore.currentSelection.businessPartnerList.page = 1;
        userStore.currentSelection.businessPartnerList.pageSize = 24;
        userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerList = idList;
        router.push({ name: 'BusinessPartnerList', query: { source: 'filter', filter: `idBusinessPartnerStatus:1|idBusinessPartnerAboStatus:-1|idBusinessPartnerList:${idList}|idBusinessPartnerProduct:-1|idBusinessPartnerPotential:-1|idBusinessPartnerCityZip:-1|nameBusinessPartnerSector:|idBusinessPartnerOrder:-1` }});
    };

    const dropdownVisible = ref(false);

    const toggleDropdown = () => {
        dropdownVisible.value = !dropdownVisible.value;
    };

    const hasNoResults = computed(() => {
        return userStore.currentSelection.businessPartnerList.search.error && hasContent.value
    })

    const hasContent = ref(false)
    watch(businessPartnerSearch, (newValue) => {
        if (newValue.trim() === '') {
            hasContent.value = false
            userStore.currentSelection.businessPartnerList.search.error = false
        } else {
            hasContent.value = true
        }
    })

</script>

<template>
    <div id="potential-bk" :class="['widget-wrapper potential-bk', {
        // 'noValue' : (userStore.currentSelection.scope !== 'salesAgent'), 
        'exceeded' : (exceededToDos && (exceededToDos.feedbacks===true || exceededToDos.followUps===true))
        }]">
        <div>
            <router-link to="/businessPartnerList?source=filter&filter=idBusinessPartnerStatus:1">
                <div class="headline-wrapper">
                    <h2>Potential & BK</h2>
                    {{ businessPartnersStore.namedFilter[0] }}
                </div>
            </router-link>
        </div>
        <!-- <template>
            <div>
            <div class="container-wrapper">
                <div class="container main-content cell-grid">
                    <div class="loader" id="loader"></div>
                </div>
                <div class="container main-content btns-wrapper">
                    <div :class="['btn-pair', {'disabled cell-grid': exceededToDos && (exceededToDos.feedbacks===true || exceededToDos.followUps===true)}]">
                        <router-link to="/businessPartnerList?source=filter&namedFilter=Today:Wiedervorlagen">        
                            <button class="btn-primary">
                                WVL Heute
                                <span class="number-label">
                                    {{businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Today:Wiedervorlagen`).length}} 
                                </span>
                            </button>
                        </router-link>
                        <router-link to="/businessPartnerList?source=filter&namedFilter=Today:VorOrtTermine">
                            <button class="btn-primary">
                                Termine Heute
                                <span class="number-label">
                                    {{businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Today:VorOrtTermine`).length}} 
                                </span>
                            </button>
                        </router-link>
                    </div>
                    <div :class="['btn-pair', {'disabled cell-grid': (businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length === 0 && businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length === 0)}]">
                        <router-link to="/salesPitchFollowUp">
                            <button :class="[{'negative' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length > 0}]">
                                WVL To-Do
                                <span :class="['number-label', {'sonar' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length > 0}]">
                                    {{ businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length}}
                                    {{ (!userStore.isSalesTeamManager && !userStore.isManagement) ? ` / ${userStore.maxToDos.followUp}` : ''}}
                                </span>
                            </button>
                        </router-link>
                        <router-link to="/salesPitchFeedback" >
                            <button :class="[{'negative' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length > 0}]">
                                Termin-Feedback
                                <span :class="['number-label', {'sonar' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length > 0}]">
                                    {{ businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length}}
                                    {{ (!userStore.isSalesTeamManager && !userStore.isManagement) ? ` / ${userStore.maxToDos.feedback}` : ''}}
                                </span>
                            </button>
                        </router-link>
                    </div>
                </div>
                <div class="container main-content"> 
                    <template v-if="exceededToDos && (exceededToDos.feedbacks===false && exceededToDos.followUps===false)">
                        <div class="custom-dropdown form-child">
                                <div class="arrowWrapper">
                                    <button @click="toggleDropdown" class="dropdown-toggle" id="dropdown-toggle">
                                        <p>Unb. Leads / Aktionen</p>
                                        <span v-if="businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#LeadList`).length > 0" class="number-label">
                                            {{ businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#LeadList`).length}}
                                        </span>
                                    </button>
                                </div>
                            <ul :style="{ display: dropdownVisible ? 'block' : 'none' }" class="dropdown-menu" id="dropdown-menu">
                                <li v-for="list of businessPartnersStore.businessPartnerLeadLists" @click="handleSelectList(`list:${list.id}`)"  :key="list.id" class="dropdown-item" data-value="option1">
                                    <div>{{ list.name }} <span class="number-label xs">{{ list.businessPartners.length }}</span></div>
                                </li>
                            </ul>
                        </div>
                        <div :class="['search', {'noResults': userStore.currentSelection.businessPartnerList.search.error}]" @submit.prevent="handleBusinessPartnerSearch">
                            <input @change="handleBusinessPartnerSearchInput"
                                v-model="businessPartnerSearch" type="text" id="search" 
                                :class="['form-child']" 
                                placeholder="GP: Name / Nr. / Tel. ...">
                        
                        </div>
                    </template>
                    <div v-else class="warningText">
                        <h2>Warnung!</h2>
                        <p v-if="exceededToDos.both">Die maximale Anzahl unbearbeiteter Termine von {{ userStore.maxToDos.feedback }} und Wiedervorlagen von {{ userStore.maxToDos.followUp }} wurde erreicht. Bitte bearbeite Feedback und Wiedervorlagen, um fortzufahren.</p>
                        <p v-else-if="exceededToDos.feedbacks">Die maximale Anzahl unbearbeiteter Termine von {{ userStore.maxToDos.feedback }} wurde erreicht. Bitte bearbeite Feedback, um fortzufahren.</p>
                        <p v-else>Die maximale Anzahl unbearbeiteter Wiedervorlagen von {{ userStore.maxToDos.followUp }} wurde erreicht. Bitte bearbeite ältere Wiedervorlagen, um fortzufahren.</p>
                    </div>
                </div>   
            </div>
        </template> -->
        <div class="container-wrapper">
            <div v-if="isLoadingList === true" class="container main-content"> 
                <div class="container main-content cell-grid">
                    <div class="loader" id="loader"></div>
                </div>
            </div> 
            <div class="container main-content btns-wrapper">
                <div :class="['btn-pair', {'disabled cell-grid': (businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Today:Wiedervorlagen`).length === 0 && businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Today:VorOrtTermine`).length === 0)}]">
                    <router-link :class="[{'cell-grid disabled' : isLoadingTodayFollowUps || businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Today:Wiedervorlagen`).length === 0}]" to="/businessPartnerList?source=filter&namedFilter=Today:Wiedervorlagen">        
                        <button :class="['btn-primary']">
                            WVL Heute
                            <span class="number-label" v-if="businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Today:Wiedervorlagen`).length > 0">
                                {{businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Today:Wiedervorlagen`).length}} 
                            </span>
                            <div v-if="isLoadingTodayFollowUps" class="loader"></div>
                        </button>
                    </router-link>
                    <router-link :class="[{'cell-grid disabled' : isLoadingTodayAppointments || businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Today:VorOrtTermine`).length === 0}]" to="/businessPartnerList?source=filter&namedFilter=Today:VorOrtTermine">
                        <button :class="['btn-primary']">
                            Termine Heute
                            <span class="number-label" v-if="businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Today:VorOrtTermine`).length > 0">
                                {{businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Today:VorOrtTermine`).length}} 
                            </span>
                            <div v-if="isLoadingTodayAppointments" class="loader"></div>
                        </button>
                    </router-link>
                </div>
                <div :class="['btn-pair', {'disabled cell-grid': (businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length === 0 && businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length === 0)}]">                    
                    <router-link :class="[{'cell-grid disabled' : isLoadingToDoFollowUps || businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length === 0}]" to="/salesPitchFollowUp">
                        <button :class="[{'negative' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length > 0}]">
                            WVL To-Do
                            <span :class="['number-label', {'sonar' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length > 0}]" v-if="businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length > 0">
                                {{ businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length}}
                                {{ (!userStore.isSalesTeamManager && !userStore.isManagement) ? ` / ${userStore.maxToDos.followUp}` : ''}}
                            </span>
                            <div v-if="isLoadingToDoFollowUps" class="loader"></div>
                        </button>
                    </router-link>
                    <router-link :class="[{'cell-grid disabled' : isLoadingToDoAppointments || businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length === 0}]" to="/salesPitchFeedback" >
                        <button :class="[{'negative' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length > 0}]">
                            Termin-Feedback
                            <span :class="['number-label', {'sonar' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length > 0}]" v-if="businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length > 0">
                                {{ businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length}}
                                {{ (!userStore.isSalesTeamManager && !userStore.isManagement) ? ` / ${userStore.maxToDos.feedback}` : ''}}
                            </span>
                            <div v-if="isLoadingToDoAppointments" class="loader"></div>
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="container-wrapper">
            <div class="container main-content"> 
                <template v-if="exceededToDos && (exceededToDos.feedbacks===false && exceededToDos.followUps===false)">
                    <div class="custom-dropdown form-child">
                        <!-- <button @click="handleSugarLists" class="dropdown-toggle btn" id="dropdown-toggle">
                            Neues Kundenmaterial <span class="highlight"> {{ 2}}</span> -->
                            <div class="arrowWrapper">
                                <button @click="toggleDropdown" class="dropdown-toggle" id="dropdown-toggle">
                                    <p>
                                        Unb. Leads / Aktionen
                                        <span class="number-label right" v-if="businessPartnersListsStore.businessPartnersLists.data.length > 0">
                                        {{ businessPartnersListsStore.businessPartnersLists?.data?.reduce((a, list) => a + list.businessPartnerCount, 0) }}
                                    </span>
                                    </p>
                                </button>
                            </div>
                        <ul :style="{ display: dropdownVisible ? 'block' : 'none' }" class="dropdown-menu" id="dropdown-menu">
                            <li v-for="list of businessPartnersListsStore.businessPartnersLists?.data" @click="handleSelectList(`list:${list.id}`)"  :key="list.id" class="dropdown-item" data-value="option1">
                                <div>{{ list.name }} <span class="number-label right xs">{{ list.businessPartnerCount }}</span></div>
                            </li>
                        </ul>
                    </div>
                    <div :class="['search-wrapper', {'noResults': hasNoResults}]" @submit.prevent="handleBusinessPartnerSearch">
                        <form class="search">
                            <!-- {{ isLoading }} -->
                            <!-- <div v-if="isLoading" class="loader" id="loader"></div> -->
                            <!-- <span class="number-label">Ups! Nichts Passendes gefunden.</span> -->
                            <input 
                                v-model="businessPartnerSearch" type="text" id="search" 
                                :class="['form-child']" 
                                placeholder="GP: Name / Nr. / Tel. ...">
                        </form>
                        <p v-if="hasNoResults" class="warning-txt">Ups! Nichts Passendes gefunden.</p>
                    </div>
                </template>
                <div v-else class="warningText">
                    <h2>Warnung!</h2>
                    <p v-if="exceededToDos.both">Die maximale Anzahl unbearbeiteter Termine von {{ userStore.maxToDos.feedback }} und Wiedervorlagen von {{ userStore.maxToDos.followUp }} wurde erreicht. Bitte bearbeite Feedback und Wiedervorlagen, um fortzufahren.</p>
                    <p v-else-if="exceededToDos.feedbacks">Die maximale Anzahl unbearbeiteter Termine von {{ userStore.maxToDos.feedback }} wurde erreicht. Bitte bearbeite Feedback, um fortzufahren.</p>
                    <p v-else>Die maximale Anzahl unbearbeiteter Wiedervorlagen von {{ userStore.maxToDos.followUp }} wurde erreicht. Bitte bearbeite ältere Wiedervorlagen, um fortzufahren.</p>
                </div>
            </div>
            <div v-if="isLoadingLeads === true" class="container main-content"> 
                <div class="container main-content cell-grid">
                    <div class="loader" id="loader"></div>
                </div>
            </div> 
        </div>
        <div class='wave-wrapper'>
            <div class='wave -one'></div>
            <div class='wave -two'></div>
            <div class='wave -three'></div>
        </div>
    </div>
</template>

<style scoped>
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>


<!-- <style src="../scss/components/incentivePreview.scss" lang="scss" scoped></style> -->